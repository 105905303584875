import "./Loading.scss"

type Props = {}

const SpinnerOverlay = (props: Props) => {
  return (
      <div className="spinner-overlay d-flex justify-content-center align-items-center">
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </div>
  )
}

export default SpinnerOverlay
