import { Formik } from "formik";
import { convertNumberPrice } from "../../../utils/FormatUtils";
import TextField from "../../../component/TextField";

const getColumnThongTinVienPhi = (
  isPhieuThu: boolean,
  handleChangeValueTable: (
    row: any,
    value: number,
    index: any,
    name: any
  ) => void,
  dsThongTinDv?: any,
  setInputFocused = (id: any) => {},
  inputFocused?: any,
  arraySelect?: any,
  setRowEdit = (row: any) => {}
) => {
  return [
    {
      title: "Tên dịch vụ",
      field: "termSvcNameClone",
      className: "spaces w-25",
    },
    {
      title: "ĐV",
      field: "svcUnit",
      className: "spaces width-20px",
      render: (rowData: any) => {
        return (
          <span className={`${rowData?.isCancel ? "text-danger" : ""}`}>
            {rowData?.svcUnit}
          </span>
        );
      },
    },
    {
      title: "Đối tượng",
      field: "visitObjectTypeName",
      className: "spaces width-30px text-center",
      render: (rowData: any) => {
        return (
          <span className={`${rowData?.isCancel ? "text-danger" : ""}`}>
            {rowData?.visitObjectTypeName}
          </span>
        );
      },
    },
    {
      title: "SL",
      field: "svcQuantity",
      className: "spaces width-20px text-center",
      render: (rowData: any) => {
        return (
          <span className={`${rowData?.isCancel ? "text-danger" : ""}`}>
            {rowData?.svcQuantity}
          </span>
        );
      },
    },
    {
      title: "Đơn giá",
      field: "svcPriceClone",
      className: "spaces width-25px text-end",
      render: (rowData: any) => {
        return (
          <span className={`${rowData?.isCancel ? "text-danger" : ""}`}>
            {rowData?.svcPriceClone !== undefined
              ? convertNumberPrice(rowData?.svcPriceClone)
              : ""}
          </span>
        );
      },
    },
    {
      title: "% TT",
      field: "paymentPtPercentOld",
      className: "spaces width-20px text-center",
      render: (rowData: any, index: any, stt: number) => {},
    },
    {
      title: "Thành tiền",
      field: "svcTotalClone",
      className: "spaces width-32px text-end",
      render: (rowData: any) => {
        return (
          <span className={`${rowData?.isCancel ? "text-danger" : ""}`}>
            {rowData?.svcTotalClone !== undefined || rowData?.svcTotal !== undefined 
              ? convertNumberPrice(rowData?.svcTotalClone || rowData?.svcTotal) 
              : ""}
          </span>
        );
      },
    },
    {
      title: "",
      field: "paymentPtPercent",
      className: "spaces width-20px text-center",
      render: (rowData: any, index: any, stt: number) => {
        return (
          <Formik initialValues={{}} onSubmit={() => {}}>
            {isPhieuThu &&
            !rowData?.isPaid &&
            rowData?.isPaid !== undefined &&
            rowData?.svcPriceClone !== 0 &&
            arraySelect?.includes(rowData?.id) ? (
              <TextField
                label=""
                className={`input input-col-vp text-end ${"paymentPtPercentClone-" +
                dsThongTinDv[index[0]].orderData[index[1]]?.id}`}
                name="paymentPtPercentClone"
                type="number"
                placeholder="%"
                value={
                  rowData?.paymentPtPercentClone || 0
                }
                onChange={(e: any) => {
                  handleChangeValueTable(
                    rowData,
                    Number(e.target.value),
                    index,
                    "paymentPtPercentClone"
                  );
                }}
                onFocus={(e: any) => {
                  setInputFocused(
                    "paymentPtPercentClone-" +
                      dsThongTinDv[index[0]].orderData[index[1]]?.id
                  );
                  setRowEdit(rowData)
                }}
                autoFocus={
                  "paymentPtPercentClone-" + rowData?.id === inputFocused
                }
                onWheel={(e: any) => e?.currentTarget?.blur()}
              />
            ) : (
              <span className="text-center">{rowData?.paymentPtPercent || ""}</span>
            )}
          </Formik>
        );
      },
    },
    {
      title: "MG",
      field: "discountClone",
      className: "spaces width-30px text-end",
      render: (rowData: any, index: any, stt: number) => {
        return (
          <span className="text-end">
            {rowData?.discountClone || rowData?.discount
              ? convertNumberPrice(rowData?.discountClone || rowData?.discount)
              : ""}
          </span>
        );
      },
    },
    { title: "BHYT Trả", field: "BHYTTra", className: "spaces width-28px" },
    { title: "% bh", field: "phanTramBaoHiem", className: "spaces width-20px" },
    {
      title: "BN trả",
      field: "svcPtTotal",
      className: "spaces width-25px text-end",
      render: (rowData: any) => {
        return (
          <span className={`${rowData?.isCancel ? "text-danger" : ""}`}>
            {rowData?.svcPtTotalClone !== undefined
              ? convertNumberPrice(rowData?.svcPtTotalClone)
              : ""}
          </span>
        );
      },
    },
    { title: "#bncct", field: "bncct", className: "spaces width-35px" },
    { title: "#bntt", field: "bntt", className: "spaces width-30px" },
    {
      title: "Đã thu",
      field: "svcCollected",
      className: "spaces width-35px text-end",
      render: (rowData: any) => {
        return (
          <span className={`${rowData?.isCancel ? "text-danger" : ""}`}>
            {rowData?.svcCollected
              ? convertNumberPrice(rowData?.svcCollected)
              : ""}
          </span>
        );
      },
    },
    { title: "#Đã thu-CCT", field: "daThuCCT", className: "spaces width-35px" },
    {
      title: "#Đã thu-bntt",
      field: "daThubntt",
      className: "spaces width-35px",
    },
    {
      title: "Yêu cầu thu",
      field: "yeuCauThu",
      className: "spaces width-35px",
    },
  ];
};

export { getColumnThongTinVienPhi };

