import { DATA_TYPE_XN } from "../const/constants";

export const convertListDichVuXetNgiem = (data: any[]) => {
	return data.map((item: any) => {
		return {
			name: item.parentName,
			subs: item?.orders.map((term: any) => ({
				...term,
				...term?.term,
				termOrderId: term?.id,
				subs: term?.term?.subs?.map((sub: any) => ({
					...sub,
					orderId: term?.orderId,
					statusCode: term?.statusCode,
					statusColor: term?.statusColor,
				})),
			})),
		};
	});
};

export const genderClassName = (rowData: any) => {
	let isText = rowData?.datatypeId === DATA_TYPE_XN.TEXT;
	let lowNormal = rowData?.numeric?.lowNormal;
	let hiNormal = rowData?.numeric?.hiNormal;
	let label = rowData?.numeric?.label;
	let obsValue = rowData?.obs?.value;
	let result = "";
	if (isText && label) {
		result = obsValue === label ? "" : "fw-bold";
	} else if (obsValue > hiNormal) {
		result = "hiNormal";
	} else if (obsValue < lowNormal) {
		result = "lowNormal";
	}
	return result;
};
