import React from 'react'
import { Col, Row } from 'react-bootstrap';
import LabelRequired from '../../../../component/LabelRequired';
import DanhSachPhieu from '../../../../component/DanhSachPhieu';
import InputSearch from '../../../../component/InputSearch';
import { TableCustom } from '../../../../component/table/table-custom/TableCustom';
import { columnsDanhSachDonThuoc } from '../../../../phan-he-kham-benh/columns/tab-thuoc/ColumnTabThuoc';

type Props = {}

const Thuoc = (props: Props) => {
 
  return (
    <Row className="h-100 spaces px-16 py-5">
      <div>
        <div className="px-3 py-1 bg-white border-bottom border-bottom-2">
          <Row>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Loại phiếu:" className="spaces min-w-110" />
              <span className="fw-light text-truncate">...</span>
            </Col>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Ngày y lệnh:" className="spaces min-w-110" />
              <span className="fw-light text-truncate">...</span>
            </Col>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Ngày chỉ định:" className="spaces min-w-110" />
              <span className="fw-light text-truncate">...</span>
            </Col>
          </Row>

          <Row>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Mã phiếu:" className="spaces min-w-110" />...</Col>
            <Col xs={8} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Nơi chỉ định:" className="spaces min-w-110" />
              <span className="fw-light text-truncate">...</span>
            </Col>
          </Row>

          <Row>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Người chỉ định:" className="spaces min-w-110" />
              <span className="fw-light text-truncate">...</span>
            </Col>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Trạng thái:" className="spaces min-w-110" />
              <span className="fw-light text-truncate">...</span>
            </Col>
          </Row>

          <Row>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Kho:" className="spaces min-w-110" />
              <span className="fw-light text-truncate">...</span>
            </Col>
            <Col xs={8} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Chẩn đoán:" className="spaces min-w-110" />
              <span className="fw-light text-truncate">...</span>
            </Col>
          </Row>
        </div>
        <div className="px-10 py-5 spaces mb-4">
          <InputSearch
            value={""}
            placeholder="Tìm kiếm"
            handleChange={() => {}}
            // handleKeyDown={onKeydown}
            // handleSearch={onBtnSearchClick}
            className="spaces h-25 radius-3"
          />
        </div>
        <div className="px-7 spaces pb-4">
          <DanhSachPhieu
            dsPhieu={[]}
            // handleSelectRowData={onSelect}
            title="price"
          />
        </div>
        <TableCustom<any>
          className="overflow-auto spaces h-calc-vh-553"
          data={[]}
          columns={columnsDanhSachDonThuoc}
        />
      </div>
    </Row>
  );
}

export default Thuoc