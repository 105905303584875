import { useField, useFormikContext } from "formik";
import { Form, FormControl } from "react-bootstrap";
import { forwardRef, useEffect, useRef } from "react";
import { IBaseComponentProps } from "./types";
import clsx from "clsx";
import './style.scss';

interface TextFieldProps extends IBaseComponentProps {
  labelClassName?: string;
  inputClassName?: string;
  [key: string]: any;
}

const TextField: React.FC<TextFieldProps> = forwardRef<
  HTMLInputElement,
  TextFieldProps
>(({ 
  labelClassName, 
  inputClassName, 
  readOnlyText, 
  variant = 'outlined',
  ...props 
}, ref) => {
  const [field, meta] = useField(props?.name ?? '');
  const { submitCount, isSubmitting } = useFormikContext();
  const inputRef = useRef<HTMLInputElement>(null);

  const fieldClassName = clsx(
    'spaces px-4 customs-input',
    {
      'is-invalid':(meta.error && meta.touched) || (props?.errors && props?.touched),
    },
    inputClassName,
    props.className,
    `oct-text-field--${variant}`
  );

  useEffect(() => {
    if ((meta.error && meta.touched) || submitCount > 0 || isSubmitting) {
      const invalidElements = document.querySelectorAll(".is-invalid");
      if (invalidElements.length > 0) {
        const firstInvalidElement = invalidElements[0] as HTMLElement;
        firstInvalidElement.focus();
        firstInvalidElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [submitCount, isSubmitting]);

  return (
		<Form.Group
			className={clsx('text-field-v2 oct-field-wrapper', props?.className)}
		>
			{props?.label && !props?.hideLabel && (
				<Form.Label
					className={clsx(
						'text-lable-input max-content-width min-w-fit-content mb-0',
						{
							'me-2': !labelClassName,
						},
						labelClassName
					)}
				>
					{props?.label}
				</Form.Label>
			)}

			<div className="position-relative w-100 h-100">
				<FormControl
					{...field}
					{...props}
					ref={ref || inputRef}
					readOnly={readOnlyText}
					className={fieldClassName}
				/>
				<Form.Control.Feedback type="invalid" tooltip className="field-tooltip-error">
					{props.errors || meta.error}
				</Form.Control.Feedback>
			</div>
		</Form.Group>
  );
});

export default TextField;
