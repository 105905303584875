import { useNavigate } from 'react-router-dom'
import './homepage.scss'
import { KEY_DS_DANH_MUC_TIEP_DON, LEVEL, LOAI_PHONG, SIMPLE_CATEGORY_TYPE, TERM_CLASS_IDS } from '../../modules/utils/Constant';
import { getAllDistricts, getAllProvinces, getAllSubDistricts, getListKhoaPhong, getSimpleCategory, getTermByClassId, getTermClasses } from '../../modules/utils/ServicesUtils';
import { getListDangKyKham, getListDanToc, getListQuocTich } from '../../modules/phan-he-tiep-nhan-thanh-toan/services/TiepNhanServices';
import { AxiosResponse } from 'axios';
import { addIndexedDBItem, getIndexedDBItem } from '../../modules/utils/IndexedDB';
import { IDanhSachDanhMuc } from '../../modules/phan-he-tiep-nhan-thanh-toan/models/TiepDonModel';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../modules/appContext/AppContext';
import { listModule } from './constant';
import { hasAuthority } from '../../modules/utils/Permission';
import { useAuth } from '../../modules/auth';

export function HomePage() {
  const navigate = useNavigate();
  const { setIsLoading } = useContext(AppContext);
  const authoritesModules = listModule.filter((menu) => hasAuthority(menu.permission));
  const { logout} = useAuth();

  const handleButtonClick = (to: string) => {
    navigate(to)
  };

  const handleCacheDsDanhMuc = async () => {
    try {
      setIsLoading(true);
      const CLASS_IDS_DICH_VU = `${TERM_CLASS_IDS.XET_NGHIEM},${TERM_CLASS_IDS.CDHA},${TERM_CLASS_IDS.PTTT}`;
      const [
        responseProvinces,
        responseDistricts,
        responseSubDistricts,
        responseDoiTuong,
        responseGioiTinh,
        responseNgheNghiep,
        responseLDKham,
        responseQuocTich,
        responseDanToc,
        responseDKKham,
        responseDVKhamBenh,
        responsePhongKham,
        responseTuyenKCB,
        responseICD10,
        responseHTXT,
        responseKQDT,
        responseDichVu,
      ] = await Promise.allSettled([
        getAllProvinces(),
        getAllDistricts(),
        getAllSubDistricts(),
        getSimpleCategory({ type: SIMPLE_CATEGORY_TYPE.DOI_TUONG }),
        getSimpleCategory({ type: SIMPLE_CATEGORY_TYPE.GIOI_TINH }),
        getSimpleCategory({ type: SIMPLE_CATEGORY_TYPE.NGHE_NGHIEP }),
        getSimpleCategory({ type: SIMPLE_CATEGORY_TYPE.LY_DO_KHAM }),
        getListQuocTich(),
        getListDanToc(),
        getListDangKyKham(),
        getTermClasses({ classIds: TERM_CLASS_IDS.KHAM_BENH }),
        getListKhoaPhong({ level: LEVEL.PHONG, type: LOAI_PHONG.PHONG_KHAM_BENH }),
        getSimpleCategory({ type: SIMPLE_CATEGORY_TYPE.TUYEN_KCB }),
        getTermByClassId({ classId: TERM_CLASS_IDS.ICD10 }),
        getTermByClassId({ classId: TERM_CLASS_IDS.HTXT }),
        getTermByClassId({ classId: TERM_CLASS_IDS.KQDT }),
        getTermClasses({ classIds: CLASS_IDS_DICH_VU }),
      ]);

      const getData = (
        response: PromiseSettledResult<AxiosResponse<any>>,
        termType?: number | string
      ) => {
        const STATUS_FULFILLED = "fulfilled";
        if (STATUS_FULFILLED === response.status) {
          switch (termType) {
            case TERM_CLASS_IDS.KHAM_BENH:
              return response?.value?.data?.data?.[0]?.terms?.[0]?.subs || [];
            case TERM_CLASS_IDS.ICD10:
            case TERM_CLASS_IDS.HTXT:
            case TERM_CLASS_IDS.KQDT:
            case CLASS_IDS_DICH_VU:
              return response?.value?.data?.data || [];
            default:
              return response?.value?.data?.data?.content || []
          }
        }
        return [];
      };
      let provinces = getData(responseProvinces);
      let districts = getData(responseDistricts);
      let subDistricts = getData(responseSubDistricts);
      let listDoiTuong = getData(responseDoiTuong);
      let listGioiTinh = getData(responseGioiTinh);
      let listNgheNghiep = getData(responseNgheNghiep);
      let listLDKham = getData(responseLDKham);
      let listQuocTich = getData(responseQuocTich);
      let listDanToc = getData(responseDanToc);
      let listDKKham = getData(responseDKKham);
      let listPhongKham = getData(responsePhongKham);
      let listDVKhamBenh = getData(responseDVKhamBenh, TERM_CLASS_IDS.KHAM_BENH);
      let listTuyenKCB = getData(responseTuyenKCB);
      let listICD10 = getData(responseICD10, TERM_CLASS_IDS.ICD10);
      let listHTXT = getData(responseHTXT, TERM_CLASS_IDS.HTXT);
      let listKQDT = getData(responseKQDT, TERM_CLASS_IDS.KQDT);
      let listDichVu = getData(responseDichVu, CLASS_IDS_DICH_VU);

      let dsDanhMucResponse = {
        provinces, districts, subDistricts, listDoiTuong,
        listGioiTinh, listNgheNghiep, listLDKham, listQuocTich,
        listDanToc, listDKKham, listPhongKham, listDVKhamBenh,
        listTuyenKCB, listICD10, listHTXT, listKQDT, listDichVu
      };
      await addIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON, dsDanhMucResponse, setIsLoading);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetDsDanhMuc = async () => {
    try {
      const responseDsDanhmuc: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
      let hasEmptyArray = responseDsDanhmuc && Object.values(responseDsDanhmuc).some(
        (item: any[]) => item.length === 0
      );
      if (!responseDsDanhmuc || hasEmptyArray) {
        handleCacheDsDanhMuc();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetDsDanhMuc();
  }, []);

  return (
    <div className="main">
      <div className="container">
        <div>
            <div className='d-flex justify-content-center spaces mt-10'>
              <img src="/media/logos/logo_xHIS.png" alt="Logo xHis" className='spaces'/>
            </div>
            <p className="text-uppercase text-left text-white fw-normal heading-3 mt-20 mb-40 spaces fs-22">
              PHẦN MỀM QUẢN LÝ PHÒNG KHÁM
            </p>
          </div>
        <div className="button-group">
          {authoritesModules.map((item: any) => (
            <div className="item-button" key={item.permission}>
              <button
                className="btn btn-light-primary fw-bolder btn-homepage"
                onClick={() => handleButtonClick(item?.to)}
              >
                <img src={item?.imgSrc} alt={item?.name}></img>
              </button>
              <span className="title-button">{item?.name}</span>
            </div>
          ))}
        </div>
      </div>
      <div className='icon-button'>
        <i className="bi bi-box-arrow-left cursor-pointer icon" onClick={logout}></i>
      </div>
    </div>
  );
}