import { Form, Formik, FormikErrors, useFormikContext } from "formik";
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import AutocompleteV2 from "../../component/AutocompleteObjectV2";
import LabelRequired from "../../component/LabelRequired";
import { IMenuVienPhi } from "../models/VienPhiInfoModels";
import { getDsChonSoThuTien, getFormFieldConfig, saveChonSoThuTien } from "../services/PhanHeVienPhiServices";
import { CODE, RESPONSE_MESSAGE } from "../../utils/Constant";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";

type Props = {
  show: IMenuVienPhi;
  onHide: Dispatch<SetStateAction<IMenuVienPhi>>;
  setDataSoThu: Dispatch<SetStateAction<any>>;
};

const ModalChonSoThu: FC<Props> = (props) => {
  const { show, onHide, setDataSoThu } = props;
  const { setIsLoading } = useContext(AppContext);
  const [validationSchema, setValidationSchema] = useState(Yup.object({}));
  // let validationSchema = Yup.object({
  //   soTamUng: Yup.object().nullable().required("Không được để trống"),
  //   soHoanUng: Yup.object().nullable().required("Không được để trống"),
  //   soThuTien: Yup.object().nullable().required("Không được để trống"),
  // });

  const handleFormSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      let submitData: { [key: string]: string } = {};
      Object.entries({ ...values }).forEach(([key, value] : any) => {
        submitData[key] = value?.id;
      });
      let {data} = await saveChonSoThuTien(submitData);
      if (CODE.SUCCESS === data?.code) {
        toast.success(RESPONSE_MESSAGE.ADD.SUCCESS);
        onHide({
          openChonSoThu: false,
          openDanhSachSoThu: false,
          openTaoMoiSoThu: false,
          openDanhSachPhieu: false,
        })
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch (error) {
      toast.warning(RESPONSE_MESSAGE.ERROR);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        centered
        show={show.openChonSoThu}
        onHide={() =>
          onHide({
            openChonSoThu: false,
            openDanhSachSoThu: false,
            openTaoMoiSoThu: false,
            openDanhSachPhieu: false,
          })
        }
        size="lg"
        placement="top"
        contentClassName="w-75 m-auto"
      >
        <Formik<any>
          initialValues={{}}
          validationSchema={validationSchema}
          validateOnChange={true}
          validate={(values) => {
            const errors: FormikErrors<any> = {};
            return errors;
          }}
          onSubmit={handleFormSubmit}
        >
          {({ touched, errors, setFieldValue, values }) => (
            <Form>
              <Modal.Header closeButton className="py-5 bg-white">
                <Modal.Title>Chọn sổ thu tiền</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-8">
               <FormChonSoThu validationSchema={validationSchema} setValidationSchema={setValidationSchema} />
              </Modal.Body>
              <Modal.Footer className="flex flex-middle flex-center spaces gap-3 py-3">
                <Button
                  className="btn-fill spaces min-w-80"
                  onClick={() => {
                    setDataSoThu(null);
                    onHide({
                      openChonSoThu: false,
                      openDanhSachSoThu: false,
                      openTaoMoiSoThu: true,
                      openDanhSachPhieu: false,
                    })
                  }}
                >
                  Thêm
                </Button>
                <Button className="btn-fill spaces min-w-80" type="submit">
                  Lưu
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
export { ModalChonSoThu };

const FormChonSoThu: FC<any> = (props) => {
  const {values, setFieldValue, setValues, errors, touched, setErrors} = useFormikContext<any>();
  const { validationSchema, setValidationSchema } = props;
  const [paymentItem, setPaymentItem] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);

  const updatePageData = async () => {
    try {
      let { data } = await getDsChonSoThuTien();
      let selectedValues: { [key: string]: string } = {};
      Object.entries(data?.data).forEach(([key, value]: any) => {
        selectedValues[key] = value?.find((item: any) => item?.isSelected);
      });
      setValues(selectedValues);
      setOptions(data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updatePageData();
    handleSetPaymentInfor();
  }, []);

  const handleSetPaymentInfor = async () => {
    const FORM_FIELD_CONFIG = [
      {
        code: "SO_TAM_UNG",
        label: "Sổ tạm ứng",
        name: "soTamUng",
        menuPlacement: "bottom",
        isRequired: true,
      },
      {
        code: "SO_HOAN_UNG",
        label: "Sổ hoàn ứng",
        name: "soHoanUng",
        menuPlacement: "bottom",
        isRequired: true,
      },
      {
        code: "SO_THU_TIEN",
        label: "Sổ thu tiền, hoàn tiền",
        name: "soThuTien",
        menuPlacement: "bottom",
        isRequired: true,
      },
      {
        code: "SO_THU_VAT",
        label: "Sổ thu tiền (có VAT)",
        name: "soThuTienVat",
        menuPlacement: "bottom",
        isRequired: false,
      },
      {
        code: "SO_THU_NHA_THUOC",
        label: "Sổ thu tiền (nhà thuốc)",
        name: "soThuTienNhaThuoc",
        menuPlacement: "bottom",
        isRequired: false,
      },
      {
        code: "SO_MIEN_GIAM",
        label: "Sổ miễn giảm",
        name: "soMienGiam",
        menuPlacement: "top",
        isRequired: false,
      },
    ]
    try {
      let { data: { data } } = await getFormFieldConfig("FORM_SELECTION_LEDGERS");
      const jsonObject = JSON.parse(data[0]?.valueText);
      
      let newArr: any[] = [];
      let newErr: any = {};

      jsonObject.forEach((item: any) => {
        let obj = FORM_FIELD_CONFIG.find((obj: any) => obj?.code === item);
        
        obj && newArr.push(obj);
      })

      newArr.map((obj: any) => {
        if (obj?.isRequired) {
          newErr[obj?.name] = Yup.object().nullable().required("Không được để trống");
        }
        return newErr[obj?.name];
      })
      
      setPaymentItem(newArr);
      setValidationSchema(validationSchema.concat(Yup.object(newErr)));
    } catch (err) {
      console.error(err);
    }
  };

  return <>
    {paymentItem?.length > 0 && paymentItem?.map((item: any, index: number) => {
      return (
        <div className="w-100 mb-4 d-flex" key={index}>
          <LabelRequired
            label={item?.label}
            className="ps-2 min-w-150px"
          />
          <AutocompleteV2
            options={options?.[item?.name]}
            name={item?.name}
            value={values?.[item?.name]}
            onChange={(selectedOption) => setFieldValue(item?.name, selectedOption)}
            className="autocomplete-custom radius width-100 spaces "
            menuPlacement={item?.menuPlacement}
            errors={errors?.[item?.name]}
            touched={touched?.[item?.name]}
          />
        </div>
      )
    })}
  </>
}
