import clsx from 'clsx';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';

type Props = {
	label?: string;
	value?: string;
	length: number;
	className?: string;
	inputClassName?: string;
	required?: boolean;
	labelClassName?: string;
	onChange?: (value: string) => void;
	name?: string;
};

const InputOTP = ({
	value,
	length,
	className,
	inputClassName,
	label,
	required,
	labelClassName,
	onChange,
	name,
}: Props) => {
	const [fieldValue, setFieldValue] = useState<string[]>(Array(length).fill(''));

	const inputsRef = useRef<HTMLInputElement[]>([]);
	const containerRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (value) {
			setFieldValue(
				value
					.padStart(length, ' ')
					.split('')
					.map(item => item.trim())
			);
		}
	}, [value]);

	const onInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
		const value = e.target.value;

		const tempValue = [...fieldValue];
		tempValue[index] = value;
		setFieldValue(tempValue);

		if (index < length - 1 && value !== '') {
			nextCell(index);
		}

		if (index > 0 && !value) {
			backCell(index);
		}
	};

	const nextCell = (index: number) => {
		inputsRef.current[index + 1].focus();
	};

	const backCell = (index: number) => {
		inputsRef.current[index - 1].focus();
	};

	const onInputKeydown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
		switch (e.key) {
			case 'Delete':
				const tempValue = [...fieldValue];
				tempValue[index] = '';
				setFieldValue(tempValue);
				break;
			case 'ArrowLeft':
				if (index >= 0) {
					backCell(index);
				}
				break;
			case 'ArrowRight':
				if (index < length - 1) {
					nextCell(index);
				}
				break;
			case 'Backspace':
				if (index > 0 && !value) {
					backCell(index);
				}
				break;
			default:
				break;
		}
	};

	return (
		<Form.Group
			className={clsx('d-flex oct-field-wrapper oct-input-otp', className)}
			ref={containerRef}
		>
			<input type="hidden" name={name} value={fieldValue} onChange={() => {}} />
			{label && (
				<Form.Label
					className={clsx(
						'spaces text-lable-input max-content-width mb-0 me-2 min-w-fit-content',
						labelClassName
					)}
					dangerouslySetInnerHTML={{
						__html: required
							? `${label}<strong class='text-danger ps-1'>(*)</strong>`
							: `${label}`,
					}}
				></Form.Label>
			)}
			<div className="d-flex align-items-end">
				{Array.from({ length }, (_, index) => index).map(field => (
					<Form.Control
						key={field}
						name={`otp-input-${field}`}
						className={clsx('spaces mr-4 p-6 text-center radius-4', inputClassName)}
						style={{
							width: 28,
							height: 28,
						}}
						value={fieldValue[field]}
						maxLength={1}
						onChange={(e: ChangeEvent<HTMLInputElement>) => onInputChange(e, field)}
						onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => onInputKeydown(e, field)}
						onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
							e.target.select();
						}}
						ref={(element: HTMLInputElement) => (inputsRef.current[field] = element)}
						onBlur={() => {
							if (field === length - 1) {
								onChange?.(fieldValue.join(''));
							}
						}}
					/>
				))}
			</div>
		</Form.Group>
	);
};

export default InputOTP;
