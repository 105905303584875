import React from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import LabelRequired from '../../../../component/LabelRequired';
import TextField from '../../../../component/TextField';
import DatePickerCustom from '../../../../component/date-picker/DatePickerCustom';
import ProvisonalDiagnosis from '../../../../phan-he-kham-benh/components/ProvisonalDiagnosis';
import AutocompleteV2 from '../../../../component/AutocompleteObjectV2';
import { Formik } from 'formik';

type Props = {}

const XuTri = (props: Props) => {
  return (
    <Formik
      initialValues={{}}
      onSubmit={() => { }}
    >
      <div className='spaces px-16 py-5'>
        <Row className="mb-1 pt-5">
          <Col xs={4} className="spaces mt-1">
            <div className="mb-3">
              <div className="d-flex justify-content-between mb-1">
                <LabelRequired label="Chẩn đoán xác định" />
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <u className="color-17A2B8">Chọn chẩn đoán</u>
                </a>
              </div>

              <TextField
                as="textarea"
                rows={2}
                name="finalDiagnosis"
              // value={}
              />
            </div>

            <div className="mb-2">
              <div className="d-flex mb-1">
                <LabelRequired
                  label="Nguyên nhân - ICD10"
                  className="min-w-150px"
                />
              </div>
              <TextField
                as="textarea"
                rows={2}
                name="icdXCause"
              // value={}
              />
            </div>

            <div className="d-flex gap-2 mb-2">
              <div className="flex-1 w-49">
                <div className="d-flex">
                  <LabelRequired label="Thời gian xử trí" />
                </div>
                <DatePickerCustom
                  mode="date-time"
                  name="medicalConclusionDate"
                  value={""}
                />
              </div>
              <div className="flex-1">
                <div className="d-flex">
                  <LabelRequired label="Kết quả điều trị" />
                </div>
                <AutocompleteV2
                  options={[]}
                  name="medicalConclusion"
                // value={}
                />
              </div>
            </div>
            <div className="d-flex gap-2">
              <div className="flex-1">
                <div className="d-flex">
                  <LabelRequired label="Hình thức xử trí" />
                </div>
                <AutocompleteV2
                  options={[]}
                  name="hinhThucXutri"
                // value={}
                />
              </div>
              <div className="spaces pt-22 flex-1 text-truncate">
                { }
              </div>
            </div>
          </Col>

          <Col xs={8} className="ps-0">
            <div className="mb-2">
              <div className="d-flex justify-content-between">
                <LabelRequired
                  label="Bệnh chính - ICD10"
                  className="min-w-150px"
                />
                <Button
                  className="bg-white"
                >
                  <u className="color-17A2B8">Chọn bệnh</u>
                </Button>
              </div>
              <Row className="spaces pe-0 align-items-center position-relative">
                <ProvisonalDiagnosis
                  encounterDiagnosisValues={{}}
                  encounterDiagnosisErrors={{}}
                  encounterDiagnosisTouched={{}}
                  benhNhanInfo={{}}
                  listComorbidity={[]}
                  setListComorbidity={() => { }}
                  icd10Field="icdXPrimary"
                  onChange={() => { }}
                  handleChangeEncounterDiagnosis={() => { }}
                />
              </Row>
            </div>

            <div className="mb-2">
              <div className="spaces d-flex flex-row justify-content-between">
                <div className="d-flex ">
                  <LabelRequired
                    label="Bệnh kèm theo - ICD10"
                    className="min-w-150px"
                  />
                </div>
              </div>
              <Row className="spaces pe-0 align-items-center">
                <TextField
                  name="comorbidity"
                  as="textarea"
                  rows={2}
                // value={}
                />
              </Row>
            </div>
            <div className="mb-2">
              <div className="d-flex ">
                <LabelRequired
                  label="Bệnh chính - YHCT"
                  className="min-w-150px"
                />
              </div>
              <Row className="spaces pe-0 align-items-center">
                <Col xs="3" className="pe-0 position-relative">
                  <TextField
                    name="traditionalMedicinePrimary"
                  // value={}
                  />
                  <div className="spaces width-4">
                    <h6
                      className="position-absolute muc-huong cursor-pointer"
                      onClick={() => { }}
                    >
                      <i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
                    </h6>
                  </div>
                </Col>
                <Col xs="9" className="ps-0">
                  <TextField
                    name="traditionalMedicinePrimary"
                  // value={}
                  />
                </Col>
              </Row>
            </div>

            <div>
              <div className="spaces d-flex flex-row justify-content-between">
                <div className="d-flex ">
                  <LabelRequired
                    label="Bệnh kèm theo - YHCT"
                    className="min-w-150px"
                  />
                </div>
                <Button
                  className="bg-white"
                  onClick={() => { }}
                  disabled={false}
                >
                  <u className="color-17A2B8">Chọn bệnh kèm theo</u>
                </Button>
              </div>
              <Row className="spaces pe-0 align-items-center">
                <TextField
                  name="traditionalMedicineComorbidity"
                  as="textarea"
                  rows={2}
                // value={}
                />
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="spaces mt-1">
            <div>
              <div className="d-flex mb-1">
                <LabelRequired
                  label="Lời dặn của bác sĩ"
                  className="min-w-150px"
                />
              </div>
              <TextField
                as="textarea"
                rows={2}
                name="doctorComment"
              // value={""}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className='d-flex flex-end mt-3'>
            <Button className='btn-fill min-w-80px'>Lưu</Button>
          </Col>
        </Row>
      </div>
    </Formik>
  )
}

export default XuTri