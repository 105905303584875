import React from 'react'
import { Col, Row } from 'react-bootstrap';
import LabelRequired from '../../../../component/LabelRequired';
import DanhSachPhieu from '../../../../component/DanhSachPhieu';
import { TableCollapseCustom } from '../../../../component/table/table-collapse/TableCollapseCustom';
import { columnsDSCdhaTdcn } from '../../../../phan-he-kham-benh/columns/tab-cdha-tdcn/ColumnTabCdhaTdcn';

type Props = {}

const CdhaTdcn = (props: Props) => {
  
  return (
    <Row className="h-100 spaces px-16 py-5">
      <div>
        <div className="pt-4 bg-white">
          <Row className="mx-0">
            <Col xs={4} className="d-flex align-items-center mb-3">
              <LabelRequired label="Mã phiếu" className="min-w-100px" />
              <span className="fw-light text-truncate">...</span>            </Col>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Ngày y lệnh" className="min-w-100px" />
              <span className="fw-light text-truncate">...</span>
            </Col>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Người chỉ định" className="min-w-100px" />
              <span className="fw-light">...</span>
            </Col>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Nơi chỉ định" className="min-w-100px" />
              <span className="fw-light text-truncate">...</span>
            </Col>
            <Col xs={8} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Chẩn đoán" className="min-w-100px" />
              <span className="fw-light text-truncate">...</span>
            </Col>
          </Row>
        </div>
        <div className="bg-white spaces pt-2 mb-6">
          <DanhSachPhieu
            className="mb-2"
            dsPhieu={[]}
            isGetFirstData
          />
          <TableCollapseCustom
            columns={columnsDSCdhaTdcn}
            data={[]}
            childrenField="subs"
            className={`overflow-auto spaces h-calc-vh-459`}
          />
        </div>
      </div>
    </Row>
  );
}

export default CdhaTdcn