import clsx from 'clsx';
import { useField } from 'formik';
import React, {
	ChangeEvent,
	FocusEvent,
	forwardRef,
	HTMLProps,
	KeyboardEvent,
	ReactNode,
	useEffect,
	useState,
} from 'react';
import { Form, FormControlProps, InputGroup } from 'react-bootstrap';
import { formatMoney } from '../../utils/FormatUtils';
import './style.scss';

type FormControlPropsWithoutFieldProps = Omit<
	FormControlProps,
	'onChange' | 'value' | 'name' | 'onBlur'
>;

type AdditionalInputProps = Pick<HTMLProps<HTMLInputElement>, 'autoFocus'>;

type Props = FormControlPropsWithoutFieldProps &
	AdditionalInputProps & {
		label?: string | ReactNode;
		name: string;
		className?: string;
		labelClassName?: string;
		inputClassName?: string;
		required?: boolean;
		value?: number;
		onChange?: (value: number) => void;
		onBlur?: (e: FocusEvent<HTMLInputElement>, value: number) => void;
		min?: number;
		max?: number;
		prefix?: ReactNode;
		suffix?: ReactNode;
		separator?: string;
	};

const NumberField = forwardRef<HTMLInputElement, Props>(
	(
		{
			label,
			name,
			className,
			labelClassName,
			inputClassName,
			required,
			value = 0,
			onChange,
			onBlur,
			min,
			max,
			prefix,
			suffix,
			separator,
			...props
		},
		ref
	) => {
		const [valueFormat, setValueFormat] = useState<string>('');
		const [isFocus, setIsFocus] = useState(false);

		const [field, { error, touched }, { setValue, setTouched }] = useField(name);

		const { value: formikFieldValue } = field;

		useEffect(() => {
			return () => {
				setValue('');
			};
		}, []);

		useEffect(() => {
			setValueFormat(formatMoney(Number(value || formikFieldValue)) || '');
		}, [value, formikFieldValue]);

		const onChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
			const inputValue = e.target.value;
			formatValue(inputValue);
		};

		const formatValue = (inputValue: string) => {
			const rawValue = removeSeparator(inputValue);

			if (isNaN(Number(rawValue))) return;

			const valueFormated = formatMoney(Number(rawValue)) || '';
			const numberValue = Number(rawValue);

			setValueFormat(valueFormated.toString());
			setValue(numberValue || undefined);
			onChange && onChange(numberValue);
		};

		const removeSeparator = (value: string) => {
			return value.trim().replaceAll('.', '');
		};

		return (
			<Form.Group className={clsx('d-flex align-items-center spaces w-100 oct-field-wrapper', className)}>
				{label && (
					<Form.Label
						className={clsx(
							'spaces text-lable-input max-content-width mb-0',
							labelClassName
						)}
					>
						{required ? (
							<span>
								{label}
								<span className="text-danger"> (*)</span>
							</span>
						) : (
							label
						)}
					</Form.Label>
				)}
				<input type="hidden" {...field} />

				<InputGroup className={clsx('position-relative')}>
					{prefix && (
						<InputGroup.Text
							id="input-number-addon"
							className={clsx(
								'customs-input border-end-0',
								`input-number-addon${isFocus ? '--focus' : ''}`
							)}
						>
							{prefix}
						</InputGroup.Text>
					)}
					<Form.Control
						ref={ref}
						className={clsx(
							'customs-input text-align-right spaces px-4 py-1 oct-input-number',
							{
								inputClassName,
								'border-start-0': prefix,
								'border-end-0': suffix,
								'is-invalid': !!error && touched,
							}
						)}
						name={`${name}-field`}
						value={valueFormat}
						onChange={onChangeInputValue}
						onBlur={(e: FocusEvent<HTMLInputElement>) => {
							onBlur && onBlur(e, formikFieldValue);
							setTouched(true);
							setIsFocus(false);
						}}
						onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
							const value = e.currentTarget.value;

							if (e.key === 'ArrowUp') {
								formatValue(Number(Number(removeSeparator(value)) + 1).toString());
							}

							if (e.key === 'ArrowDown') {
								formatValue(Number(Number(removeSeparator(value)) - 1).toString());
							}
						}}
						onFocus={() => setIsFocus(true)}
						aria-describedby="input-number-addon"
						{...props}
					/>
					{suffix && (
						<InputGroup.Text
							id="input-number-addon"
							className={clsx(
								'customs-input input-number-addon border-start-0',
								`input-number-addon${isFocus ? '--focus' : ''}`
							)}
						>
							{suffix}
						</InputGroup.Text>
					)}
					<Form.Control.Feedback type="invalid" tooltip className="field-tooltip-error">
						{error}
					</Form.Control.Feedback>
				</InputGroup>
			</Form.Group>
		);
	}
);

export default NumberField;
