import { IContextMenu } from "../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy";
import { IThoiDiemDung } from "../models/ThuocModels";

export const contextMenuChiTietMauThuocCu: IContextMenu[] = [
  {
    title: "Thuốc",
  },
  {
    icon: <i className="fa-solid fa-retweet text-pri"></i>,
    code: "thayTheThuocCungHoatChat",
    name: "Thay thế thuốc cùng hoạt chất",
  },
  {
    icon: <i className="fa-solid fa-retweet text-pri"></i>,
    code: "thayTheThuocBatKy",
    name: "Thay thế thuốc bất kỳ",
  },
  {
    icon: <i className="bi bi-x-lg text-danger"></i>,
    code: "xoa",
    name: "Xóa",
  },
];

export const CODE_MENU_THAY_THE_THUOC = {
  THAY_THE_THUOC_CUNG_HOAT_CHAT: "thayTheThuocCungHoatChat",
  THAY_THE_THUOC_BAT_KY: "thayTheThuocBatKy",
  XOA: "xoa",
}

export const CODE_MENU_DANH_SACH_MAU_CHI_DINH_THUOC = {
  SUA_MAU_THUOC: "suaMauThuoc",
  SUA_NHOM_DON_MAU: "suaNhomDonMau",
  XOA_MAU_THUOC: "xoaMauThuoc",
}

export const contextMenuDanhSachMauChiDinhThuoc: IContextMenu[] = [
  {
    title: "Mẫu thuốc",
  },
  {
    code: "suaMauThuoc",
    name: "Sửa mẫu thuốc",
  },
  {
    code: "suaNhomDonMau",
    name: "Sửa nhóm đơn mẫu",
  },
  {
    icon: <i className="bi bi-x-lg text-danger"></i>,
    code: "xoaMauThuoc",
    name: "Xóa mẫu thuốc",
  },
];

export const THOI_DIEM_DUNG_THUOC: IThoiDiemDung[] = [
  {
    label: 'SL sáng',
    name: 'quantityInMorning'
  },
  {
    label: 'SL trưa',
    name: 'quantityInNoon'
  },
  {
    label: 'SL chiều',
    name: 'quantityInAfternoon'
  },
  {
    label: 'SL tối',
    name: 'quantityInEvening'
  }
];

export const CHI_PHI_THUOC_FIELDS = {
  TIEN_DON: 'TIEN_DON',
  TONG_CHI_PHI: 'TONG_CHI_PHI',
  BHYT: 'BHYT',
  NGUON_KHAC: 'NGUON_KHAC',
  BENH_NHAN: 'BENH_NHAN',
  TAM_UNG: 'TAM_UNG',
  DA_THU: 'DA_THU',
  MIEN_GIAM: 'MIEN_GIAM',
  CON_DU: 'CON_DU'
};

export type CHI_PHI_THUOC_TYPE = keyof typeof CHI_PHI_THUOC_FIELDS;

export const LIEU_DUNG_THUOC_OPTIONS = [
	'1 liều/lần - 1 lần/ngày',
	'1 liều/lần - 2 lần/ngày',
	'1 liều/lần - 3 lần/ngày',
	'1 liều/lần - 4 lần/ngày',
];

export const HDSD_THUOC_OPTIONS = [
  'Uống sau khi ăn, thời gian giữa các lần không cách nhau dưới 4 tiếng'
];

export const CONTEXT_MENU_SUA_DOI_TUONG_KEYS = {
  SUA_DOI_TUONG_BHYT: 'DOI_TUONG_BHYT',
  SUA_DOI_TUONG_BHYT_YC: 'SUA_DOI_TUONG_BHYT_YC',
  SUA_DOI_TUONG_VIEN_PHI: 'SUA_DOI_TUONG_VIEN_PHI',
  SUA_DOI_TUONG_YEU_CAU: 'SUA_DOI_TUONG_YEU_CAU',
  SUA_DOI_TUONG_HAO_PHI_KHAC: 'SUA_DOI_TUONG_HAO_PHI_KHAC'
};

export const CONTEXT_MENU_SUA_DOI_TUONG_OPTIONS: IContextMenu = {
  icon: <i className="bi bi-pencil-square text-pri"></i>,
  name: "Sửa đối tượng",
  children: [
    { code: CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_BHYT, name: "Bảo Hiểm Y Tế" },
    { code: CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_BHYT_YC, name: "Bảo Hiểm Y Tế + Yêu cầu" },
    { code: CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_VIEN_PHI, name: "Viện Phí" },
    { code: CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_YEU_CAU, name: "Yêu Cầu" },
    { code: CONTEXT_MENU_SUA_DOI_TUONG_KEYS.SUA_DOI_TUONG_HAO_PHI_KHAC, name: "Hao Phí Khác" },
  ],
}

export const CONTEXT_MENU_KE_THUOC_KEY = {
    XOA_THUOC: 'XOA_THUOC',
    ...CONTEXT_MENU_SUA_DOI_TUONG_KEYS
};

export const contextMenuKeThuocOptions: IContextMenu[] = [
    {
        icon: <i className="bi bi-x-lg text-danger"></i>,
        code: CONTEXT_MENU_KE_THUOC_KEY.XOA_THUOC,
        name: 'Xóa thuốc',
    },
    CONTEXT_MENU_SUA_DOI_TUONG_OPTIONS
];

export const MA_LOAI_DOI_TUONG = {
  BHYT: 'BHYT',
  BHYT_YEU_CAU: 'BHYT',
  YEU_CAU: 'YC',
  VIEN_PHI: 'VP',
  HAO_PHI_KHAC: 'HPK',
};

export const WORKFLOW_STATUS = {
  CLINICAL_CONCLUSION: 7
};

export const WORKFLOW_MEDICAL_ITEM_STATUS_CODE = {
  GUI_DON: "medical_item_sent",
  CHUA_GUI_DON: "medical_item_new",
  CHO_XUAT_KHO: "medical_item_await_release",
  DA_XUAT_KHO: "medical_item_released"
}

export const DRUG_WAREHOUSE_FORM_MAPPER = {
  donViTinh: 'unitofMeasureName',
  duongDung: 'roa',
  actIngName: 'actIngName',
  idThuoc: 'id',
  maThuoc: 'code',
  tenThuoc: 'name',
  soLuongKhaDung: 'iventory',
}

export const KHO_NHA_THUOC_ID = '32';