import { useRef, useState } from 'react';
import { Row } from 'react-table';
import { ConfirmDialog } from '../../../../../component/ConfirmDialog';
import ContextMenu from '../../../../../component/ContextMenuV3';
import CustomIconButton from '../../../../../component/custom-icon-button/CustomIconButton';
import { TableCustomHeader } from '../../../../../component/table/components/TableCustomHeader';
import { TableCustom } from '../../../../../component/table/table-custom/TableCustom';
import { columnsPhieuChiDinhKeVatTu } from '../../../../columns/modal-ke-vat-tu/ColumnModalKeVatTu';
import { contextMenuKeVatTuOptions, CONTEXT_MENU_KE_VAT_TU_KEY } from '../../../../constants/VatTu';
import { useKeVatTuContext } from '../../context';
import { useTableSelectVatTuContext } from '../../context/TableSelectVatTuContext';

const TablePhieuChiDinhVatTu = () => {
	const { vatTuChecked, setVatTuChecked, setDeletedList } = useKeVatTuContext();
	const { setSelectedRow, setEditRowIndex } = useTableSelectVatTuContext();

	const [contextMenu, setContextMenu] = useState<null | {
		x: number;
		y: number;
	}>(null);
	const rowSelectedRef = useRef<any>();
	const [openCofirmDeleteDialog, setOpenCofirmDeleteDialog] = useState<boolean>(false);

	const onClickContextMenu = (value: any) => {
		const actionCode = value.code;
		switch (actionCode) {
			case CONTEXT_MENU_KE_VAT_TU_KEY.XOA_VAT_TU:
				setOpenCofirmDeleteDialog(true);
				break;
			default:
				break;
		}
		setContextMenu(null);
	};

	const handleContextMenu = (e: any, row: Row) => {
		e.preventDefault();
		setContextMenu({ x: e.clientX, y: e.clientY });
		rowSelectedRef.current = row.original;
	};

	const onDeleteVatTu = () => {
		if (rowSelectedRef.current.idBackend) {
			setDeletedList(rowSelectedRef.current.idBackend, {
				...rowSelectedRef.current,
				deleted: true,
			});
		}
		const listTemp = vatTuChecked.filter(row => row.id !== rowSelectedRef.current.id);
		setVatTuChecked(listTemp.map((row, index) => ({ ...row, index })));
		setOpenCofirmDeleteDialog(false);
	};

	const columnsActions = {
		Header: (props: any) => (
			<TableCustomHeader
				tableProps={props}
				title="Thao tác"
				className="text-center text-light min-w-80px"
			/>
		),
		id: 'actions',
		Cell: ({ ...props }) => (
			<div className="d-flex justify-content-center">
				<CustomIconButton
					variant="edit"
					onClick={() => {
						const rowData = props.row.original;
						setEditRowIndex(props.row.index);
						setSelectedRow({
							...rowData,
							id: rowData?.idVatTu?.toString() || rowData?.id,
						});
					}}
				>
					<i className="bi bi-pencil-square text-navy"></i>
				</CustomIconButton>
				<CustomIconButton
					variant="delete"
					onClick={() => {
						rowSelectedRef.current = props.row.original;
						setOpenCofirmDeleteDialog(true);
					}}
				>
					<i className="bi bi-trash3-fill text-danger"></i>
				</CustomIconButton>
			</div>
		),
	};

	return (
		<div className="d-flex flex-column flex-grow-1 mt-2 bg-white">
			<div className="d-flex spaces pt-10 pb-6 text-pri fw-bold">Phiếu chỉ định</div>
			<div className="flex-grow-1 spaces h-calc-vh-710">
				<TableCustom
					columns={columnsPhieuChiDinhKeVatTu}
					data={vatTuChecked}
					handleContextMenu={handleContextMenu}
					height={220}
					maxHeight={220}
					handleDoubleClick={(row: any) => {
						setEditRowIndex(row.index);
						setSelectedRow(row.original);
					}}
					columnsActions={columnsActions}
				/>
			</div>

			{contextMenu && (
				<ContextMenu
					handleClickOptionContextMenu={onClickContextMenu}
					handleCloseMenu={() => setContextMenu(null)}
					data={contextMenuKeVatTuOptions}
					target={contextMenu}
				/>
			)}
			{openCofirmDeleteDialog && (
				<ConfirmDialog
					className="z-index-1060"
					show={openCofirmDeleteDialog}
					title="Thông báo"
					message="Bạn có chắc chắn muốn xóa vật tư này không ?"
					yes="Xác nhận"
					close="Đóng"
					onCloseClick={() => {
						setOpenCofirmDeleteDialog(false);
						rowSelectedRef.current = null;
					}}
					onYesClick={onDeleteVatTu}
				/>
			)}
		</div>
	);
};

export default TablePhieuChiDinhVatTu;
