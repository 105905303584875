import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import InfoPatientRight from '../../../phan-he-kham-benh/components/InfoPatientRight'
import { IconBack } from '../../../component/IconSvg'
import TextField from '../../../component/TextField'
import { OctDatePickerCustom } from '@oceantech/oceantech-ui'
import AutocompleteV2 from '../../../component/AutocompleteObjectV2'
import LabelRequired from '../../../component/LabelRequired'
import ProvisonalDiagnosis from '../../../phan-he-kham-benh/components/ProvisonalDiagnosis'
import Buong from '../buong/Buong'
import Giuong from '../giuong/Giuong'

type Props = {
    handleClose: () => void
}

const ChiDinhPhong = ({ handleClose }: Props) => {
    const [IsShowGiuong, setIsShowGiuong] = useState<boolean>(false);
    const dsBuong = [
        { name: "Buồng 101", used: 5, total: 5 },
        { name: "Buồng 102", used: 5, total: 5 },
        { name: "Buồng 103", used: 3, total: 5 },
        { name: "Buồng 104", used: 1, total: 5 },
        { name: "Buồng 105", used: 4, total: 5 },
        { name: "Buồng 106", used: 2, total: 5 },
        { name: "Buồng 107", used: 3, total: 5 },
        { name: "Buồng 108", used: 1, total: 5 },
        { name: "Buồng 109", used: 4, total: 5 },
        { name: "Buồng 110", used: 2, total: 5 },
        { name: "Buồng 111", used: 3, total: 5 },
        { name: "Buồng 112", used: 1, total: 5 },
        { name: "Buồng 113", used: 4, total: 5 },
        { name: "Buồng 114", used: 2, total: 5 },
        { name: "Buồng 115", used: 3, total: 5 },
        { name: "Buồng 116", used: 1, total: 5 },
        { name: "Buồng 117", used: 4, total: 5 },
        { name: "Buồng 118", used: 2, total: 5 },
        { name: "Buồng 119", used: 3, total: 5 },
        { name: "Buồng 120", used: 1, total: 5 },
    ];
    const dsGiuong = [
        { name: "Giường H31", used: 0, total: 1, isBaoTri: false },
        { name: "Giường H32", used: 0, total: 1, isBaoTri: false },
        { name: "Giường H33", used: 1, total: 1, isBaoTri: true },
        { name: "Giường H34", used: 1, total: 2, isBaoTri: false },
        { name: "Giường H35", used: 1, total: 1, isBaoTri: false },
        { name: "Giường H36", used: 2, total: 2, isBaoTri: false },
        { name: "Giường H37", used: 3, total: 3, isBaoTri: false },
        { name: "Giường H31", used: 0, total: 1, isBaoTri: false },
        { name: "Giường H32", used: 0, total: 1, isBaoTri: false },
        { name: "Giường H33", used: 1, total: 1, isBaoTri: true },
        { name: "Giường H34", used: 1, total: 2, isBaoTri: false },
        { name: "Giường H35", used: 1, total: 1, isBaoTri: false },
        { name: "Giường H36", used: 2, total: 2, isBaoTri: false },
        { name: "Giường H37", used: 3, total: 3, isBaoTri: false },
    ];
    return (
        <Modal
            className="page-full"
            fullscreen
            dialogClassName="h-modal"
            size="xl"
            show={true}
            animation={false}
            centered
            backdropClassName="spaces top-50"
        >
            <Formik initialValues={{}} onSubmit={() => { }}>
                <Form>
                    <div className="h-100 bg-white">
                        <Modal.Header className="py-3 header-modal">
                            <div className="d-flex gap-6">
                                <div
                                    className="d-flex align-items-center gap-3 cursor-pointer"
                                    onClick={() => handleClose()}
                                >
                                    <IconBack />
                                    <span className="fw-500">Quay lại</span>
                                </div>
                            </div>
                            {true ? (
                                <InfoPatientRight benhNhanInfo={{}} />
                            ) : (
                                <div className="spaces h-65"></div>
                            )}
                        </Modal.Header>
                        <Modal.Body className="px-0 py-0 spaces overflow-hidden spaces h-calc-vh-160">
                            <Row className="spaces px-10">
                                <Col xs="6" className='border-end border-end-3 spaces px-10'>
                                    <h4 className="text-title fw-bold fs-4 spaces mb-0">Chỉ định giường cho bệnh nhân</h4>
                                    <Row>
                                        <Col xs="12" className='d-flex spaces mb-10'>
                                            <LabelRequired className="min-w-150px" label="Ngày cập nhật" />
                                            <OctDatePickerCustom
                                                value={""}
                                                setDateValue={(date) => { }}
                                            />
                                        </Col>
                                        <Col xs="12" className='spaces mb-10'>
                                            <TextField
                                                label="Khoa"
                                                name="khoa"
                                                value={""}
                                                labelClassName="ms-0 min-w-150px"
                                            />
                                        </Col>
                                        <Col xs="8" className='d-flex spaces mb-10'>
                                            <LabelRequired className="min-w-150px" label="Phòng" />
                                            <AutocompleteV2
                                                options={[]}
                                                value={null}
                                                name="visitObjectType"
                                                onChange={(selectedOption) => { }}
                                                className="autocomplete-custom-tiep-nhan radius spaces h-29"
                                            />
                                        </Col>
                                        <Col xs="4" className='d-flex'>
                                            <LabelRequired className="min-w-90px" label="Buồng" />
                                            <AutocompleteV2
                                                options={[]}
                                                value={null}
                                                name="visitObjectType"
                                                onChange={(selectedOption) => { }}
                                                className="autocomplete-custom-tiep-nhan radius spaces h-29"
                                            />
                                        </Col>
                                        <Col xs="12" className='d-flex spaces mb-10'>
                                            <LabelRequired className="min-w-150px" label="Giường" />
                                            <AutocompleteV2
                                                options={[]}
                                                value={null}
                                                name="visitObjectType"
                                                onChange={(selectedOption) => { }}
                                                className="autocomplete-custom-tiep-nhan radius spaces h-29"
                                            />
                                        </Col>
                                        <Col xs="12" className='d-flex spaces mb-10'>
                                            <LabelRequired className="min-w-150px" label="Dịch vụ ngày giường" />
                                            <AutocompleteV2
                                                options={[]}
                                                value={null}
                                                name="visitObjectType"
                                                onChange={(selectedOption) => { }}
                                                className="autocomplete-custom-tiep-nhan radius spaces h-29"
                                            />
                                        </Col>
                                        <Col xs="12" className='d-flex spaces mb-10'>
                                            <LabelRequired className="min-w-150px" label="Bác sĩ điều trị" />
                                            <AutocompleteV2
                                                options={[]}
                                                value={null}
                                                name="visitObjectType"
                                                onChange={(selectedOption) => { }}
                                                className="autocomplete-custom-tiep-nhan radius spaces h-29"
                                            />
                                        </Col>
                                        <Col xs="12" className='d-flex spaces mb-10'>
                                            <LabelRequired className="min-w-150px" label="Loại bệnh án" />
                                            <AutocompleteV2
                                                options={[]}
                                                value={null}
                                                name="visitObjectType"
                                                onChange={(selectedOption) => { }}
                                                className="autocomplete-custom-tiep-nhan radius spaces h-29"
                                            />
                                        </Col>
                                        <Col xs="12" className='d-flex flex-end'>
                                            <Button
                                                className="bg-white"
                                            >
                                                <u className="color-17A2B8">Sử dụng chẩn đoán ỏ khám bệnh</u>
                                            </Button>
                                        </Col>
                                        <Col xs="12" className='d-flex spaces mb-10'>
                                            <LabelRequired
                                                label="Chẩn đoán vào khoa"
                                                className="min-w-150px"
                                            />
                                            <Row className="spaces pe-0 align-items-center position-relative">
                                                <ProvisonalDiagnosis
                                                    encounterDiagnosisValues={{}}
                                                    encounterDiagnosisErrors={{}}
                                                    encounterDiagnosisTouched={{}}
                                                    benhNhanInfo={{}}
                                                    listComorbidity={[]}
                                                    setListComorbidity={() => { }}
                                                    icd10Field="icdXPrimary"
                                                    onChange={() => { }}
                                                    handleChangeEncounterDiagnosis={() => { }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col xs="12" className='spaces mb-10'>
                                            <TextField
                                                label="Chẩn đoán kèm theo"
                                                name="khoa"
                                                value={""}
                                                className="resize-none"
                                                labelClassName="ms-0 min-w-150px"
                                                as="textarea"
                                                rows={3}
                                            />
                                        </Col>

                                        <Col xs="12" className='d-flex spaces mb-10'>
                                            <LabelRequired
                                                label="Bệnh chính - YHCT"
                                                className="min-w-150px"
                                            />
                                            <Row className="spaces pe-0 align-items-center position-relative">
                                                <ProvisonalDiagnosis
                                                    encounterDiagnosisValues={{}}
                                                    encounterDiagnosisErrors={{}}
                                                    encounterDiagnosisTouched={{}}
                                                    benhNhanInfo={{}}
                                                    listComorbidity={[]}
                                                    setListComorbidity={() => { }}
                                                    icd10Field="icdXPrimary"
                                                    onChange={() => { }}
                                                    handleChangeEncounterDiagnosis={() => { }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col xs="12" className='spaces mb-10'>
                                            <TextField
                                                label="Bệnh kèm theo - YHCT"
                                                name="khoa"
                                                value={""}
                                                labelClassName="ms-0 min-w-150px"
                                                className="resize-none"
                                                as="textarea"
                                                rows={3}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs="6" className='spaces h-calc-vh-160 spaces px-20 overflow-auto'>
                                    {
                                        IsShowGiuong
                                            ? <Row className='slide-in position-relative'>
                                                <Col xs="12" className='position-sticky spaces top-0 left-0 right-0 z-index-1 pt-10 bg-white'>
                                                    <div className="d-flex spaces gap-6 pb-10 w-100">
                                                        <div
                                                            className="d-flex align-items-center gap-3 cursor-pointer w-100"
                                                            onClick={() => setIsShowGiuong(false)}
                                                        >
                                                            <IconBack />
                                                            <span className="fw-500">Quay lại</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                {
                                                    dsGiuong.map((item: any, index) => (
                                                        <Col xs="6" className='spaces mb-10'>
                                                            <Giuong key={index}
                                                                name={item.name}
                                                                used={item.used}
                                                                total={item.total}
                                                                isBaoTri={item.isBaoTri}
                                                            />
                                                        </Col>
                                                    ))
                                                }
                                            </Row>
                                            : <>{
                                                dsBuong.map((item: any, index) => (
                                                    <Buong key={index}
                                                        name={item.name}
                                                        used={item.used}
                                                        total={item.total}
                                                        onClick={() => setIsShowGiuong(true)}
                                                    />
                                                ))
                                            }</>
                                    }
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer className='d-flex flex-end'>
                            <Button className="btn-fill spaces mr-10 min-w-60" onClick={handleClose}>Lưu</Button>
                            <Button className="btn-secondary spaces min-w-60" onClick={handleClose}>Đóng</Button>
                        </Modal.Footer>
                    </div>
                </Form>
            </Formik>
        </Modal>
    )
}

export default ChiDinhPhong