import React from 'react'
import LazyLoadTable from '../../../../component/lazy-load-table/LazyLoadTable';

type Props = {}

const LichSuDieuTri = (props: Props) => {

    const columns = [
        {
            name: "TT",
            field: "",
            headerStyle: {
                minWidth: "50px",
            },
        },
        {
            name: "Ngày vào",
            field: "",
            headerStyle: {
                minWidth: "100px",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData: any, index: number) => index + 1,
        },
        {
            name: "Hình thức vào",
            field: "",
            headerStyle: {
                minWidth: "150px",
            },
        },
        {
            name: "Khoa",
            field: "",
            headerStyle: {
                minWidth: "150px",
            },
        },
        {
            name: "Phòng khám",
            field: "",
            headerStyle: {
                minWidth: "150px",
            },
        },
        {
            name: "Bác sĩ",
            field: "",
            headerStyle: {
                minWidth: "150px",
            },
        },
        {
            name: "Thẻ BHYT",
            field: "",
            headerStyle: {
                minWidth: "100px",
            },
        },
        {
            name: "Ngày sinh",
            field: "",
            headerStyle: {
                minWidth: "100px",
            },
        },
        {
            name: "Ngày ra",
            field: "",
            headerStyle: {
                minWidth: "100px",
            },
        },
        {
            name: "Xử trí",
            field: "",
            headerStyle: {
                minWidth: "100px",
            },
        },
        {
            name: "Chẩn đoán",
            field: "",
            headerStyle: {
                minWidth: "150px",
            },
        },
        {
            name: "Thông tin khác",
            field: "",
            headerStyle: {
                minWidth: "150px",
            },
        },
    ];

    return (
        <div className="spaces px-16 py-5">
            <div className="spaces h-calc-vh-300">
                <LazyLoadTable
                    columns={columns}
                    urlData="data.data.medicalExamBrief"
                    className="spaces h-calc-vh-300"
                    rowKey="orderId"
                />
            </div>
        </div>
    )
}

export default LichSuDieuTri