import { Form, useFormikContext } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import TextField from "../../../../component/TextField";
import { formatDateToDDMMYYYY, formatMoney } from "../../../../utils/FormatUtils";
import LabelRequired from "../../../../component/LabelRequired";
import AutocompleteObjectV2 from "../../../../component/AutocompleteObjectV2";
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../../../utils/Constant";
import { getDanhSachHinhThucService } from "../../../services/KhamDoanHopDongServices";
import { AppContext } from "../../../../appContext/AppContext";
import { getDanhSachLoaiPhieuService, getFormFieldConfig } from "../../../../phan-he-vien-phi/services/PhanHeVienPhiServices";
import { CATEGORY_TYPE, INV_TYPE_CODE } from "../../../../phan-he-vien-phi/const/VienPhiConstant";
import { initialValuesFormThanhToanHD } from "../ThanhToanHDConstant";
import { ThanhToanHDContext } from "../ThanhToanHD";
import { TYPE_RECEIPT } from "../../../constants/PhanHeKhamDoan";
import { KEY_LOCALSTORAGE } from "../../../../auth/core/_consts";
import { localStorageItem } from "../../../../utils/LocalStorage";
import NumberField from "../../../../component/number-field";

interface FormValues {
  orderSetDataIds: string[];
  loaiPhieu: any;
  hinhThuc: any;
  invDate: string;
  invCode: string;
  invCashier: string;
  invTotal: number;
  note: string;
}

const PhieuThanhToanHD: FC<any> = (props) => {
  const {
    openPhieuThu,
    setOpenPhieuThu,
    HDSelected,
    onlyView,
    setOnlyView,
    setInitialValues,
    onlyViewInvTotal,
    setOnlyViewInvTotal,
    thongTinThanhToan,
    listGKClone,
    listDanhSachPhieu
  } = props;
  const { DSGoiKham, setDSGoiKham } = useContext(ThanhToanHDContext);
  const { setIsLoading } = useContext(AppContext);
  const  { values, setFieldValue, touched, errors, handleSubmit } = useFormikContext<FormValues>();
  const [dsHinhThuc, setDSHinhThuc] = useState<any[]>([]);
  const [dsLoaiPhieu, setDSLoaiPhieu] = useState<any[]>([]);
  const currentUser = localStorageItem.get(KEY_LOCALSTORAGE.CURRENT_USER);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [paymentItem, setPaymentItem] = useState<any>([]);

  const updateTotalPayment = () => {
    let orderIds: any = [];
    let invTotal: any = 0;
    let goiKhamSelected: any = [];

    DSGoiKham.forEach((goiKham: any) => {
      const newOrderIds = goiKham.idOrderSetData || [];
      if (goiKham.checked) {
        orderIds = orderIds.concat(newOrderIds);
        invTotal = invTotal + parseFloat(goiKham.totalFee || '0') - parseFloat(goiKham.totalFeePaid || '0')
        goiKhamSelected.push({
          ...goiKham,
          invTotal: parseFloat(goiKham.totalFee || '0') - parseFloat(goiKham.totalFeePaid || '0')
        });
      }
    });
    
    if (DSGoiKham.some((goiKham: any) => goiKham.checked)) {
      let noteString = goiKhamSelected.map((item: any) => {
        return `${item?.nameGK}: ${item?.invTotal}đ`
      }).join("; ");

      setFieldValue('note', noteString);
    } else {
      setFieldValue('note', '');
    };

    setFieldValue('orderSetDataIds', orderIds);
    setFieldValue('invTotal', invTotal);
  };

  useEffect(() => {
    updateTotalPayment();
  }, [DSGoiKham])

  const updatePageData = async () => {
    try {
      setIsLoading(true);
      const searchObject = {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE,
      };
      const resHinhThuc = await getDanhSachHinhThucService({...searchObject, type: CATEGORY_TYPE.HINH_THUC.code});
      const resLoaiPhieu = await getDanhSachLoaiPhieuService({...searchObject, type: CATEGORY_TYPE.LOAI_PHIEU.code});
      setDSHinhThuc(resHinhThuc?.data?.data?.content || []);
      setDSLoaiPhieu(resLoaiPhieu?.data?.data?.content || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
    setFieldValue('invCashier', currentUser?.name);
  },[]);

  useEffect(() => {
    if (listDanhSachPhieu?.length > 0) {
      //TH HĐ không có phiếu tạm ứng thì k hiển thị loại phiếu Hoàn ứng
      const hasPhieuTamUng = listDanhSachPhieu.some((item: any) => item?.invTypeCode === INV_TYPE_CODE.PHIEU_TAM_UNG.code);
      const newArr: any[] = !hasPhieuTamUng ? dsLoaiPhieu?.filter((item: any) => item?.code !== INV_TYPE_CODE.PHIEU_HOAN.code) : dsLoaiPhieu;
      setDSLoaiPhieu(newArr);
    }
  }, [listDanhSachPhieu]);

  useEffect(()=>{
    setOpenPhieuThu(false);
  },[HDSelected])

  const handleSetPaymentInfor = async () => {
    const FORM_FIELD_CONFIG = [
      {
        code: "TONG_CHI_PHI",
        name: "Tổng chi phí:",
        value: formatMoney(thongTinThanhToan?.ttTong || 0),
        type: "price",
        colorName: "fw-bold text-danger",
        colorValue: "text-danger",
      },
      {
        code: "TAM_UNG",
        name: "Tạm ứng:",
        value: formatMoney(thongTinThanhToan?.ttTamUng || 0),
        type: "price",
        colorName: "fw-bold",
        colorValue: "color-count-number",
      },
      {
        code: "DA_THU",
        name: "Đã thu:",
        value: formatMoney(thongTinThanhToan?.ttDaThu || 0),
        type: "price",
        colorName: "fw-bold",
        colorValue: "color-count-number",
      },
      {
        code: "CON_NO",
        name: thongTinThanhToan?.ttConNo > 0 ? "Còn nợ:" : "Còn dư:",
        value: formatMoney(Math.abs(thongTinThanhToan?.ttConNo) || 0),
        type: "price",
        colorName: "fw-bold",
        colorValue: "color-count-number",
      },
    ]
    try {
      let { data: { data } } = await getFormFieldConfig("FORM_INFO_PAYMENT_PE");
      const jsonObject = JSON.parse(data[0]?.valueText);
      
      let newArr: any[] = [];
      jsonObject.forEach((item: any) => {
        let obj = FORM_FIELD_CONFIG.find((obj: any) => obj?.code === item);
        
        newArr.push(obj);
      })
      
      setPaymentItem(newArr);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleSetPaymentInfor();
  }, [thongTinThanhToan]);

  return (
    <Form id="form-phieu-thu-hd" onSubmit={handleSubmit}>
      <Row>
        <Col xs="12" className="d-flex gap-10">
          {paymentItem?.map((item: any, index: number) => (
            <div className="d-flex justify-content-between min-w-200px" key={index}>
              <span className={`${item?.colorName}`}>{item?.name}</span>
              <span className={`${item?.colorValue}`}>{item?.value}</span>
            </div>
          ))}
          <div className="flex-grow-1"></div>
        </Col>
        <Col xs="12" className="line-bottom spaces pt-10 mb-10 w-2000px"></Col>
        {openPhieuThu && (
          <>
            <Col xs="4" className="d-flex">
              <LabelRequired label="Số phiếu" className="min-w-105px"/>
              <span className="text-gray spaces mt-1">{values.invCode}</span>
            </Col>
            <Col xs="4" className="d-flex">
              <LabelRequired label="Người thu" className="min-w-100px"/>
              <span className="text-gray spaces mt-1">{values.invCashier}</span>
            </Col>
            <Col xs="4" className="d-flex">
              <LabelRequired label="Ngày thu" className="min-w-100px"/>
              <span className="text-gray spaces mt-4">{formatDateToDDMMYYYY(values.invDate)}</span>
            </Col>
            <Col xs="4" className="spaces py-10 d-flex">
              <LabelRequired
                isRequired
                className="min-w-100px"
                label="Loại phiếu"
              />
              <AutocompleteObjectV2
                options={dsLoaiPhieu || []}
                name="loaiPhieu"
                value={values.loaiPhieu || ""}
                onChange={(selectedOption: any) => {
                  if (selectedOption.code === TYPE_RECEIPT.PHIEU_THU) {
                    setFieldValue("invTotal", 0);
                    setOnlyViewInvTotal(selectedOption.code === TYPE_RECEIPT.PHIEU_THU);
                  } else if (selectedOption.code === TYPE_RECEIPT.TAM_UNG) {
                    setFieldValue("note", "Thu tạm ứng");
                    setFieldValue("invTotal", "");
                    setOnlyViewInvTotal(false);
                    setIsDisable(false);
                    setDSGoiKham(listGKClone);
                  } else if (selectedOption.code === TYPE_RECEIPT.PHIEU_HOAN) {
                    setFieldValue("note", "");
                    setFieldValue("invTotal", thongTinThanhToan?.ttTamUng);
                    setIsDisable(selectedOption.code === TYPE_RECEIPT.PHIEU_HOAN);
                    setDSGoiKham(listGKClone);
                  } else {
                    setFieldValue("note", "");
                    setFieldValue("invTotal", "");
                    setIsDisable(false);
                    setOnlyViewInvTotal(false);
                    setDSGoiKham(listGKClone);
                  }
                  setFieldValue("loaiPhieu", selectedOption);
                }}
                isClearable={false}
                className="spaces autocomplete-custom-tiep-nhan h-29 ml-6"
                touched={touched.loaiPhieu}
                errors={errors.loaiPhieu}
                isDisabled={onlyView}
              />
            </Col>
            <Col xs="4" className="spaces py-10">
              <NumberField
                label={
                  <div>
                    Số tiền
                    <span className="text-danger"> (*)</span>
                  </div>
                }
                labelClassName="min-w-100px"
                className="input"
                name="invTotal"
                disabled={onlyView || onlyViewInvTotal || isDisable}
              />
            </Col>
            <Col xs="4" className="spaces py-10">
              <span className="text-error text-decoration-underline cursor-pointer">
                {values.invTotal ? formatMoney(values.invTotal) : 0} VNĐ
              </span>
            </Col>
            <Col xs="4" className="d-flex">
              <LabelRequired
                isRequired
                className="min-w-100px"
                label="Hình thức"
              />
              <AutocompleteObjectV2
                options={dsHinhThuc || []}
                name="hinhThuc"
                value={values.hinhThuc || ""}
                onChange={(selectedOption: any) => {
                  setFieldValue("hinhThuc", selectedOption);
                }}
                isClearable={false}
                className="spaces autocomplete-custom-tiep-nhan h-29 ml-6"
                touched={touched.hinhThuc}
                errors={errors.hinhThuc}
                isDisabled={true}
              />
            </Col>
            <Col xs="8">
              <TextField
                label="Ghi chú"
                labelClassName="min-w-100px"
                className="input"
                name="note"
                disabled={onlyView}
              />
            </Col>
          </>
        )}
        <Col xs="12" className="spaces h-50 py-10 d-flex justify-content-start gap-5">
          {(!openPhieuThu && HDSelected.id) && (
            <Button className="btn-fill" onClick={() => {
              setFieldValue('hinhThuc', {
                id: HDSelected.paymentMethodId,
                name: HDSelected.paymentMethodName,
              })
              setOpenPhieuThu(true);
            }}>
              Tạo phiếu
            </Button>
          )}
          {openPhieuThu && (
            <>
              {!onlyView && 
                <Button className="btn-fill" onClick={() => {
                  setOnlyViewInvTotal(false);
                  handleSubmit();
                }}>
                  Lưu
                </Button>
              }
              <Button
                className="btn-outline"
                onClick={() => {
                  setOpenPhieuThu(false);
                  setOnlyViewInvTotal(false);
                  setOnlyView(false);
                  setDSGoiKham(listGKClone);
                  setInitialValues({...initialValuesFormThanhToanHD, invDate: new Date()})
                }}
              >
                { onlyView ? "Đóng" : "Hủy" }
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default PhieuThanhToanHD;