import { Form, Formik } from "formik";
import { FC, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import TextField from "../../../component/TextField";
import { inBarCode } from "../../service/tiepNhan";
import FormInPhieuDialog from "../../../component/button-in-phieu/components/PrintDialog";
import { toast } from "react-toastify";

interface Props {
    quantity: number;
    handleClose: () => void;
    params?: any;
    fetchExport?: (params: any) => any;
}

interface IQuantity {
    quantity: number;
}

const ModalNhapSoLuongInBarcode: FC<Props> = (props) => {
    let { quantity, handleClose, params, fetchExport = inBarCode } = props;
    const [openPrintDialog, setOpenPrintDialog] = useState<boolean>(false);

    let validationSchema = Yup.object({
        quantity: Yup.number().required("Không được để trống số lượng"),
    });

    const handleSubmit = (values: IQuantity) => {
        // if (values.quantity < 1 || values.quantity > props.quantity) {
        //     toast.warning("Số lượng in không được nhỏ hơn 1 hoặc lớn hơn số lượng dịch vụ trong phiếu!");
        //     return;
        // }
        setOpenPrintDialog(true);
    };

    return (
        <Modal show={true} animation centered onHide={handleClose} size="sm" className="dialog-background">
            <Formik<IQuantity> initialValues={{ quantity }} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({values, handleChange}) => (
                    <Form>
                        <div className="timKiemBenhNhan">
                            <Modal.Header className="py-3 header-modal" closeButton>
                                <Modal.Title className="text-pri">Nhập số lượng in barcode</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="spaces py-16 px-24 mb-10">
                                <div className="spaces flex-2 mb-4">
                                    <TextField
                                        name="quantity"
                                        className="custom-input no-spinners"
                                        labelClassName="min-w-75px"
                                        type="number"
                                        values={values.quantity || 1}
                                        onChange={handleChange}
                                        />
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="d-flex justify-content-center py-1">
                                <Button className="btn-fill spaces min-w-70" type="submit">
                                    In
                                </Button>
                                <Button className="btn-outline" onClick={handleClose}>
                                    Đóng
                                </Button>
                            </Modal.Footer>
                            {openPrintDialog && (
                                <FormInPhieuDialog<any>
                                    show={openPrintDialog}
                                    onHide={() => {
                                        setOpenPrintDialog(false);
                                        handleClose();
                                    }}
                                    fetchExport={fetchExport}
                                    params={{
                                        quantity: values.quantity,
                                        ...params
                                    }}
                                />
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ModalNhapSoLuongInBarcode;
