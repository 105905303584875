import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { toast } from "react-toastify";
import DanhSachPhieu from "../../../component/DanhSachPhieu";
import LabelRequired from "../../../component/LabelRequired";
import { TableCollapseCustom } from "../../../component/table/table-collapse/TableCollapseCustom";
import { APP_DATE_TIME_FORMAT, CODE, CODE_MENU, KEY, LIST_COLOR_TRANG_THAI_PHIEU, MENU_ACTION, ORDER_TYPE_ID, RESPONSE_MESSAGE, STATUS_CANCEL, TERM_TYPE } from "../../../utils/Constant";
import { formatDateTime } from "../../../utils/FormatUtils";
import { WfConstTracker, WfConstTrackerClinical } from "../../../workflows/Constant";
import WfButton from "../../../workflows/components/WfButton";
import "../../PhanHeKhamBenh.scss";
import { columnsDSCdhaTdcn } from "../../columns/tab-cdha-tdcn/ColumnTabCdhaTdcn";
import DynamicComponentKB from "../../components/DynamicComponentKB";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import { deleteDSChiDinhDV, getDSDVDaChiDinh, getDSNhomDVDaChiDinh } from "../../services/ChiDinhDVService";
import { fetchGuiPhieu, fetchHuyGuiPhieu, getStatusDesc } from "../../services/KhamBenhService";
import CheckIcon from "../../components/CheckIcon";
import ModalThucHienCDHA from "../../../phan-he-cdha-va-tdcn/components/modals/ModalThucHienCDHA";
import ButtonInPhieu from "../../../component/button-in-phieu";
import { inPhieuChiDinhChung, inPhieuChiDinhCLS, inPhieuKetQuaCLS } from "../../services/PhanHeTiepDonServer";
import ShowStatus from "../../../component/ShowStatus";
import { IContextMenu } from "../../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy";
import ContextMenu from "../../../component/ContextMenuV3";
import { cancelService } from "../../../utils/ServicesUtils";
import { ConfirmReasonDialog } from "../../../component/ConfirmReasonDialog";
import { getPaymentDetail } from "../../../phan-he-vien-phi/services/PhanHeVienPhiServices";

export type KhamBenh = {
  thongTinKhamBenh?: any;
  setThongTinKhamBenh: ((data: any) => void) | undefined;
};

interface Iprops {
  danhSachPhieu?: boolean;
}

export const TabCdhaTdcn = ({ danhSachPhieu = true }: Iprops) => {
  const { benhNhanInfo, setBenhNhanInfo, statusChangedEventFunc, setThongTinChiPhi } = useContext(PhanHeTiepDonContext);
  let { visit, isConclusion, patient, orderId } = benhNhanInfo?.thongTinKhamBenh || {};
  const [dsXetNghiemDetail, setDsXetNghiemDetail] = useState<any[]>([]);
  const [dsPhieu, setDsPhieu] = useState<any[]>([]);
  const [selectedPhieu, setSelectedPhieu] = useState<any>(null);
  const [rowSelected, setRowSelected] = useState<any>();
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [dynamicData, setDynamicData] = useState<any>(null);
  const [resultData, setResultData] = useState<any>(null);
  const [shouldOpenResultModal, setShouldOpenResultModal] = useState<boolean>(false);
  const [statusDesc, setStatusDesc] = useState<any>([]);
  const [keyOpenPhieuIn, setKeyOpenPhieuIn] = useState<string>("");
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] = useState<boolean>(false);
  const [dropListBoDV, setDropListBoDV] = useState<any[]>([]);

  const dropListChiDinhDichVu: IContextMenu[] = [
    {
      title: "Phiếu thực hiện",
    },
    {
      icon: <i className="bi bi-x-lg text-danger"></i>,
      code: CODE_MENU.XOA_DICH_VU,
      name: "Bỏ dịch vụ không làm",
    },
  ];

  const handleGetStatusDesc = async () => {
    try {
      let { data } = await getStatusDesc(WfConstTracker.RISPACS);
      if (data?.code === CODE.SUCCESS) {
        setStatusDesc(data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetStatusDesc();
  }, []);

  const actionChanged = useCallback(
    async (currStatusCode: string | undefined) => {
      try {
        let res;
        switch (currStatusCode) {
          case "order_group_cancel":
            res = await fetchHuyGuiPhieu(selectedPhieu?.id);
            break;
            case "order_group_submit":
            res = await fetchGuiPhieu(selectedPhieu?.id);
            break;
          default:
            res = null;
            break;
        }
        if (CODE.SUCCESS === res?.data?.code) {
          statusChangedEventFunc?.();
          handleSelectPhieu(selectedPhieu);
          setBenhNhanInfo({
            ...benhNhanInfo,
            shouldUpdate: !benhNhanInfo?.shouldUpdate,
          });
          toast.success("Thành công");
        } else {
          toast.warning(res?.data?.message || RESPONSE_MESSAGE.ERROR);
        }
      } catch (error) {
        toast.warning(RESPONSE_MESSAGE.ERROR);
        console.error(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [benhNhanInfo]
  );

  const handleGetThongTinKhamBenh = async () => {
    let { data: dataChiPhi } = await getPaymentDetail(benhNhanInfo?.thongTinKhamBenh?.visitId);
    if (CODE.SUCCESS === dataChiPhi?.code) {
      setThongTinChiPhi({
        ...dataChiPhi?.data,
        ttDiscount:
          dataChiPhi?.data?.ttDiscountInv +
          dataChiPhi?.data?.ttDiscountPercent,
      });
    }
  };

  const handleContextMenu = (e: any, row: any) => {
    e.preventDefault();
    setRowSelected(row);
    
    if (!row?.isCancel && row?.statusCode === STATUS_CANCEL.KTV_CANCEL) {
      setDropListBoDV(dropListChiDinhDichVu);
    } else {
      setDropListBoDV([]);
    }
    setContextMenu({ x: e.clientX, y: e.clientY });
  };

  const handleClickOptionContextMenu = (value: any) => {
    const menuActions = {
      [CODE_MENU.XOA_DICH_VU]: () => {
        if (rowSelected?.isCancel) {
          toast.warning("Dịch vụ đã được hủy không làm!");
          return;
        } else {
          setShouldOpenConfirmDialog(true)
        }
      },
    };
    menuActions[value?.code]?.();
    setContextMenu(null);
  };  
  
  const handleCancelService = async (value: any) => {
    try {
      const obj = {
        termType: TERM_TYPE.CDHA,
        termOrderId: rowSelected?.termOrderId,
        cancelReason: value?.canceledReason,
        menuAction: MENU_ACTION.KHAM_BENH
      }
      
      let { data } = await cancelService(obj);
      if(CODE.SUCCESS === data?.code && data?.data) {
        toast.success("Bỏ dịch vụ thành công!");
        handleSelectPhieu(selectedPhieu);
        handleGetThongTinKhamBenh();
        setShouldOpenConfirmDialog(false);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    !dsPhieu.length && setDsXetNghiemDetail([]);
  }, [benhNhanInfo, dsPhieu]);

  const handleSelectPhieu = async (dataPhieu: any) => {
    try {
      setSelectedPhieu(dataPhieu);
      let { data } = await getDSDVDaChiDinh({
        orderGroupId: dataPhieu?.id,
        type: ORDER_TYPE_ID.XET_NGHIEM
      });
      if (CODE.SUCCESS === data?.code) {
        const newData = data?.data?.map((item: any) => {
          return {
            ...item,
            terms: item?.terms?.map((term: any) => {
              if (term?.subs) {
                return {
                  ...term,
                  name: <CheckIcon data={term} color={item?.color} className="ml-2" />,
                  subs: term?.subs?.map((sub: any) => {
                    return {
                      ...sub,
                      name: <CheckIcon color={item?.color} data={sub} />
                    }
                  })
                }
              } else {
                return {
                  ...term,
                  name: <CheckIcon color={item?.color} data={term} />
                }
              }
            })
          }
        });
        setDsXetNghiemDetail(newData || []);
      } else {
        setDsPhieu([]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleConfirmDelete = async () => {
    try {
      await deleteDSChiDinhDV(selectedPhieu?.id);
      setBenhNhanInfo({
        ...benhNhanInfo,
        shouldUpdate: !benhNhanInfo?.shouldUpdate,
      });
      statusChangedEventFunc?.();
    } catch (error) {
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    }
  }

  const updataData = async () => {
    try {
      let { data } = await getDSNhomDVDaChiDinh({
        orderId,
        type: ORDER_TYPE_ID.CDHA
      });
      if (CODE.SUCCESS === data?.code) {
        let dsPhieuConverted = data?.data?.map((item: any) => {
          return {
            ...item,
            date: moment(item?.date).format("DD/MM/YYYY"),
          };
        });
        setDsPhieu(dsPhieuConverted || []);
        setSelectedPhieu(null);
      } else {
        setDsPhieu([]);
      }
    } catch (error) {
      console.error(error);
    }
  }
  
  const handleClickWfButton = (data: any) => {
    setDynamicData(data);
  };

  useEffect(() => {
    if (orderId) {
      updataData();
    }
  }, [benhNhanInfo?.shouldUpdate, orderId]);

  const handleViewResult = (data: any) => {
    let isResultReleased = data?.isResultReleased || (data?.terms ? data?.terms[0]?.isResultReleased : null)
    let resultData = data?.obs?.attributeValue || (data?.terms ? data?.terms[0]?.obs?.attributeValue : null)
    if(isResultReleased) {
      setShouldOpenResultModal(true);
      setResultData(resultData);
    } else {
      toast.warning("Chưa trả kết quả");
    }
  };

  useEffect(() => {
    let orderGroupId = selectedPhieu?.id;
    setBenhNhanInfo({
      ...benhNhanInfo,
      thongTinKhamBenh: {
        ...benhNhanInfo?.thongTinKhamBenh,
        orderGroupId
      }
    });
  }, [selectedPhieu]);

  const handlePressKeyShortcuts = (e: any) => {
    switch (e.key) {
      case KEY.F2:
        e.preventDefault();
        setKeyOpenPhieuIn("F2");
        break;
      case KEY.F3:
        e.preventDefault();
        setKeyOpenPhieuIn("F3");
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handlePressKeyShortcuts);
    return () => {
      window.removeEventListener("keydown", handlePressKeyShortcuts);
    };
  }, []);

  return (
    <Row className="h-100">
      <div>
        <div className="pt-4 bg-white">
          <Row className="mx-0">
            <Col xs={4} className="d-flex align-items-center mb-3">
              <LabelRequired label="Mã phiếu" className="min-w-100px" />
              <span className="fw-light text-truncate">{selectedPhieu?.code || "..."}</span>            </Col>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Ngày y lệnh" className="min-w-100px" />
              <span className="fw-light text-truncate">
                {formatDateTime(selectedPhieu?.indicationTime, APP_DATE_TIME_FORMAT)}
              </span>
            </Col>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Người chỉ định" className="min-w-100px" />
              <span className="fw-light">{selectedPhieu?.indicationPerson}</span>
            </Col>
            <Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Nơi chỉ định" className="min-w-100px" />
              <span className="fw-light text-truncate">{selectedPhieu?.requestDeptName || "..."}</span>
            </Col>
            <Col xs={8} className="d-flex align-items-center text-lable-input mb-3">
              <LabelRequired label="Chẩn đoán" className="min-w-100px" />
              <span className="fw-light text-truncate">
                {selectedPhieu?.provisionalDiagnosisName || "..."}
              </span>
            </Col>
          </Row>
        </div>
        <div className="bg-white spaces pt-2 mb-6">
          <DanhSachPhieu
            className="mb-2"
            handleSelectRowData={handleSelectPhieu}
            dsPhieu={dsPhieu as []}
            listColorPhieu={LIST_COLOR_TRANG_THAI_PHIEU}
            isGetFirstData
          />
          <TableCollapseCustom
            columns={columnsDSCdhaTdcn}
            data={dsXetNghiemDetail || []}
            childrenField="subs"
            className={`overflow-auto spaces h-calc-vh-480`}
            handleDoubleClick={handleViewResult}
            // handleContextMenu={handleContextMenu}
          />
        </div>
        <div className="d-flex justify-content-between p-2 gap-3 bg-white">
          <div className="d-flex">
            {(visit?.id && patient?.id && selectedPhieu?.id) && (
              <>
                <ButtonInPhieu
                  className="spaces mx-3"
                  label="Phiếu chỉ định chung"
                  fetchExport={inPhieuChiDinhChung}
                  params={{
                    visitId: visit?.id,
                    patientId: patient?.id,
                    orderGroupId: selectedPhieu?.id,
                  }}
                  keyOpenPhieuIn={keyOpenPhieuIn}
                  keyBtn={KEY.F2}
                  setKeyOpenPhieuIn={setKeyOpenPhieuIn}
                />
                <ButtonInPhieu
                  className="spaces mx-3"
                  label="Phiếu chỉ định theo phòng"
                  fetchExport={inPhieuChiDinhChung}
                  params={{
                    visitId: visit?.id,
                    patientId: patient?.id,
                    orderGroupId: selectedPhieu?.id,
                    isSplit: true
                  }}
                  keyOpenPhieuIn={keyOpenPhieuIn}
                  keyBtn={KEY.F2}
                  setKeyOpenPhieuIn={setKeyOpenPhieuIn}
                />
                <ButtonInPhieu
                  className="spaces mx-3"
                  label="Phiếu chỉ định (F2)"
                  fetchExport={inPhieuChiDinhCLS}
                  params={{
                    visitId: visit?.id,
                    patientId: patient?.id,
                    orderGroupId: selectedPhieu?.id,
                    orderTypeId: ORDER_TYPE_ID.CDHA,
                  }}
                  keyOpenPhieuIn={keyOpenPhieuIn}
                  setKeyOpenPhieuIn={setKeyOpenPhieuIn}
                  keyBtn={KEY.F2}
                />
                <ButtonInPhieu
                  className="spaces mx-3"
                  label="Phiếu kết quả (F3)"
                  fetchExport={inPhieuKetQuaCLS}
                  params={{
                    orderGroupId: selectedPhieu?.id,
                    type: ORDER_TYPE_ID.CDHA,
                  }}
                  keyOpenPhieuIn={keyOpenPhieuIn}
                  setKeyOpenPhieuIn={setKeyOpenPhieuIn}
                  keyBtn={KEY.F3}
                />
              </>
            )}
            {(selectedPhieu?.isAllowModify && !isConclusion) && (
              <WfButton tracker={WfConstTrackerClinical.ORDER_GROUP} objectId={selectedPhieu?.id} additionalFunc={actionChanged} handleClick={handleClickWfButton}></WfButton>
            )}
          </div>

          <div className="flex-grow-1">
            <Stack
              direction="horizontal"
              gap={3}
              className="justify-content-end h-100"
            >
              <ShowStatus
                className="d-flex spaces gap-8"
                statuses={statusDesc}
              />
            </Stack>
          </div>
        </div>
      </div>
      {dynamicData && <DynamicComponentKB data={dynamicData} setData={setDynamicData} onConfirm={handleConfirmDelete} />}
      {shouldOpenResultModal && <ModalThucHienCDHA
        handleSubmitForm={() => { }}
        handleClose={() => setShouldOpenResultModal(false)}
        handleTraKetQua={() => { }}
        infoBenhNhan={benhNhanInfo?.thongTinKhamBenh}
        resultData={resultData}
        isViewModal
      />}      
      {(contextMenu && dropListBoDV.length > 0) && (
        <ContextMenu
          handleClickOptionContextMenu={handleClickOptionContextMenu}
          handleCloseMenu={() => setContextMenu(null)}
          data={dropListBoDV}
          target={contextMenu}
        />
      )}

      {shouldOpenConfirmDialog && (
        <ConfirmReasonDialog
          show={shouldOpenConfirmDialog}
          close="Hủy"
          yes="Lưu"
          onYesClick={handleCancelService}
          onCloseClick={() => setShouldOpenConfirmDialog(false)}
          title="Không thực hiện dịch vụ"
          name="canceledReason"
          label="Lý do không thực hiện dịch vụ"
        />
      )}
    </Row>
  );
};

export default TabCdhaTdcn;
