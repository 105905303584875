import { toast } from 'react-toastify';
import { RESPONSE_MESSAGE } from '../../../../utils/Constant';
import { IKeVatTu, IVatTuItem, SearchVatTuParams } from '../../../models/VatTuModels';
import { searchVatTu } from '../../../services/VatTuService';

export const getListVatTu = async (
	query: SearchVatTuParams,
	setIsLoading?: (flag: boolean) => void
) => {
	try {
		setIsLoading?.(true);

		const response: IVatTuItem[] = await searchVatTu({
			...query,
			pageIndex: 1,
			pageSize: 100,
			isIventoryNoZero: true,
		});
		const result: IKeVatTu[] = response.map((vatTu: IVatTuItem) => ({
			id: vatTu.id.toString(),
			donVi: vatTu.unitofMeasureName,
			maVatTu: vatTu.code,
			tenVatTu: vatTu.name,
			giaBhyt: Number(vatTu.bhytPrice),
			giaDichVu: Number(vatTu.servicePrice),
			giaVienPhi: Number(vatTu.hospitalPrice),
			tonKho: Number(vatTu.iventory) - Number(vatTu.peShipment),
			bidId: vatTu.bidId,
			serialNumber: vatTu.serialNumber,
			consignment: vatTu.consignment,
			giaBan: vatTu.salePrice
		}));

		return result;
	} catch (error) {
		console.error(error);
		toast.error(RESPONSE_MESSAGE.ERROR);
	} finally {
		setIsLoading?.(false);
	}
};
