import React, { useState } from 'react'
import FilterSidebar from '../../../component/filter-sidebar/FilterSidebar';
import LazyLoadTable from '../../../component/lazy-load-table/LazyLoadTable';
import CustomIconButton from '../../../component/custom-icon-button/CustomIconButton';
import { OctAutocomplete, OctKTSVG } from '@oceantech/oceantech-ui';
import WfStatusSummary from '../../../workflows/components/WfStatusSummary';
import { getDSKhamBenh } from '../../../phan-he-kham-benh/services/PhanHeTiepDonServer';
import ChiDinhPhong from '../phong-benh/ChiDinhPhong';

type Props = {}

const DsBenhNhan = (props: Props) => {
    const [shouldOpenChiDinhPhongDialog, setShouldOpenChiDinhPhongDialog] = useState<boolean>(false);

    const doSearch = (data: any) => {
    };

    const columns = [
        {
            name: "TT",
            field: "",
            headerStyle: {
                width: "50px",
            },
        },
        {
            name: "STT",
            field: "",
            headerStyle: {
                width: "50px",
            },
            cellStyle: {
                textAlign: "center",
            },
            render: (rowData: any, index: number) => index + 1,
        },
        {
            name: "Tên bệnh nhân",
            field: "name",
            headerStyle: {
                minWidth: "175px",
            },
        },
        {
            name: "Tuổi",
            field: "age",
            headerStyle: {
                width: "50px",
            },
        },
        {
            name: "Giường",
            field: "",
            headerStyle: {
                width: "70px",
            },
            render: (rowData: any, index: number) => {
                return <div className="d-flex justify-content-center">
                    <CustomIconButton
                        variant="delete"
                        title="Chỉ định giường"
                        onClick={() => setShouldOpenChiDinhPhongDialog(true)}
                    >
                        <OctKTSVG path='/media/svg/cis/giuong.svg' className='svg-icon-1'/>
                    </CustomIconButton>
                </div>
            },
        },
        {
            name: "BHYT",
            field: "",
            headerStyle: {
                width: "50px",
            },
        },
        {
            name: "Tạm ứng",
            field: "",
            headerStyle: {
                width: "80px",
            },
        },
    ];

    return (
        <div className="bangDSBenhNhan p-0 position-relative">
            <div className="flex-1 flex flex-column">
                <FilterSidebar
                    title="Danh sách bệnh nhân"
                    //   formalList={LIST_FORMAL}
                    onSearch={doSearch}
                    listMenuItem={[]}
                    handleSelectOption={() => { }}
                    // handleGoiBenhNhan={() => handleGoiBN()}
                    isShowMenuItem={false}
                />
                <div className="d-flex flex-column justify-content-between h-100 pt-3">
                    <div className="spaces h-calc-vh-380">
                        <LazyLoadTable
                            columns={columns}
                            axiosReq={getDSKhamBenh}
                            searchData={{}}
                            urlData="data.data.medicalExamBrief"
                            className="spaces h-calc-vh-300"
                            rowKey="orderId"
                        />
                    </div>
                    <div className="spaces px-12">
                        <OctAutocomplete
                            options={[]}
                            placeholder="Tất cả bệnh nhân"
                            menuPlacement="top"
                        />
                    </div>
                    <div className="spaces px-12 mb-10">
                        <OctAutocomplete
                            options={[]}
                            placeholder="Tất cả khoa phòng"
                            menuPlacement="top"
                        />
                    </div>
                    <WfStatusSummary
                        summaryStatus={[
                            { statusName: "Chờ nhập khoa", summary: 0, color: "#6C757D", statusCode: "1" },
                            { statusName: "Đang điều trị", summary: 0, color: "#FF9C07", statusCode: "2" },
                            { statusName: "Hết đợt điều trị", summary: 0, color: "#3C78D8", statusCode: "3" },
                            { statusName: "Tạm dừng điều trị", summary: 0, color: "#DC3545", statusCode: "4" },
                            { statusName: "Đã kết thúc", summary: 0, color: "#17A2B8", statusCode: "5" },
                            { statusName: "Đã ra viện", summary: 0, color: "#28A745", statusCode: "6" },
                        ]}
                        col={3}
                    />
                </div>
            </div>
            {shouldOpenChiDinhPhongDialog && (
                <ChiDinhPhong handleClose={() => setShouldOpenChiDinhPhongDialog(false)}/>
            )}
        </div>
    )
}

export default DsBenhNhan