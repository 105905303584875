import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import { cloneDeep } from "lodash";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { RowMouseEventHandlerParams } from "react-virtualized";
import { useDebounce } from "../../../../../_metronic/helpers";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import { ConfirmDialog } from "../../../component/ConfirmDialog";
import ContextMenu from "../../../component/ContextMenuV3";
import { IconBack, IconButtonSave, IconMenu } from "../../../component/IconSvg";
import InputSearch from "../../../component/InputSearch";
import LabelRequired from "../../../component/LabelRequired";
import SelectTree from "../../../component/SelectTree";
import TextField from "../../../component/TextField";
import DatePickerCustom from "../../../component/date-picker/DatePickerCustom";
import SimpleTable from "../../../component/table/simple-table/SimpleTable";
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import { CODE_DOI_TUONG } from "../../../phan-he-tiep-nhan-thanh-toan/constants/PhanHeTiepNhan";
import { IContextMenu } from "../../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy";
import { IDanhSachDanhMuc } from "../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import PaymentInfor from "../../../phan-he-vien-phi/components/PaymentInfor";
import { getFormFieldConfig } from "../../../phan-he-vien-phi/services/PhanHeVienPhiServices";
import { handleSearchByFuse } from "../../../utils/AppFunction";
import {
  CODE,
  KEY_DS_DANH_MUC_TIEP_DON,
  ORDER_TYPE_ID,
  SELECTION_MODE,
  TERM_CLASS_IDS
} from "../../../utils/Constant";
import { formatMoney } from "../../../utils/FormatUtils";
import { getIndexedDBItem } from "../../../utils/IndexedDB";
import { localStorageItem } from "../../../utils/LocalStorage";
import { getTermClasses } from "../../../utils/ServicesUtils";
import ICD10 from "../../components/ICD10";
import InfoPatientRight from "../../components/InfoPatientRight";
import ProvisonalDiagnosis from "../../components/ProvisonalDiagnosis";
import ModalDsPhieuChiDinhCu from "../../components/modal-chi-dinh-dich-vu/modal-ds-mau-da-tao/ModalDsPhieuChiDinhCu";
import ModalThemPhieuDieuTri from "../../components/modal-chi-dinh-dich-vu/modal-them-phieu-dieu-tri/ModalThemPhieuDieuTri";
import ModalEditOneColumn from "../../components/modal-ke-thuoc/ModalEditOneColumn";
import { TreeChiDinhDichVu, classIdTabKhamBenh } from "../../constants/KhamBenh";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import { IDonThuocMauRequest } from "../../models/ThuocModels";
import { getThongTinPhieuChiDinh } from "../../services/ChiDinhDVService";
import { getThongTinChanDoan } from "../../services/KhamBenhService";
import MauChiDinhButton from "../modal-ke-thuoc/components/DonThuocMauButton";
import {
  DsDichVuChiDinhColumn,
  dsDichVuColumnsSimple,
} from "./ChiDinhDichVuColumn";

interface Props {
  handleClose: Dispatch<SetStateAction<boolean>>;
  dieuTri?: boolean;
  isSuaPhieu?: boolean;
}

const FormChiDinhDichVu: FC<Props> = (props) => {
  let { handleClose, dieuTri, isSuaPhieu } = props;
  let { values, setFieldValue, setValues, errors, touched } = useFormikContext<any>();
  let currentUser = localStorageItem.get(KEY_LOCALSTORAGE.CURRENT_USER);
  const { benhNhanInfo, activeTab } = useContext(PhanHeTiepDonContext);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [selectedCol, setSelectedCol] = useState<any>({});
  const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [shouldOpenDsPhieuChiDinhModal, setShouldOpenDsPhieuChiDinhModal] =
    useState<boolean>(false);
  const [shouldOpenPhieuDieuTriModal, setShouldOpenPhieuDieuTriModal] =
    useState<boolean>(false);
  const [shouldOpenGhiChuPopup, setShouldOpenGhiChuPopup] =
    useState<boolean>(false);
  const [shouldOpenSoLuongPopup, setShouldOpenSoLuongPopup] =
    useState<boolean>(false);
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] =
    useState<boolean>(false);
  const [openModalThemPhieuDieuTri, setOpenModalThemPhieuDieuTri] =
    useState<boolean>(false);
  const [shouldOpenModalICD10, setShouldOpenModalICD10] =
    useState<boolean>(false);
  const [treeData, setTreeData] = useState<any>(TreeChiDinhDichVu);
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [listDichVu, setListDichVu] = useState<any[]>([]);
  const [listDichVuSearch, setListDichVuSearch] = useState<any[]>([]);
  const [selectionModeICD10, setSelectionModeICD10] = useState<
    "checkbox" | "radio"
    >("radio");
  const [listICD10, setListICD10] = useState<any>([]);
  const [expenseObject, setExpenseObject] = useState({
    tong: '0',
    benhNhan: '0',
    conNo: '0',
  }) 
  const [textSearch, setTextSearch] = useState<string>("");
  const [listDoiTuong, setListDoiTuong] = useState<any[]>([]);
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [listResponseService, setListResponseService] = useState<any>([]);
  const [listComorbidity, setListComorbidity] = useState<any[]>([]);
  const [shouldOpenModalSelectICD10, setShouldOpenModalSelectICD10] = useState<boolean>(false);

  const { BAO_HIEM, VIEN_PHI, YEU_CAU} = CODE_DOI_TUONG;
  const { visit, patient } = benhNhanInfo?.thongTinKhamBenh || {};
  const typeObjectCode = visit?.visitObjectTypeCode;
  const thongTinChiPhi = {
    tongChiPhi: visit.ttTong,
    bhyt: visit.ttBhyt,
    benhNhan: visit.ttNguoiBenh,
    tamUng: visit.ttTamUng,
    daThu: visit.ttDaThu,
    mienGiam: visit.ttDiscountInv,
    conDu: visit.ttConNo,
  };
  const textSearchDebounce = useDebounce(textSearch.trim(), 300);
  const [paymentItem, setPaymentItem] = useState<any>([]);

  useEffect(() => {
    handleSearchByFuse(listDichVuSearch, textSearchDebounce ?? '', setListDichVu
    //   {
    //   includeScore: true,
    //   shouldSort: true,
    //   minMatchCharLength: 1,
    //   ignoreFieldNorm: false,
    //   fieldNormWeight: 1,
    // }
  );
  }, [textSearchDebounce]);

  useEffect(() => {
    updatePageData();
  }, []);

  const CODE_MENU = {
    SUA_SO_LUONG: {
      MOT: "1",
      HAI: "2",
      BA: "3",
      BON: "4",
      NHAP_SO_LUONG: "nhapSoLuong",
    },
    SUA_GIA: "suaGia",
    GHI_CHU: "ghiChu",
    XOA_DICH_VU: "xoaDichVu",
  };

  const updateColumnDSDichVuChiDinh = (field: string, newValue: any) => {
    let newArr: any[] = cloneDeep(values?.orders);
    newArr[selectedRowIndex as number][field] = newValue;
    setFieldValue("orders", newArr);
  };

  const handleClickOptionContextMenu = (value: any) => {
    const menuActions = {
      [CODE_MENU.SUA_SO_LUONG.NHAP_SO_LUONG]: () =>
        setShouldOpenSoLuongPopup(true),
      [CODE_MENU.SUA_SO_LUONG.MOT]: () =>
        updateColumnDSDichVuChiDinh("soLuong", CODE_MENU.SUA_SO_LUONG.MOT),
      [CODE_MENU.SUA_SO_LUONG.HAI]: () =>
        updateColumnDSDichVuChiDinh("soLuong", CODE_MENU.SUA_SO_LUONG.HAI),
      [CODE_MENU.SUA_SO_LUONG.BA]: () =>
        updateColumnDSDichVuChiDinh("soLuong", CODE_MENU.SUA_SO_LUONG.BA),
      [CODE_MENU.SUA_SO_LUONG.BON]: () =>
        updateColumnDSDichVuChiDinh("soLuong", CODE_MENU.SUA_SO_LUONG.BON),
      [CODE_MENU.SUA_GIA]: () => {},
      [CODE_MENU.GHI_CHU]: () => setShouldOpenGhiChuPopup(true),
      [CODE_MENU.XOA_DICH_VU]: () => setShouldOpenConfirmDialog(true),
    };
    menuActions[value?.code]?.();
    setContextMenu(null);
  };

  const dropListChiDinhDichVu: IContextMenu[] = [
    {
      title: "Dịch vụ",
    },
    {
      icon: <i className="bi bi-pencil-square text-pri"></i>,
      // code: CODE_MENU.SUA_SO_LUONG.,
      name: "Sửa số lượng",
      children: [
        { code: CODE_MENU.SUA_SO_LUONG.MOT, name: "1" },
        { code: CODE_MENU.SUA_SO_LUONG.HAI, name: "2" },
        { code: CODE_MENU.SUA_SO_LUONG.BA, name: "3" },
        { code: CODE_MENU.SUA_SO_LUONG.BON, name: "4" },
        { code: CODE_MENU.SUA_SO_LUONG.NHAP_SO_LUONG, name: "Nhập số lượng" },
      ],
    },
    {
      icon: <i className="bi bi-currency-exchange text-pri"></i>,
      code: CODE_MENU.SUA_GIA,
      name: "Sửa giá",
    },
    {
      icon: <i className="fa-solid fa-message text-pri"></i>,
      code: CODE_MENU.GHI_CHU,
      name: "Ghi chú",
    },
    {
      icon: <i className="bi bi-x-lg text-danger"></i>,
      code: CODE_MENU.XOA_DICH_VU,
      name: "Xóa dịch vụ",
    },
  ];

  const handleDelete = () => {
    setShouldOpenConfirmDialog(true);
  };

  const handleConfirmDelete = () => {
    let newArr = [...(values?.orders || [])];
    if (selectedRowIndex || selectedRowIndex === 0) {
      newArr.splice(selectedRowIndex, 1);
    }
    setFieldValue("orders", newArr);
    setSelectedRow(newArr);
    const objFee = handleCaculateFee(newArr);
    setExpenseObject({
      tong: objFee?.tong,
      benhNhan: objFee?.benhNhan,
      conNo: objFee?.conNo
    });
    setShouldOpenConfirmDialog(false);
  };

  const handleSaveGhiChu = (values: { ghiChu: string }) => {
    updateColumnDSDichVuChiDinh("ghiChu", values.ghiChu);
  };

  const handleOpenThemPhieuDieuTri = () => {
    setOpenModalThemPhieuDieuTri(true);
  };

  const handleConvertTreeData = (data: any[]) => {
    let convertedData: any[] = data.map((item, index) => {
      return {
        ...item,
        code: item?.code || item?.name,
        name: item?.name,
        ...(item?.terms &&
          item?.terms?.length && {
            filter: handleConvertTreeData(item?.terms || []),
          }),
      };
    });
    return convertedData;
  };

  const handleChangeEncounterDiagnosis = (name: string, value: any) => {
    setFieldValue("encounterDiagnosis", {
      ...values.encounterDiagnosis,
      [name]: value,
    });
  };

  const filterListDoiTuong = (listDoiTuong: any[]) => {

    switch (typeObjectCode) {
      case BAO_HIEM: {
        return listDoiTuong;
      }
      case VIEN_PHI: {
        return listDoiTuong?.filter((item) => item?.code !== BAO_HIEM);
      }
      case YEU_CAU: {
        return listDoiTuong?.filter((item) => item?.code !== BAO_HIEM && item?.code !== VIEN_PHI);
      }
      default:
        return listDoiTuong;
    }
  };

  const handleFilterListDichVu = (classId: any, listDichVuFull: any) => {
    let listDichVuFiltered = listDichVuFull.filter((itemDichVu: any) => itemDichVu?.classId === classId)
    return listDichVuFiltered
  }
  const updatePageData = async () => {
    try {
      let { orderId, orderGroupId } = benhNhanInfo?.thongTinKhamBenh || {};
      let termClassId = 0;
      if (isSuaPhieu) {
        let responsePhieu = await getThongTinPhieuChiDinh({
          orderId, orderGroupId
        });
        termClassId = responsePhieu?.data?.data?.termClassId || 0;
        let orders = responsePhieu?.data?.data?.orderTermResDtos?.map((item: any) => ({
          ...(item?.term || {}),
          visitObjectType: item?.objectTypeId || item?.objectTypeCode
            ? {
              id: item?.objectTypeId,
              code: item?.objectTypeCode,
              name: item?.objectTypeName,
            }
            : {
              id: visit?.objectTypeId,
              code: visit?.objectTypeCode,
              name: visit?.objectTypeName,
            },
        })) || [];
        setValues({
          ...values,
          encounterDiagnosis: {
            ...values.encounterDiagnosis,
            ...responsePhieu?.data?.data?.encounterDiagnosis
          },
          orders,
          indicationTime: responsePhieu?.data?.data?.indicationTime
        });
        handleSelectService(orders);
        setSelectedRow(orders);
      } else {
        handleGetThongTinChanDoan();
      };
      let responseDanhMuc: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
      const listDichVuChiDinh = await getListDichVu();

      let listLoaiDichVu = [];
      switch (termClassId) {
        case TERM_CLASS_IDS.XET_NGHIEM:
          listLoaiDichVu.push(listDichVuChiDinh?.[0]);
          break;
        case TERM_CLASS_IDS.CDHA:
          listLoaiDichVu.push(listDichVuChiDinh?.[1]);
          break;
        case TERM_CLASS_IDS.PTTT:
          listLoaiDichVu.push(listDichVuChiDinh?.[2]);
          break;
        default:
          listLoaiDichVu = listDichVuChiDinh || [];
          break;
      };
      let treeDataConverted = {
        code: "all",
        name: "Tất cả dịch vụ",
        icon: <IconMenu />,
        filter: handleConvertTreeData(listLoaiDichVu),
      };
      setTreeData(treeDataConverted);
      setListICD10(responseDanhMuc?.listICD10 || []);
      setListDoiTuong(filterListDoiTuong(responseDanhMuc?.listDoiTuong) || []);
      setListResponseService(listDichVuChiDinh);   

      let listDichVuFull = listDichVuChiDinh.flatMap((items: any) => items?.terms.flatMap((item: any) => item?.subs))
      if (isSuaPhieu) {
        if (+activeTab === classIdTabKhamBenh.XET_NGHIEM.indexTab) {
          setListDichVu(handleFilterListDichVu(classIdTabKhamBenh.XET_NGHIEM.classId, listDichVuFull));
        }
        else if(+activeTab === classIdTabKhamBenh.CDHA.indexTab) {
          setListDichVu(handleFilterListDichVu(classIdTabKhamBenh.CDHA.classId, listDichVuFull));
        }
        else if(+activeTab === classIdTabKhamBenh.CHUYEN_KHOA.indexTab) {
          setListDichVu(handleFilterListDichVu(classIdTabKhamBenh.CHUYEN_KHOA.classId, listDichVuFull));
        }
      } 
      else {
        //Lúc mới vào hiển thị full dịch vụ
        setListDichVu(listDichVuFull);
      }
      setListDichVuSearch(
        listDichVuChiDinh.flatMap((items: any) => items?.terms.flatMap((item: any) => item?.subs))
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleGetThongTinChanDoan = async () => {
    try {
      const { data } = await getThongTinChanDoan(visit?.id);
      if (data?.code === CODE.SUCCESS) {
        setValues({
          ...values,
          encounterDiagnosis: {
            ...values.encounterDiagnosis,
            provisionalDiagnosis: data?.data?.provisionalDiagnosis || "",
            provisionalDiagnosisName: data?.data?.provisionalDiagnosisName || "",
            comorbidity: data?.data?.comorbidity || "",
            diagnosisNonCoded:  data?.data?.diagnosisNonCoded || "",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetSelectedItem = (data: any) => {
    if (data) {
      setListDichVu(data?.subs || []);
      setListDichVuSearch(data?.subs || []);
      setTextSearch("");
    } else {
      //Khi click lần 2 vào loại dịch vụ đó thì set ds dịch vụ về full dịch vụ
      setListDichVu(
        listResponseService.flatMap((items: any) =>
          items?.terms.flatMap((item: any) => item?.subs)
        )
      );
      setListDichVuSearch(listResponseService.flatMap((items: any) =>
        items?.terms.flatMap((item: any) => item?.subs)
      ));
    }
  };

  useEffect(() => {
    const objFee = handleCaculateFee(values?.orders);
    setExpenseObject({
      tong: objFee?.tong,
      benhNhan: objFee?.benhNhan,
      conNo: objFee?.conNo
    });
  }, [values?.orders]);

  useEffect(() => {
    if (benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis) {
      setValues({
        ...values,
        encounterDiagnosis: {
          ...benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis,
        },
      });
    }
  }, [benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis]);

  const handleRenderCell = (columnData: any, dataKey: string, rowData: any) => {
    rowData = {
      ...rowData,
      svcFee: formatMoney(rowData?.attributes?.svcFee) || 0,
      svcFeeHospital: formatMoney(rowData?.attributes?.svcFeeHospital) || 0,
      svcFeeIns: formatMoney(rowData?.attributes?.svcFeeIns) || 0,
      svcQuantity: rowData?.attributes?.svcQuantity || 1,
      svcPrice: formatMoney(handleRenderPrice(rowData, typeObjectCode)) || 0,
      deptLaboratoryName: rowData?.attributes?.deptLaboratoryName,
    };
    if (typeof rowData.get === "function") {
      return rowData.get(dataKey);
    } else {
      return rowData[dataKey];
    }
  };

  const handleRenderPrice= (rowData: any, typeObjectCode: string) => {
    switch (typeObjectCode) {
      case YEU_CAU: {
        return rowData?.attributes?.svcFee || 0;
      }
      case VIEN_PHI: {
        return rowData?.attributes?.svcFeeHospital || 0;
      }
      case BAO_HIEM: {
        return rowData?.attributes?.svcFeeIns || 0;
      }
      default: {
        return 0;
      }
    }
  };

  const handleCaculateFee = (data: any) => {
    let objResult = {
      tong: visit.ttTong || 0,
      benhNhan: visit.ttNguoiBenh || 0,
      conNo: visit.ttConNo || 0
    };
    let tongChiPhi = 0;

    data?.forEach((item: any) => {
      let visitObjectTypeCode = item?.visitObjectType?.code;
      let price = handleRenderPrice(item, visitObjectTypeCode);
      tongChiPhi = tongChiPhi + Number(price);

      objResult = {
        tong: objResult.tong + Number(price),
        benhNhan: objResult.benhNhan + Number(price),
        conNo: thongTinChiPhi.tamUng + thongTinChiPhi.daThu - (thongTinChiPhi.benhNhan + tongChiPhi),
      };
    });
    return objResult;
  };

  const handleSelectService = (data: any) => {
    let result = data?.map((item: any) => ({
      ...item,
      visitObjectType: item?.visitObjectType
        ? item?.visitObjectType
        : {
          id: visit?.visitObjectTypeId,
          code: visit?.visitObjectTypeCode,
          name: visit?.visitObjectTypeName,
        },
    })) || [];

    setFieldValue("orders", result);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    const value = (event.target as HTMLInputElement).value;
    const newValue = value ? `'${value}` : '';
    setTextSearch(newValue);
  };

  const handleCheckBeforeSave = () => {
    // if (!values?.encounterDiagnosis?.provisionalDiagnosisName) {
    //   toast.warning("Vui lòng nhập thông tin chẩn đoán!");
    // }
    // return;
  };

  const handleOnRowClickCheckbox = (rowData: any) => {
    if(!rowData) return;
    
    let _listData = [...listDichVu];
    _listData[rowData.index].isChecked = true;
    let updatedSelectedRows = [...selectedRow];
    const selectedItem = _listData[rowData.index];

    if (!rowData?.rowData?.isChecked) {
      updatedSelectedRows.push(selectedItem);
    } else {
      updatedSelectedRows = updatedSelectedRows.filter(
        (item: any) => item?.["code"] !== selectedItem?.["code"]
      );
    }

    let result = updatedSelectedRows?.map((item: any) => ({
      ...item,
      visitObjectType: item?.visitObjectType
        ? item?.visitObjectType
        : {
          id: visit?.visitObjectTypeId,
          code: visit?.visitObjectTypeCode,
          name: visit?.visitObjectTypeName,
        },
    })) || [];
    
    setFieldValue("orders", result);
    setListDichVu([..._listData]);
    setSelectedRow(updatedSelectedRows);
  }

  const handleOpenSelectICD10 = () => {
    setShouldOpenModalSelectICD10(true);
  };
  
  const getMauChiDinhParams = () => {
		const ORDER_TYPE = parseInt(ORDER_TYPE_ID.XET_NGHIEM);
		const tableData = (values?.orders || []).map((row: any) => {
      const { subs, ...rowData } = row ?? {};

			return {
				orderType: ORDER_TYPE,
				orderTemplate: JSON.stringify(rowData),
			};
		});

		return {
			orderType: ORDER_TYPE,
			orderTemplate: JSON.stringify({
				provisionalDiagnosis: values.encounterDiagnosis.provisionalDiagnosis,
				provisionalDiagnosisName: values.encounterDiagnosis.provisionalDiagnosisName,
        comorbidity: values.encounterDiagnosis.comorbidity,
        diagnosisNonCoded: values.encounterDiagnosis.diagnosisNonCoded,
			}),
			lstOrderSetMemebers: tableData,
		};
  };

	const onSelectMauChiDinh = (data: IDonThuocMauRequest) => {
		const formValue = data.orderTemplate ? JSON.parse(data.orderTemplate) : {};
		const tableTemplateValue = data.lstOrderSetMemebers.map((row: any) => ({
			...JSON.parse(row.orderTemplate),
		}));
    const terms = treeData.filter.map((node: any) => [...node.terms]).flat();

    const tableData = tableTemplateValue.map((row) => {
      const parentTerm = terms.find((term: any) => term.id === row.parentId);
      const visitObjectType = {
        id: visit?.visitObjectTypeId,
        code: visit?.visitObjectTypeCode,
        name: visit?.visitObjectTypeName,
      };

      if (parentTerm) {
          const termRow = parentTerm.subs.find((sub: any) => sub.id === row.id);
          return {
            ...termRow,
            visitObjectType
          }
      }

      return null;
    });

		setValues({
			...values,
			orders: tableData,
		});
    setSelectedRow(tableData);
	};

  const saveDonMauValidation = () => {
		if (!(values.orders || []).length) {
			toast.warn('Chưa có dịch vụ được chỉ định cho mẫu này');
			return false;
		}

    return true;
  };

  const selectDonMauValidation = () => {
		if ((values.orders || []).length) {
			toast.warn('Đã có dịch vụ được chỉ định. Không thể chọn mẫu chỉ định');
			return false;
		}
		return true;
  };

  const handleChangeICD10 = (data: any) => {
    setFieldValue('encounterDiagnosis', {
      ...values?.encounterDiagnosis,
      ...data,
    });
  };

  const getListDichVu = async(termClassId?: string) => { 
    try {
        const CLASS_IDS_DICH_VU = [
          TERM_CLASS_IDS.XET_NGHIEM,
          TERM_CLASS_IDS.CDHA,
          TERM_CLASS_IDS.PTTT
        ].join(',');

        const { data } = await getTermClasses({ classIds: termClassId ?? CLASS_IDS_DICH_VU });

        if (CODE.SUCCESS === data?.code) {
           return data.data;
        }

        return [];
    } catch (error) {
      console.error(error);
      toast.error('Xảy ra lỗi khi lấy danh sách dịch vụ, vui lòng thử lại!');
    } 
  };

  const handleSetPaymentInfor = async () => {
    const FORM_FIELD_CONFIG = [
      {
        code: "TONG_CHI_PHI",
        name: "Tổng chi phí",
        value: expenseObject?.tong || 0,
        type: "price",
        color: "text-danger"
      },
      {
        code: "BHYT",
        name: "BHYT",
        value: thongTinChiPhi?.bhyt || 0,
        type: "price",
      },
      {
        code: "NGUON_KHAC",
        name: "Nguồn khác",
        value: 0,
        type: "price",
      },
      {
        code: "BENH_NHAN",
        name: "Bệnh nhân",
        value: expenseObject?.benhNhan || 0,
        type: "price",
      },
      {
        code: "TAM_UNG",
        name: "Tạm ứng",
        value: thongTinChiPhi?.tamUng || 0,
        type: "price",
      },
      {
        code: "DA_THU",
        name: "Đã thu",
        value: thongTinChiPhi?.daThu || 0,
        type: "price",
      },
      {
        code: "MIEN_GIAM",
        name: "Miễn giảm",
        value: thongTinChiPhi?.mienGiam || 0,
        type: "price",
      },
      {
        code: "CON_NO",
        name: Number(expenseObject?.conNo) < 0 ? "Còn nợ" : "Còn dư",
        value: Math.abs(Number(expenseObject?.conNo)) || 0,
        type: "price",
      },
    ]
    try {
      let { data: { data } } = await getFormFieldConfig("FORM_INFO_PAYMENT_ASSIGN");
      const jsonObject = JSON.parse(data[0]?.valueText);
      
      let newArr: any[] = [];
      jsonObject.forEach((item: any) => {
        let obj = FORM_FIELD_CONFIG.find((obj: any) => obj?.code === item);
        
        newArr.push(obj);
      })
      
      setPaymentItem(newArr);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleSetPaymentInfor();
  }, [expenseObject]);

  return (
    <div className="h-100">
      <Modal.Header className="py-3 header-modal">
        <div className="d-flex gap-6">
          <div
            className="d-flex align-items-center gap-3 cursor-pointer"
            onClick={() => handleClose(false)}
          >
            <IconBack />
            <span className="fw-500">Quay lại</span>
          </div>
          <MauChiDinhButton
							label="Mẫu chỉ định"
							getSaveParams={getMauChiDinhParams}
							onSelectMau={(data: IDonThuocMauRequest) => {
								onSelectMauChiDinh(data);
							}}
							orderType={parseInt(ORDER_TYPE_ID.XET_NGHIEM)}
              onSaveValidationFn={saveDonMauValidation}
              onSelectValidationFn={selectDonMauValidation}
						/>
        </div>
        {benhNhanInfo?.thongTinKhamBenh ? (
          <InfoPatientRight benhNhanInfo={benhNhanInfo} />
        ) : (
          <div className="spaces h-65"></div>
        )}
      </Modal.Header>
      <Modal.Body className="py-6 px-0 spaces overflow-hidden spaces h-calc-vh-90">
        <div className="d-flex h-100">
          <div className="spaces width-20 bg-white radius-2 d-flex flex-column">
            <div className="spaces px-12 py-5 h-42">
              <InputSearch
                placeholder="Tìm kiếm"
                handleChange={() => {}}
                className="spaces h-32 pr-4 radius-3"
              />
            </div>
            <SelectTree
              className="w-100 py-2 ps-4 flex-grow-1 border border-top-0 overflow-auto"
              codeCollapses={codeCollapses}
              handleChangeCollapsesCode={setCodeCollapses}
              idSelected={idSelected}
              handleChangeSelectId={setIdSelected}
              getSelectedItem={handleGetSelectedItem}
              selectTree={treeData}
            />
          </div>
          <div className="d-flex flex-column spaces width-80 bg-light radius-2">
            <div className="bg-white radius-2 spaces py-10 px-10 d-flex justify-content-between">
              <div className="text-pri fw-bold">Chỉ định dịch vụ</div>
              {dieuTri && (
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenThemPhieuDieuTri();
                  }}
                  className="text-black"
                >
                  <u>Thêm phiếu điều trị</u>
                </a>
              )}
            </div>
            <div className="spaces radius-2 p-10 border-top bg-white">
							<div className="d-flex flex-wrap">
                <div className="spaces W-235 mb-4">
                  <DatePickerCustom 
                    label="Ngày y lệnh"
                    name="indicationTime"
                    labelClassName="ms-0 min-w-100px"
                    value={values?.indicationTime ? values?.indicationTime : new Date()}
                    setDateValue={(date) => {
                        setFieldValue("indicationTime", date);
                    }}
                    mode='date-time'
                    disabled={isSuaPhieu}
                    errors={errors?.indicationTime}
                    touched={touched?.indicationTime}
                  />
                </div>
                <div className="spaces d-flex position-relative align-items-start">
                  <Button
                    className="bg-white spaces W-90 py-0 pl-10 mb-3"
                    onClick={handleOpenSelectICD10}
                  >
                    <u className="fw-bold text-pri fs-13px">Chọn bệnh</u>
                  </Button>
                  <LabelRequired label="Chẩn đoán" isRequired className="min-w-90px" />
                </div>
                <Row className="flex-grow-1 position-relative">
                  <ProvisonalDiagnosis
                    encounterDiagnosisValues={values?.encounterDiagnosis}
                    encounterDiagnosisErrors={(errors?.encounterDiagnosis as FormikErrors<any>)}
                    encounterDiagnosisTouched={(touched?.encounterDiagnosis as FormikTouched<any>)}
                    benhNhanInfo={benhNhanInfo}
                    listComorbidity={listComorbidity}
                    setListComorbidity={setListComorbidity}
                    onChange={handleChangeICD10}
							      handleChangeEncounterDiagnosis={handleChangeEncounterDiagnosis}
                  />
                </Row>
              </div>
              <div className="w-100 d-flex spaces mt-2">
                <div className="flex-grow-1 position-relative">
                  <TextField
                    label="Bệnh kèm theo"
                    className="spaces w-100"
                    inputClassName="w-100 spaces pr-40"
                    name="comorbidity"
                    value={values?.encounterDiagnosis?.comorbidity || ""}
                    onChange={(e: any) =>
                      handleChangeEncounterDiagnosis(e.target.name, e.target.value)
                    }
                    labelClassName="ms-0 min-w-100px"
                  />
                </div>
                <div className="spaces W-310 mb-4 ml-10">
                  <TextField
                    label="BS điều trị"
                    name="bacSiDieuTri"
                    type="text"
                    value={currentUser?.name || ""}
                    labelClassName="ms-0 min-w-85px"
                    disabled
                  />
                </div>
              </div>

              <div className="w-100">
                <TextField
                  className="spaces"
                  inputClassName="w-100"
                  label="Ghi chú"
                  name="notes"
                  value={values?.encounterDiagnosis?.notes || ""}
                  onChange={(e: any) =>
                    handleChangeEncounterDiagnosis(
                      e.target.name,
                      e.target.value
                    )
                  }
                  labelClassName="ms-0 min-w-100 spaces"
                />
              </div>
            </div>
            <div className="spaces flex-grow-1 bg-white px-0 radius-2 mt-6 table-ke-thuoc">
              <div className="d-flex flex-column spaces width-100 bg-light h-100">
                <div className="bg-white">
                  <div className="px-10 py-5 h-35 spaces">
                    <InputSearch
                      placeholder="Tìm kiếm"
                      handleChange={handleChange}
                      className="spaces h-25 pr-4 radius-3"
                    />
                  </div>
                  <div className="min-h-180 spaces bg-white mb-6">
                    <SimpleTable
                      data={listDichVu || []}
                      columns={dsDichVuColumnsSimple}
                      height={270}
                      width={1000}
                      scrollable={true}
                      type={SELECTION_MODE.MULTI_TABLE}
                      setDataChecked={handleSelectService}
                      dataChecked={values?.orders || []}
                      handleRenderCell={handleRenderCell}
                      onRowClick={(rowData: RowMouseEventHandlerParams) => {
                        handleOnRowClickCheckbox(rowData);
                      }}
                      keySearch={textSearchDebounce}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1 mt-2 bg-white">
                  <div className="d-flex spaces pt-10 pb-6 px-10 text-pri fw-bold">
                    Phiếu chỉ định
                  </div>
                  <div className="flex-grow-1 spaces h-calc-vh-710">
                    <TableCustom
                      columns={DsDichVuChiDinhColumn(
                        handleRenderPrice,
                        selectedRowIndex, 
                        setSelectedRowIndex,
                        selectedCol, 
                        setSelectedCol,
                        listDoiTuong,
                        updateColumnDSDichVuChiDinh,
                        handleDelete
                      )}
                      data={values?.orders || []}
                      // handleContextMenu={handleContextMenu}
                      height={220}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between spaces px-10 mt-6 bg-white">
                  <PaymentInfor
                    className="w-100 price-info"
                    paymentData={paymentItem}
                  />
                  <div className="d-flex align-items-center spaces gap-10">
                    <Button className="btn-outline min-w-100px">
                      <i className="bi bi-printer" /> In
                    </Button>
                    <Button className="btn-fill min-w-100px" type="submit" onClick={handleCheckBeforeSave}>
                      <IconButtonSave /> Lưu
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      
      {shouldOpenDsPhieuChiDinhModal && (
        <ModalDsPhieuChiDinhCu
          handleClose={() => setShouldOpenDsPhieuChiDinhModal(false)}
        />
      )}
      {shouldOpenPhieuDieuTriModal && (
        <ModalThemPhieuDieuTri
          handleClose={() => setShouldOpenPhieuDieuTriModal(false)}
        />
      )}
      {contextMenu && (
        <ContextMenu
          handleClickOptionContextMenu={handleClickOptionContextMenu}
          handleCloseMenu={() => setContextMenu(null)}
          data={dropListChiDinhDichVu}
          target={contextMenu}
        />
      )}
      <ConfirmDialog
        className="z-index-1060"
        show={shouldOpenConfirmDialog}
        title="Thông báo"
        message="Bạn có chắc chắn muốn bỏ dịch vụ này không ?"
        yes="Xác nhận"
        close="Đóng"
        onCloseClick={() => setShouldOpenConfirmDialog(false)}
        onYesClick={() => handleConfirmDelete()}
      />
      {shouldOpenGhiChuPopup && (
        <ModalEditOneColumn
          handleSave={handleSaveGhiChu}
          code="ghiChu"
          name="Ghi chú"
          handleClose={() => setShouldOpenGhiChuPopup(false)}
        />
      )}
      {shouldOpenSoLuongPopup && (
        <ModalEditOneColumn
          handleSave={(values) =>
            updateColumnDSDichVuChiDinh("soLuong", values?.soLuong)
          }
          code="soLuong"
          name="Chỉnh sửa số lượng"
          handleClose={() => setShouldOpenSoLuongPopup(false)}
        />
      )}

      {openModalThemPhieuDieuTri && (
        <ModalThemPhieuDieuTri
          handleClose={() => setOpenModalThemPhieuDieuTri(false)}
        />
      )}

      <ICD10
        shouldOpenModalICD10={shouldOpenModalICD10}
        setShouldOpenModalICD10={setShouldOpenModalICD10}
        shouldOpenModalSelectICD10={shouldOpenModalSelectICD10}
        setShouldOpenModalSelectICD10={setShouldOpenModalSelectICD10}
        listICD10={listICD10}
        listComorbidity={listComorbidity}
        setListComorbidity={setListComorbidity}
        selectionModeICD10={selectionModeICD10}
        data={values?.encounterDiagnosis}
        onChange={handleChangeICD10}
      />
    </div>
  );
};

export default FormChiDinhDichVu;
