import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, InputGroup, Modal, Row, Stack } from "react-bootstrap";
import { AppContext } from "../../../appContext/AppContext";
import { deleteBenhNhan, searchByPage } from "../../services/TiepNhanServices";
import {
  CODE,
  DEFAULT_PAGE_INDEX,
  KEY,
  overflowHiddenTable,
  textSystem,
  TYPE,
} from "../../../utils/Constant";
import TextValidator from "../../../component/TextValidator";
import SelectTree from "../../../component/SelectTree";
import { IconMenu } from "../../../component/IconSvg";
import { toast } from "react-toastify";
import LabelRequired from "../../../component/LabelRequired";
import { OctTable } from "@oceantech/oceantech-ui";
import moment from "moment";
import { handleConvertAddress } from "../../../utils/AppFunction";
import CustomIconButton from "../../../component/custom-icon-button/CustomIconButton";
import "../../PhanHeTiepNhan.scss";
import ShowStatus from "../../../component/ShowStatus";
import { formatDateTime, formatTrangThaiBenhNhan } from "../../../utils/FormatUtils";
import { ConfirmDialog } from "../../../component/ConfirmDialog";
import DatePickerCustom from "../../../component/date-picker/DatePickerCustom";
import { useFormikContext } from "formik";
import { ITiepDon } from "../../models/TiepDonModel";
import { PhanHeTiepNhanContext } from "../../PhanHeTiepNhan";

type Props = {
  show: boolean;
  onHide: () => void;
  handleGetThongTinBenhNhan: (id: string) => void;
  resetFormTiepDon: () => void;
};

const treePhongDonTiep = {
  code: "all",
  name: "Tất cả bệnh nhân",
  icon: <IconMenu />,
  filter: [],
};
const DSBenhNhan: FC<Props> = (props) => {
  const { show, onHide, handleGetThongTinBenhNhan, resetFormTiepDon } = props;
  const { values } = useFormikContext<ITiepDon>();
  const {
    thongTinBenhNhan,
    setThongTinBenhNhan
  } = useContext(PhanHeTiepNhanContext);
  const { setIsLoading } = useContext(AppContext);
  const currentDate = moment().format("YYYY-MM-DD");
  const [dsBenhNhan, setDsBenhNhan] = useState<any>([]);
  const [statuses, setStatuses] = useState<any[]>([]);
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
  });
  let [objectSearch, setObjectSearch] = useState<any>({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: 15,
  });
  const [keyword, setKeyword] = useState<string>("");
  const [fromDateSearch, setFromDateSearch] = useState<any>(currentDate);
  const [toDateSearch, setToDateSearch] = useState<any>(currentDate);

  //
  const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [treeData, setTreeData] = useState<any>(treePhongDonTiep);
  const [selectedPatient, setSelectedBenhNhan] = useState<any>([]);
  const [itemSelectedTree, setItemSelectedTree] = useState<any>(null);
  const [confirmDialogInfo, setConfirmDialogInfo] = useState<any>();
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === KEY.ENTER) {
      updatePageData();
    }
  };

  const updatePageData = async () => {
    try {
      setIsLoading(true);
      let {
        data: { data },
      } = await searchByPage({
        pageIndex: objectSearch.pageIndex,
        pageSize: objectSearch.pageSize,
        fromDate: fromDateSearch ? fromDateSearch : null,
        toDate: toDateSearch ? toDateSearch : null,
        keyword,
        filterBy: itemSelectedTree?.parrentCode,
        value: itemSelectedTree?.name,
      });
      const newArr = data?.admissions?.content.map((item: any) => {
        return {
          ...item,
          id: item?.encounterId,
        };
      });
      setDsBenhNhan(newArr);
      setTreeData({
        code: "all",
        name: "Tất cả bệnh nhân",
        icon: <IconMenu />,
        filter: data?.treeList,
      });
      setStatuses(data?.statuses);
      setConfigTable({
        totalPages: data?.admissions?.totalPages,
        totalElements: data?.admissions?.totalElements,
        numberOfElements: data?.admissions?.numberOfElements,
      });
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
  }, [
    objectSearch.pageIndex,
    objectSearch.pageSize,
    fromDateSearch,
    toDateSearch,
    itemSelectedTree,
  ]);

  const handleSelectBenhNhan = (data: any) => {
    onHide();
    handleGetThongTinBenhNhan(data?.encounterId);
  };

  const handleDeleteBenhNhan = async (dataDelete: any) => {
    try {
      let { data } = await deleteBenhNhan(dataDelete?.encounterId);
      if (data?.code === CODE.SUCCESS) {
        toast.success("Xóa bệnh nhân thành công!");
        if (dataDelete?.encounterId === values?.encounter?.id) {
          resetFormTiepDon();
        };
        updatePageData();
        setThongTinBenhNhan({
          ...thongTinBenhNhan,
          isChangeThongTinBenhNhan: !thongTinBenhNhan?.isChangeThongTinBenhNhan
        });
      } else {
        toast.warning(data?.data[0]?.errorMessage);
      }
      setConfirmDialogInfo({ show: false });
    } catch (error) {
      toast.error("Có lỗi xảy ra, vui lòng thử lại!");
    }
  };

  const handleAdmission = () => {
    if (selectedPatient.length > 0) {
      onHide();
      handleGetThongTinBenhNhan(selectedPatient?.[0]?.encounterId);
    } else {
      toast.warning("Vui lòng chọn bệnh nhân tiếp đón");
    }
  };

  const getSelectedItem = (item: any) => {
    setItemSelectedTree(item);
  };

  const dsBenhNhanColumns = [
    {
      name: "Thao tác",
      field: "",
      cellStyle: {
        minWidth: "80px",
        textAlign: "center",
        ...textSystem,
      },
      render: (rowData: any) => {
        return (
          <div className="d-flex justify-content-center ">
            <CustomIconButton
              variant="edit"
              onClick={() => handleSelectBenhNhan(rowData)}
            >
              <i className="bi bi-pencil-square text-navy"></i>
            </CustomIconButton>
            <CustomIconButton
              variant="delete"
              onClick={() =>
                setConfirmDialogInfo({
                  show: true,
                  message: "Bạn có chắc chắn muốn xóa bệnh nhân này không?",
                  onYes: () => handleDeleteBenhNhan(rowData),
                })
              }
            >
              <i className="bi bi-trash3-fill text-danger"></i>
            </CustomIconButton>
          </div>
        );
      },
    },
    {
      name: "TT",
      field: "",
      cellStyle: {
        minWidth: "50px",
        textAlign: "center",
      },
      render: (rowData: any, index: number, stt: number) =>
        formatTrangThaiBenhNhan(rowData?.color),
    },
    {
      name: "STT",
      field: "",
      cellStyle: {
        minWidth: "50px",
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>,
    },
    {
      name: "Thời gian đón tiếp",
      field: "",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center",
      },
      render: (rowData: any) =>
        rowData?.encounterDatetime
          ? formatDateTime(rowData?.encounterDatetime)
          : "",
    },
    {
      name: "Mã BN",
      field: "code",
      cellStyle: {
        minWidth: "100px",
        textAlign: "center",
      },
    },
    {
      name: "Họ và tên",
      field: "personName",
      cellStyle: {
        minWidth: "200px",
      },
    },
    {
      name: "SĐT",
      field: "phoneNumber",
      cellStyle: {
        ...overflowHiddenTable,
        minWidth: "100px",
        textAlign: "center",
      },
    },
    {
      name: "Giới tính",
      field: "gender",
      cellStyle: {
        minWidth: "120px",
        textAlign: "center",
      },
    },
    {
      name: "Đối tượng",
      field: "visitObjectTypeName",
      cellStyle: {
        minWidth: "100px",
      },
    },
    {
      name: "BHYT",
      field: "insCode",
      cellStyle: {
        minWidth: "150px",
        textAlign: "center",
      },
      render: (rowData: any) =>
        rowData?.insCode ? rowData?.insCode.split(",") : "",
    },
    {
      name: "Lý do khám",
      field: "visitReasonName",
      cellStyle: {
        ...overflowHiddenTable,
        minWidth: "100px",
        maxWidth: "200px",
      },
    },
    {
      name: "ĐK khám",
      field: "visitTypeName",
      cellStyle: {
        ...overflowHiddenTable,
        maxWidth: "200px",
      },
    },
    {
      name: "Dịch vụ",
      field: "termSvcName",
      cellStyle: {
        ...overflowHiddenTable,
        maxWidth: "230px",
      },
    },
    {
      name: "Phòng khám",
      field: "deptName",
      cellStyle: {
        maxWidth: "200px",
        ...overflowHiddenTable,
      },
    },
    {
      name: "Địa chỉ",
      field: "administrativeUnit",
      cellStyle: {
        maxWidth: "300px",
        ...overflowHiddenTable,
      },
      render: (rowData: any) => handleConvertAddress(rowData),
    },
  ];

  return (
    <div>
      <Modal
        centered
        show={show}
        onHide={onHide}
        size="xl"
        fullscreen
        className="page-full spaces h-calc-vh-50"
      >
        <Modal.Header closeButton className="header-modal min-h-50px me-2">
          <Modal.Title>
            <div className="text-transform-none fs-3 color-blue-custom">Danh sách bệnh nhân</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="spaces p-0 bg-white">
          <Row className="d-flex spaces m-0 h-100 max-h-calc-100">
            <Col xs="2" className="spaces p-0">
              <SelectTree
                className="w-100 h-1000 border border-0 overflow-auto"
                codeCollapses={codeCollapses}
                handleChangeCollapsesCode={setCodeCollapses}
                idSelected={idSelected}
                handleChangeSelectId={setIdSelected}
                selectTree={treeData}
                getSelectedItem={getSelectedItem}
              />
            </Col>
            <Col
              xs="10"
              className="spaces p-10 d-flex flex-column justify-content-between"
            >
              <div>
                <div className="d-flex justify-content-end">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="justify-content-end"
                  >
                    <div className="d-flex align-items-center spaces h-32 mb-3">
                      <LabelRequired
                        className="min-w-60px fw-bold"
                        label="Từ ngày"
                      />
                      <DatePickerCustom
												name="tuNgay"
												value={fromDateSearch}
												setDateValue={date => {
													setFromDateSearch(date);
												}}
                        dateFormatOutput="YYYY-MM-DD"
											/>
                    </div>
                    <div className="d-flex align-items-center spaces h-32 mb-3">
                      <LabelRequired
                        className="min-w-65px fw-bold"
                        label="Đến ngày"
                      />
                      <DatePickerCustom
												name="denNgay"
												value={toDateSearch}
												setDateValue={date => {
													setToDateSearch(date);
												}}
                        dateFormatOutput="YYYY-MM-DD"
											/>
                    </div>
                    <div className="spaces w-200">
                      <InputGroup className="spaces h-32">
                        <TextValidator
                          className="spaces h-29 w-100"
                          name="keyword"
                          placeholder="Tìm kiếm"
                          value={keyword}
                          onChange={(e: any) => {
                            setKeyword(e?.target?.value);
                          }}
                          onKeyDown={handleKeyPress}
                        />
                        <Button className="btn-fill spaces h-29" onClick={(e: any) => updatePageData()}>
                          <span>Tìm kiếm</span>
                        </Button>
                      </InputGroup>
                    </div>
                  </Stack>
                </div>
                <div className="spaces h-calc-vh-300">
                  <OctTable
                    id="list-patients"
                    className="h-100 table-background-th"
                    data={dsBenhNhan || []}
                    columns={dsBenhNhanColumns}
                    searchObject={objectSearch}
                    setSearchObject={setObjectSearch}
                    notDelete={true}
                    notEdit={false}
                    noToolbar={true}
                    totalPages={configTable.totalPages}
                    totalElements={configTable.totalElements}
                    numberOfElements={configTable.numberOfElements}
                    handleDoubleClick={handleSelectBenhNhan}
                    setDataChecked={setSelectedBenhNhan}
                    selectionMode={TYPE.SINGLE_NO_RADIO_BUTTON}
                    fixedColumnsCount={-1}
                    isShowSelection={false}
                    clearToolbar={true}
                  />
                </div>
              </div>
              <Stack
                direction="horizontal"
                gap={3}
                className="justify-content-between"
              >
                <ShowStatus statuses={statuses} />
              </Stack>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {
        <ConfirmDialog
          title="Thông báo"
          show={confirmDialogInfo?.show}
          onYesClick={confirmDialogInfo?.onYes}
          message={confirmDialogInfo?.message}
          yes="Có"
          close="Không"
          onCloseClick={() => setConfirmDialogInfo({ show: false })}
        />
      }
    </div>
  );
};
export default DSBenhNhan;
