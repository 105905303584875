import React, { FC, useContext, useEffect, useState } from "react";
import { WithChildren } from "../../../../_metronic/helpers";
import {
  KEY_DS_DANH_MUC_TIEP_DON,
  LEVEL,
  LOAI_PHONG,
  SIMPLE_CATEGORY_TYPE,
  TERM_CLASS_IDS
} from "../../utils/Constant";
import { addIndexedDBItem, getIndexedDBItem } from "../../utils/IndexedDB";
import { AppContext } from "../../appContext/AppContext";
import {
  getAllDistricts,
  getAllProvinces,
  getAllSubDistricts,
  getListKhoaPhong,
  getSimpleCategory,
  getTermByClassId,
  getTermClasses
} from "../../utils/ServicesUtils";
import {
  getListDangKyKham,
  getListDanToc,
  getListQuocTich
} from "../../phan-he-tiep-nhan-thanh-toan/services/TiepNhanServices";
import { AxiosResponse } from "axios";
import { IDanhSachDanhMuc } from "../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";

const CacheWrap: FC<WithChildren> = ({ children }) => {
  const { setIsLoading } = useContext(AppContext);
  const [isHasRessponse, setIsHasResponse] = useState<boolean>(true);

  const handleCacheDsDanhMuc = async () => {
    try {
      setIsLoading(true);
      const CLASS_IDS_DICH_VU = `${TERM_CLASS_IDS.XET_NGHIEM},${TERM_CLASS_IDS.CDHA},${TERM_CLASS_IDS.PTTT}`;
      const [
        responseProvinces,
        responseDistricts,
        responseSubDistricts,
        responseDoiTuong,
        responseGioiTinh,
        responseNgheNghiep,
        responseLDKham,
        responseQuocTich,
        responseDanToc,
        responseDKKham,
        responsePhongKham,
        responseTuyenKCB,
        // responseDVKhamBenh,
        responseICD10,
        responseHTXT,
        responseKQDT,
        // responseDichVu
      ] = await Promise.allSettled([
        getAllProvinces(),
        getAllDistricts(),
        getAllSubDistricts(),
        getSimpleCategory({ type: SIMPLE_CATEGORY_TYPE.DOI_TUONG }),
        getSimpleCategory({ type: SIMPLE_CATEGORY_TYPE.GIOI_TINH }),
        getSimpleCategory({ type: SIMPLE_CATEGORY_TYPE.NGHE_NGHIEP }),
        getSimpleCategory({ type: SIMPLE_CATEGORY_TYPE.LY_DO_KHAM }),
        getListQuocTich(),
        getListDanToc(),
        getListDangKyKham(),
        getListKhoaPhong({ level: LEVEL.PHONG, type: LOAI_PHONG.PHONG_KHAM_BENH }),
        getSimpleCategory({ type: SIMPLE_CATEGORY_TYPE.TUYEN_KCB }),
        // getTermClasses({ classIds: TERM_CLASS_IDS.KHAM_BENH }),
        getTermByClassId({ classId: TERM_CLASS_IDS.ICD10 }),
        getTermClasses({ classIds: TERM_CLASS_IDS.HTXT }),
        getTermByClassId({ classId: TERM_CLASS_IDS.KQDT }),
      ]);

      const getData = (
        response: PromiseSettledResult<AxiosResponse<any>>,
        termType?: number | string
      ) => {
        const STATUS_FULFILLED = "fulfilled";
        if (STATUS_FULFILLED === response.status) {
          switch (termType) {
            case TERM_CLASS_IDS.KHAM_BENH:
              return response?.value?.data?.data?.[0]?.terms?.[0]?.subs || [];

            case TERM_CLASS_IDS.ICD10:
            case TERM_CLASS_IDS.KQDT:
            case CLASS_IDS_DICH_VU:
                  return response?.value?.data?.data || [];

            case TERM_CLASS_IDS.HTXT:
              return response?.value?.data?.data?.[0]?.terms;

            default:
              return response?.value?.data?.data?.content || [];
          }
        }
        return [];
      };
      let provinces = getData(responseProvinces);
      let districts = getData(responseDistricts);
      let subDistricts = getData(responseSubDistricts);
      let listDoiTuong = getData(responseDoiTuong);
      let listGioiTinh = getData(responseGioiTinh);
      let listNgheNghiep = getData(responseNgheNghiep);
      let listLDKham = getData(responseLDKham);
      let listQuocTich = getData(responseQuocTich);
      let listDanToc = getData(responseDanToc);
      let listDKKham = getData(responseDKKham);
      let listPhongKham = getData(responsePhongKham);
      let listTuyenKCB = getData(responseTuyenKCB);
      // let listDVKhamBenh = getData(
      //   responseDVKhamBenh,
      //   TERM_CLASS_IDS.KHAM_BENH
      // );
      let listICD10 = getData(responseICD10, TERM_CLASS_IDS.ICD10);
      let listHTXT = getData(responseHTXT, TERM_CLASS_IDS.HTXT);
      let listKQDT = getData(responseKQDT, TERM_CLASS_IDS.KQDT);
      // let listDichVu = getData(responseDichVu, CLASS_IDS_DICH_VU);

      let dsDanhMucResponse = {
        provinces,
        districts,
        subDistricts,
        listDoiTuong,
        listGioiTinh,
        listNgheNghiep,
        listLDKham,
        listQuocTich,
        listDanToc,
        listDKKham,
        listPhongKham,
        listTuyenKCB,
        // listDVKhamBenh,
        listICD10,
        listHTXT,
        listKQDT,
        // listDichVu
      };

      await addIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON, dsDanhMucResponse, setIsLoading);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetDsDanhMuc = async () => {
    try {
      const responseDsDanhmuc: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      
      let hasEmptyArray =
        responseDsDanhmuc &&
        Object.values(responseDsDanhmuc).some(
          (item: any[]) => item.length === 0
        );
        
      if (!responseDsDanhmuc || hasEmptyArray) {
        handleCacheDsDanhMuc();
      }
      // setIsHasResponse(!hasEmptyArray)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGetDsDanhMuc();
  }, []);

  return <>{isHasRessponse && children}</>
};

export default CacheWrap;
