import { FC, useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import { AppContext } from "../../../appContext/AppContext";
import CustomTabMenu from "../../../component/CustomTabMenu";
import { KEY_DS_TAB_TIEP_DON } from "../../../utils/Constant";
import { danhSachMenu, dsTabThongTinBN } from "../../const/PhanHeNoitruConst";
import DsBenhNhan from "./DsBenhNhan";
import ThaoTacNoiTru from "./ThaoTacNoiTru";

export const TiepDon: FC = () => {
    const { setBreakCrumb, setDSMenu } = useContext(AppContext);
    const [activeTabBN, setActiveTabBN] = useState<string>("0");

    const intl = useIntl();
    let breakCrumb = [
        { text: intl.formatMessage({ id: 'MENU.RECEIVE' }), url: '' },
        { text: intl.formatMessage({ id: 'MENU.RECEIVE' }), url: '/receive' },
    ];

    useEffect(() => {
        setBreakCrumb(breakCrumb);
        setDSMenu(danhSachMenu);
        return () => {
            setDSMenu([]);
            setBreakCrumb([]);
        };
    }, []);

    return (
        <div className="reception-list bg-gray">
            <div className="reception__header spaces d-flex flex-column">
                <div>
                    <ThaoTacNoiTru />
                </div>
                <Row className="">
                    <div className="pe-0 spaces width-40 h-calc-vh-98">
                        <DsBenhNhan />
                    </div>
                    <div className="pl-0 ml-pt-0_6 spaces border width-59 h-calc-vh-98 bg-white">
                        <CustomTabMenu
                            danhsachTabs={dsTabThongTinBN}
                            keyDanhSachTabs={KEY_DS_TAB_TIEP_DON}
                            activeTab={activeTabBN}
                            onTabChange={(activeTab: any) => setActiveTabBN(activeTab)}
                        />
                    </div>
                </Row>
            </div>
        </div>
    )
}

export default TiepDon;
