import { Column } from "react-table";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
import { LIST_COLOR_TRANG_THAI_PHIEU } from "../../../utils/Constant";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import { DATA_TYPE_XN } from "../../../phan-he-xet-nghiem/const/constants";
import { genderClassName } from "../../../phan-he-xet-nghiem/common";

const chiDinhXetNghiemColumn: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => <div className="p-0"></div>,
    id: "dv",
    Cell: ({ ...props }) => (
      <TableCustomCell
        tableProps={props}
        listColorPhieu={LIST_COLOR_TRANG_THAI_PHIEU}
        className="d-flex justify-content-between"
        data={
          <div className="ms-5">
            <p className="my-1">{props.data[props.row.index]?.date}</p>
            <p className="my-1">{props.data[props.row.index]?.title}</p>
          </div>
        }
      />
    ),
  },
];

const columnsDSXetNghiem = [
  {
    title: "Tên xét nghiệm",
    field: "name",
    className: "spaces width-40",
    render: (rowData: any) => {
      return (
        <div
          className={`${rowData?.isCancel ? "text-danger" :
            genderClassName(rowData)
          }`}
        >
          {rowData?.name}
        </div>
      );
    },
  },
  {
    title: "SL",
    field: "quantity",
    className: "spaces width-3 text-center",
    render: (rowData: any) => {
      const DEFAULT_QUANTITY = 1;
      return (
        <div
          className={`${rowData?.isCancel ? "text-danger" :
            genderClassName(rowData)
          }`}
        >
          {rowData?.isCancel ? 0 : DEFAULT_QUANTITY}
        </div>
      );
    },
  },
  {
    title: "Kết quả",
    field: "ketQua",
    className: "spaces width-11 text-center",
    render: (rowData: any) => {
      return (
        <div
          className={`${rowData?.isCancel ? "text-danger spaces p-2" :
            genderClassName(rowData)
          }`}
        >
          {rowData?.obs?.value || ""}
        </div>
      );
    },
  },
  {
    title: "",
    field: "HL",
    className: "spaces width-5 text-center",
    render: (rowData: any) => {
      const validValue = (value: any) => {
        return value || value === 0;
      };
      let isText = rowData?.datatypeId === DATA_TYPE_XN.TEXT;
      let lowNormal = rowData?.numeric?.lowNormal;
      let hiNormal = rowData?.numeric?.hiNormal;
      let value = rowData?.obs?.value;
      let result = "";
      if (validValue(value) && validValue(hiNormal) && value > hiNormal) {
        result = "H";
      } else if (validValue(value) && validValue(lowNormal) && value < lowNormal) {
        result = "L";
      };
      return (
        !isText ? <div
          className={`${genderClassName(rowData)}`}
          style={{ color: rowData?.statusColor }}
        >
          {result}
        </div> : ""
      );
    },
  },
  {
    title: "Đơn vị",
    field: "donVi",
    className: "spaces width-11 text-center",
    render: (rowData: any) => {
      return (
        <div
          className={`${rowData?.isCancel ? "text-danger" :
            genderClassName(rowData)
          }`}
        >
          {rowData?.numeric?.units}
        </div>
      );
    },
  },
  {
    title: "GTBT",
    field: "gtbt",
    className: "spaces width-11 text-center",
    render: (rowData: any) => {
      let isText = rowData?.datatypeId === DATA_TYPE_XN.TEXT;
      const validValue = (value: any) => {
        return value || value === 0;
      };
      let result = "";
      let lowNormal = rowData?.numeric?.lowNormal;
      let hiNormal = rowData?.numeric?.hiNormal;
      if (isText) {
        result = rowData?.numeric?.label || "";
      } else if (validValue(lowNormal) && validValue(hiNormal)) {
        result = (lowNormal === hiNormal) ? lowNormal : lowNormal + " - " + hiNormal;
      } else if (validValue(lowNormal) && !validValue(hiNormal)) {
        result = "> " + lowNormal;
      } else if (!validValue(lowNormal) && validValue(hiNormal)) {
        result = "< " + hiNormal;
      };
      return (
        <div
          className={`${rowData?.isCancel ? "text-danger" :
            genderClassName(rowData)
          }`}
        >
          {result || ""}
        </div>
      );
    },
  },
  { title: "Loại bệnh phẩm", field: "type", className: "spaces width-13" },
  {
    title: "Đối tượng", field: "doiTuong", className: "spaces width-14", render: (rowData: any) => {
      return (
        <div className={`${rowData?.isCancel ? "text-danger" : ""}`}>{rowData?.objectTypeName || ""}</div>
      )
    },
  }
];

const xetNghiemColumn: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"STT"}
        className=" text-center text-white"
      />
    ),
    id: "STT",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center"
        data={(props.row.index + 1).toString()}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="Tên xét nghiệm"
        className=" min-w-250px text-center "
      />
    ),
    id: "Tên xét nghiệm",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="min-w-150px "
        data={props.data[props.row.index]?.serviceName || ""}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="SL"
        className=" text-center "
      />
    ),
    id: "Số lượng",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="min-w-50px text-center "
        data={props.data[props.row.index]?.soLuong}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="Kết quả"
        className="min-w-200px  text-center"
      />
    ),
    id: "Kết quả",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="min-w-200px text-start "
        data={props.data[props.row.index]?.loaiCongKham}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="Loại bệnh phẩm"
        className="min-w-200px  text-center"
      />
    ),
    id: "Loại bệnh phẩm",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="min-w-200px text-start "
        data={props.data[props.row.index]?.loaiCongKham}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title="Đối tượng"
        className="min-w-200px text-center"
      />
    ),
    id: "Đối tượng",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="min-w-200px text-start "
        data={props.data[props.row.index]?.ghiChu}
      />
    ),
  },
];

export { chiDinhXetNghiemColumn, columnsDSXetNghiem, xetNghiemColumn };
