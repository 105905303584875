import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { IconButtonSave } from '../../../../../component/IconSvg';
import { CHI_PHI_THUOC_FIELDS, CHI_PHI_THUOC_TYPE } from '../../../../constants/Thuoc';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import { useKeVatTuContext } from '../../context';
import { getFormFieldConfig } from '../../../../../phan-he-vien-phi/services/PhanHeVienPhiServices';
import PaymentInfor from '../../../../../phan-he-vien-phi/components/PaymentInfor';

const ChiPhiVatTu = () => {
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const { vatTuChecked } = useKeVatTuContext();
	const [paymentItem, setPaymentItem] = useState<any>([]);

	const thongTinLanKham = benhNhanInfo.thongTinKhamBenh.visit;
	const thongTinChiPhi = {
		tongChiPhi: thongTinLanKham.ttTong,
		bhyt: thongTinLanKham.ttBhyt,
		benhNhan: thongTinLanKham.ttNguoiBenh,
		tamUng: thongTinLanKham.ttTamUng,
		daThu: thongTinLanKham.ttDaThu,
		mienGiam: thongTinLanKham.ttDiscountInv,
		conDu: thongTinLanKham.ttConNo,
	};

	const tongChiPhiVatTu = vatTuChecked.length
		? vatTuChecked.reduce(
				(acc, row) => acc + (row.deleted ? 0 : (row.donGia || 0) * (row.soLuong || 0)),
				0
		  )
		: 0;

	const getChiPhiVatTu = (field: CHI_PHI_THUOC_TYPE) => {
		const MAP_FIELD_VALUE = {
			[CHI_PHI_THUOC_FIELDS.TONG_CHI_PHI]: thongTinChiPhi.tongChiPhi + tongChiPhiVatTu,
			[CHI_PHI_THUOC_FIELDS.BHYT]: thongTinChiPhi.bhyt,
			[CHI_PHI_THUOC_FIELDS.NGUON_KHAC]: 0,
			[CHI_PHI_THUOC_FIELDS.BENH_NHAN]:
				thongTinChiPhi.benhNhan + tongChiPhiVatTu - thongTinChiPhi.bhyt,
			[CHI_PHI_THUOC_FIELDS.TAM_UNG]: thongTinChiPhi.tamUng,
			[CHI_PHI_THUOC_FIELDS.DA_THU]: thongTinChiPhi.daThu,
			[CHI_PHI_THUOC_FIELDS.MIEN_GIAM]: thongTinChiPhi.mienGiam,
			[CHI_PHI_THUOC_FIELDS.CON_DU]:
				thongTinChiPhi.tamUng +
				thongTinChiPhi.daThu -
				(thongTinChiPhi.benhNhan + tongChiPhiVatTu),
		};
		return MAP_FIELD_VALUE[field];
	};

	const handleSetPaymentInfor = async () => {
		const FORM_FIELD_CONFIG = [
		  {
			code: "TONG_CHI_PHI",
			name: "Tổng chi phí",
			value: (thongTinChiPhi.tongChiPhi + tongChiPhiVatTu) || 0,
			type: "price",
			color: "text-danger"
		  },
		  {
			code: "BHYT",
			name: "BHYT",
			value: thongTinChiPhi?.bhyt || 0,
			type: "price",
		  },
		  {
			code: "NGUON_KHAC",
			name: "Nguồn khác",
			value: 0,
			type: "price",
		  },
		  {
			code: "BENH_NHAN",
			name: "Bệnh nhân",
			value: (thongTinChiPhi.benhNhan + tongChiPhiVatTu - thongTinChiPhi.bhyt) || 0,
			type: "price",
		  },
		  {
			code: "TAM_UNG",
			name: "Tạm ứng",
			value: thongTinChiPhi?.tamUng || 0,
			type: "price",
		  },
		  {
			code: "DA_THU",
			name: "Đã thu",
			value: thongTinChiPhi?.daThu || 0,
			type: "price",
		  },
		  {
			code: "MIEN_GIAM",
			name: "Miễn giảm",
			value: thongTinChiPhi?.mienGiam || 0,
			type: "price",
		  },
		  {
			code: "CON_NO",
			name: getChiPhiVatTu('CON_DU') < 0 ? 'Còn nợ' : 'Còn dư',
			value: Math.abs(getChiPhiVatTu('CON_DU')) || 0,
			type: "price",
		  },
		]
		try {
		  let { data: { data } } = await getFormFieldConfig("FORM_INFO_PAYMENT_ASSIGN");
		  const jsonObject = JSON.parse(data[0]?.valueText);
		  
		  let newArr: any[] = [];
		  jsonObject.forEach((item: any) => {
			let obj = FORM_FIELD_CONFIG.find((obj: any) => obj?.code === item);
			
			newArr.push(obj);
		  })
		  
		  setPaymentItem(newArr);
		} catch (err) {
		  console.error(err);
		}
	  };
	
	  useEffect(() => {
		handleSetPaymentInfor();
	  }, [tongChiPhiVatTu]);

	return (
		<div className="d-flex justify-content-between align-items-center spaces p-10 mt-6 bg-white w-100">
			<PaymentInfor
				className="w-100 price-info"
				paymentData={paymentItem}
			/>
			<div className='spaces min-w-80'>
				<Button className="btn-fill" type="submit">
					<IconButtonSave /> Lưu lại
				</Button>
			</div>
		</div>
	);
};

export default ChiPhiVatTu;
