import React from 'react'

type Props = {}

const DVKhac = (props: Props) => {
  return (
    <div className='spaces px-16 py-5'>DV khác</div>
  )
}

export default DVKhac