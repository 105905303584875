import { createContext, Dispatch, MutableRefObject, ReactNode, SetStateAction, useContext, useRef, useState } from 'react';

type KeVatTuContextProps = {
	treeNodeValue: any[];
	setTreeNodeValue: Dispatch<SetStateAction<object[]>>;
	vatTuChecked: any[];
	setVatTuChecked: Dispatch<SetStateAction<any[]>>;
	treeValue: any;
	setTreeValue: Dispatch<SetStateAction<any>>;
	deletedList: Map<string, any>;
	setDeletedList: (key: string, value: any) => void;
};

const initialContextValue: KeVatTuContextProps = {
	vatTuChecked: [],
	setVatTuChecked() {},
	treeNodeValue: [],
	setTreeNodeValue() {},
	treeValue: {},
	setTreeValue() {},
	deletedList: new Map(),
	setDeletedList() {}
};

const KeVatTuContext = createContext<KeVatTuContextProps>(initialContextValue);

export const KeVatTuContextProvider = ({ children }: { children: ReactNode }) => {
	const [vatTuChecked, setVatTuChecked] = useState<any[]>([]);
	const [treeValue, setTreeValue] = useState<any>();
	const [treeNodeValue, setTreeNodeValue] = useState<object[]>([]);
	const deletedListRef = useRef<Map<string, any>>(new Map());

	const setDeletedList = (key: string, value: any) => {
		if(!deletedListRef.current.has(key)) {
			deletedListRef.current.set(key, value);
		}
	};

	return (
		<KeVatTuContext.Provider
			value={{
				vatTuChecked,
				setVatTuChecked,
				treeNodeValue,
				setTreeNodeValue,
				treeValue,
				setTreeValue,
				deletedList: deletedListRef.current,
				setDeletedList
			}}
		>
			{children}
		</KeVatTuContext.Provider>
	);
};

export const useKeVatTuContext = () => useContext<KeVatTuContextProps>(KeVatTuContext);
