import { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

import moment from 'moment';
import { toast } from 'react-toastify';
import { AppContext } from '../appContext/AppContext';
import ButtonInPhieu from '../component/button-in-phieu';
import FormInPhieuDialog from '../component/button-in-phieu/components/PrintDialog';
import FilterSidebar from '../component/filter-sidebar/FilterSidebar';
import LabelRequired from '../component/LabelRequired';
import { IInPhieuParams } from '../models/params';
import {
	CODE,
	DEFAULT_PAGE_INDEX,
	KEY,
	KEY_DS_DANH_MUC_TIEP_DON,
	ORDER_TYPE_ID,
	RESPONSE_MESSAGE,
} from '../utils/Constant';
import { blobToBase64, calculateAge, formatDateTime, formatDateToYYYYMMDD } from '../utils/FormatUtils';
import { getIndexedDBItem } from '../utils/IndexedDB';
import WfButton from '../workflows/components/WfButton';
import { WfConstTracker } from '../workflows/Constant';
import { getWfWorkflows, updateWfWorkflows } from '../workflows/services/WfWorkflowsService';
import BangDsDichVuCDHA from './components/BangDsDichVuCdha';
import BangDsPhieuCDHA from './components/BangDsPhieuCdha';
import ModalThucHienCDHA from './components/modals/ModalThucHienCDHA';
import { LIST_ORDER, TYPE_SAVE_CDHA, WORK_FLOW_STATUS_CDHA } from './constants/Constants';
import { IBenhNhanV3CDHA } from './models/ModelsPhanHeCDHAVaTDCN';
import './PhanHeCDHAVaTDCN.scss';
import {
	createObsValue,
	getInforBnCDHA,
	getInforPhieuCDHA,
	getRispacsGroups,
	getRispacsStatus,
	inPhieuKetQuaCLS,
	returnResult,
	updateTimes,
} from './services/PhanHeCDHAVaTDCNService';
import { PATIENT_TYPE } from '../phan-he-vien-phi/const/VienPhiConstant';

const LIST_FORMAL = [
	{
		id: 1,
		value: '1',
		name: 'Ngày y lệnh',
		code: 'indicationTime',
	},
	{
		id: 3,
		value: '3',
		name: 'Ngày thực hiện',
		code: 'executionStartTime',
	},
	{
		id: 4,
		value: '4',
		name: 'Ngày trả kết quả',
		code: 'resultReportingTime',
	},
];
const PhanHeCDHAVaTDCN = () => {
	const { setIsLoading } = useContext(AppContext);
	const [filter, setFilter] = useState<any>(null);
	const [dsDichVu, setDsDichVu] = useState<any[]>([]);
	const [isViewModal, setIsViewModal] = useState(true);
	const [statusSummary, setStatusSummary] = useState<any>([]);
	const [workFlowStatus, setWorkFlowStatus] = useState('');
	const [workFlowNewStatus, setWorkFlowNewStatus] = useState<any>(null);
	const [listICD10, setListICD10] = useState<any>([]);
	const [keyOpenPhieuIn, setKeyOpenPhieuIn] = useState<string>('');
	const [typeSave, setTypeSave] = useState<any>(null);
	
	const [statusChangedEvent, setStatusChangedEvent] = useState<number>();
	const [infoBnSelectedCdha, setInfoBnSelectedCDHA] = useState<IBenhNhanV3CDHA>();
	const [forceRender, setForceRender] = useState<boolean>(false);
	const [dichVuSelected, setDichVuSelected] = useState<{
		open: boolean;
		data: any;
	}>({
		open: false,
		data: null,
	});

	const getListStatusCDHA = async (filter: any) => {
		setIsLoading(true);
		let searchObject = {
			pageIndex: DEFAULT_PAGE_INDEX,
			pageSize: 9999999,
			keyword: filter?.keyword,
			isBhyt: filter?.typeCheckBHYT ? true : false,
			isVienPhi: filter?.typeCheckVP ? true : false,
			sortBy: filter?.formal?.code,
			descending: filter?.order?.id === LIST_ORDER.DERCEASE.id ? true : false,
			fromDate: formatDateToYYYYMMDD(filter?.period?.fromDate),
			toDate: formatDateToYYYYMMDD(filter?.period?.toDate),
			filterBy: filter?.filterBy || null,
			filterValue: filter?.filterValue || null,
			fulfillDeptCode: filter?.fulfillDeptCode || null,
		};
		try {
			let { data } = await getRispacsStatus(searchObject);
			if (data?.code === CODE.SUCCESS) {
				setStatusSummary(data?.data || []);
				setIsLoading(false);
			}
		} catch (e) {
			toast.error(RESPONSE_MESSAGE.ERROR);
			setIsLoading(false);
		}
	};

	const handleDoubleClick = (row: any) => {
		setDichVuSelected({
			open: true, 
			data: row.original
		});
	};

	useEffect(() => {
		filter && getListStatusCDHA(filter);
	}, [filter]);

	const handleThucHienCDHA = (currStatusId: any) => {
		if (infoBnSelectedCdha) {
			setInfoBnSelectedCDHA({
				...infoBnSelectedCdha,
				chanDoanHinhAnh: [
					{
						...infoBnSelectedCdha?.chanDoanHinhAnh?.[0],
						nguoiThucHien: 'Quản trị hệ thống',
						ngayThucHien: moment().format('HH:mm DD/MM/YYYY'),
					},
				],
			} as IBenhNhanV3CDHA);
			if (currStatusId === WORK_FLOW_STATUS_CDHA.CHUA_THUC_HIEN.code) {
				handleSubmitForm({}, currStatusId);
			} else {
				handleGetInfoBn(infoBnSelectedCdha, true);
			}
			setWorkFlowStatus(currStatusId);
			doSearch(filter);
		} else {
			toast.warning('Vui lòng chọn bệnh nhân thực hiện!');
		}
	};

	const getWorkFlowStatus = async () => {
		try {
			let tracker = WfConstTracker.RISPACS;
			let objectId = infoBnSelectedCdha?.id || '';
			let { data } = await getWfWorkflows(tracker, objectId);
			if (data?.code === CODE.SUCCESS) {
				setWorkFlowNewStatus(data?.data?.newStatusId);
				setWorkFlowStatus(data?.data?.currStatusCode);
			}
		} catch (e) {
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	useEffect(() => {
		if (infoBnSelectedCdha?.id) {
			getWorkFlowStatus();
		}
	}, [infoBnSelectedCdha]);

	useEffect(() => {
		setIsViewModal(workFlowStatus !== WORK_FLOW_STATUS_CDHA.DANG_THUC_HIEN.code);
	}, [workFlowStatus]);

	const convertImageToBase64 = async (images: any[], orderId: string) => {
		const result: any[] = [];
		for (const image of images) {
			if (!image.id) {
				const blob = new Blob([image.file]);
				const base64 = await blobToBase64(blob);
				const { file, src, index, ...data } = image;
				result.push({
					...data,
					orderId,
					url: base64,
				});
			} else {
        		result.push(image);
     		}
		}
		return result;
	};

	const handleSubmitForm = async (values: any, currentId?: any) => {
		let dataSubmit: any = {
			personId: infoBnSelectedCdha?.patient?.personId,
			orderId: dichVuSelected.data?.orderId,
			orderGroupId: infoBnSelectedCdha?.orderGroupId,
			values: {
				result: values?.noiDungMauKetQua || '',
				startTime: values?.startTime || null,
				endTime: values?.endTime || null,
				executionTime: values?.executionTime || '',
				minimumTime: values?.minimumTime || '',
				conclusion: values?.conclusion || '',
			},
		};

		if (values.images?.length) {
			const listFiles = await convertImageToBase64(
				values.images,
				dichVuSelected.data?.orderId
			);
			dataSubmit.files = listFiles;
		}

		try {
			setIsLoading(true);
			await createObsValue(dataSubmit);
			toast.success(
				currentId === WORK_FLOW_STATUS_CDHA.CHUA_THUC_HIEN.code
					? 'Hủy thực hiện thành công'
					: 'Thực hiện thành công'
			);
			values?.handleGetResult && values?.handleGetResult();
			if (values?.actionType) {
				setTypeSave(values?.actionType);
			}

			if (values?.actionType === TYPE_SAVE_CDHA.SAVE_PRINT_RETURN.code) {
				handleReturnResult();
			} else {
				handleGetInfoBn(infoBnSelectedCdha);
			}
		} catch (error) {
			toast.error('Xảy ra lỗi, vui lòng thử lại!');
		} finally {
			setIsLoading(false);
		}
	};

	const handleReturnResult = async () => {
		try {
			let objectParams = {
				orderId: dichVuSelected.data?.orderId,
				resultReportingTime: true,
			};
			await returnResult(infoBnSelectedCdha?.id);
			const { data: dataTime } = await updateTimes(objectParams);
			if (dataTime?.code === CODE.SUCCESS) {
				handleGetInfoBn(infoBnSelectedCdha);
			}
			changeStatus();
		} catch (error) {
			console.error(error);
		}
	};

	const changeStatus = async () => {
		let { data } = await updateWfWorkflows(WfConstTracker.RISPACS, {
			objectId: infoBnSelectedCdha?.id || '',
			status: workFlowNewStatus,
		});
		if (data?.data) {
			doSearch(filter);
			setWorkFlowStatus(WORK_FLOW_STATUS_CDHA.DA_TRA_KQ.code);
			setForceRender(prev => !prev);
		}
	};

	const handleGetInfoBn = async (item: any, isShowModal?: boolean) => {
		setIsLoading(true);
		try {
			let { data } = await getInforBnCDHA(item?.id);
			let { data: dataPhieu } = await getInforPhieuCDHA(item?.id);
			if (CODE.SUCCESS === data?.code && dataPhieu?.code === CODE.SUCCESS) {
				let icdXPrimary = listICD10?.find(
					(item: any) => item?.code === dataPhieu?.data?.icdXPrimary
				);
				let dsDichVu = dataPhieu?.data?.orders?.map((item: any) => {
					return {
						...item?.term,
						additionalConfiguration: item?.additionalConfiguration,
						oldStatusId: item?.oldStatusId,
						termOrderId: item?.id,
						isCanceled: item?.isCanceled,
						statusCode: item?.statusCode,
						statusColor: item?.statusColor,
						orderId: item?.orderId,
						obs: item?.obs,
					};
				});
				isShowModal &&
					dataPhieu?.data?.executionStartTime &&
					!dataPhieu?.data?.resultReportingTime &&
					setDichVuSelected({
						...dichVuSelected,
						open: true, 
					});
				setInfoBnSelectedCDHA({
					...item,
					...data?.data,
					...dataPhieu?.data,
					icdXPrimaryName: icdXPrimary?.name,
				});
				setDsDichVu(dsDichVu);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const doSearch = (data: any) => {
		let dataSearch = {
			...filter,
			...data,
		};
		setFilter(dataSearch);
	};

	const handleGetListICD10 = async () => {
		try {
			let res = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
			setListICD10(res?.listICD10 || []);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		handleGetListICD10();
	}, []);

	const handlePressKeyShortcuts = (e: any) => {
		switch (e.key) {
			case KEY.F5:
				e.preventDefault();
				setKeyOpenPhieuIn('F5');
				break;
			default:
				return;
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', handlePressKeyShortcuts);
		return () => {
			window.removeEventListener('keydown', handlePressKeyShortcuts);
		};
	}, []);

	const PRINT_PARAMS = {
		visitId: infoBnSelectedCdha?.visit?.id,
		patientId: infoBnSelectedCdha?.patient?.id,
		orderGroupId: infoBnSelectedCdha?.orderGroupId,
		orderTypeId: ORDER_TYPE_ID.CDHA,
		risPacsGroupId: infoBnSelectedCdha?.id,
	};

	return (
		<>
			<div className="reception-list bg-gray">
				<Row className="phanHeVienPhi h-100">
					<div className="pe-0 dsBenhNhan spaces width-27_4 h-calc-vh-35 bg-white pt-6">
						<FilterSidebar
							title="Danh sách phiếu"
							formalList={LIST_FORMAL}
							onSearch={doSearch}
							listMenuItem={[]}
							handleSelectOption={() => {}}
						/>
						<div className="flex-1 spaces pt-10">
							<BangDsPhieuCDHA
								setInfoPhieuCDHA={handleGetInfoBn}
								statusSummary={statusSummary}
								setFilter={setFilter}
								filter={filter}
								statusChangedEvent={statusChangedEvent}
							/>
						</div>
					</div>
					<div className="pl-0 dsBenhNhan ml-pt-0_6 spaces border width-72 ">
						<div className="d-flex">
							<div className="spaces w-50 border bg-white">
								<h4 className="text-title fw-bold fs-4 spaces mb-0 py-5 px-10">
									Thông tin phiếu
								</h4>
							</div>
							<div className="spaces w-50 border bg-white">
								<h4 className="text-title fw-bold fs-4 spaces mb-0 py-5 px-10">
									Thông tin bệnh nhân
								</h4>
							</div>
						</div>
						<div className="d-flex spaces mb-0">
							<div className="spaces w-50 border bg-white spaces px-10 py-5">
								<div className="bg-white">
									<div className="d-flex spaces mb-5">
										<LabelRequired
											label="Mã phiếu:"
											className="spaces w-17 fw-bold"
										/>
										<div className="spaces flex-grow-1 w-83 text-visit-infor">
											{infoBnSelectedCdha?.code || '...'}
										</div>
									</div>
									<div className="d-flex spaces mb-5">
										<LabelRequired
											label="Chỉ định:"
											className="spaces w-17 fw-bold"
										/>
										<div className="spaces flex-grow-1 w-83 text-visit-infor text-truncate">
											{infoBnSelectedCdha?.indicationPerson
												? `${infoBnSelectedCdha?.indicationPerson}`
												: '...'}{' '}
											-{' '}
											{infoBnSelectedCdha?.indicationTime
												? formatDateTime(infoBnSelectedCdha?.indicationTime)
												: '...'}{' '}
											-{' '}
											{infoBnSelectedCdha?.deptIndicationDeptName
												? infoBnSelectedCdha?.deptIndicationDeptName
												: infoBnSelectedCdha?.visit?.visitObjectTypeCode ===
												  PATIENT_TYPE.KHAM_DOAN.code
												? 'Phòng khám sức khỏe'
												: '...'}
										</div>
									</div>
									<div className="d-flex spaces mb-5">
										<LabelRequired
											label="Thực hiện:"
											className="spaces w-17 fw-bold"
										/>
										<div className="spaces flex-grow-1 w-83 text-visit-infor text-truncate">
											{infoBnSelectedCdha?.executor || '...'} -{' '}
											{infoBnSelectedCdha?.executionStartTime
												? formatDateTime(
														infoBnSelectedCdha?.executionStartTime
												  )
												: '...'}{' '}
											- {infoBnSelectedCdha?.deptFulfillDeptName || '...'}
										</div>
									</div>
									<div className="d-flex spaces mb-5">
										<LabelRequired
											label="Kết quả:"
											className="spaces w-17 fw-bold text-danger"
										/>
										<div className="spaces flex-grow-1 w-83 text-danger text-truncate">
											{infoBnSelectedCdha?.resultReporter || '...'} -{' '}
											{infoBnSelectedCdha?.resultReportingTime
												? formatDateTime(
														infoBnSelectedCdha?.resultReportingTime
												  )
												: '...'}{' '}
											- {infoBnSelectedCdha?.deptResultReportingDept || '...'}
										</div>
									</div>
								</div>
							</div>
							<div className="spaces w-50 border bg-white spaces px-10 py-5">
								<div className="d-flex spaces mb-5">
									<LabelRequired
										label="Bệnh nhân:"
										className="spaces w-25 fw-bold"
									/>
									<div className="flex-grow-1 w-75 text-truncate">
										{infoBnSelectedCdha?.patient?.code || '...'} -{' '}
										{infoBnSelectedCdha?.patient?.personName || '...'} -{' '}
										{infoBnSelectedCdha?.patient?.gender || '...'} -{' '}
										{infoBnSelectedCdha?.patient?.dobDay 
											? `${infoBnSelectedCdha?.patient?.dobDay}/` 
											: ''}
										{infoBnSelectedCdha?.patient?.dobMonth 
											? `${infoBnSelectedCdha?.patient?.dobMonth}/` 
											: ''}
										{infoBnSelectedCdha?.patient?.dobYear 
											? `${infoBnSelectedCdha?.patient?.dobYear}` 
											: ''} -{' '}
										{infoBnSelectedCdha?.patient?.dobYear
											? calculateAge(
												infoBnSelectedCdha?.patient?.dobDay,
												infoBnSelectedCdha?.patient?.dobMonth,
												infoBnSelectedCdha?.patient?.dobYear
											)
											: '...'}
									</div>
								</div>
								<div className="d-flex spaces mb-6">
									<LabelRequired
										label="Mã vào khám:"
										className="spaces w-25 fw-bold"
									/>
									<div className="flex-grow-1 w-75 text-truncate">
										{infoBnSelectedCdha?.visit?.code || '...'}
									</div>
								</div>
								<div className="d-flex spaces mb-5">
									<LabelRequired
										label="Chẩn đoán:"
										className="spaces w-25 fw-bold"
									/>
									<div className="flex-grow-1 w-75 text-truncate">
										{infoBnSelectedCdha?.provisionalDiagnosisName || '...'}
									</div>
								</div>
							</div>
						</div>
						<div className="spaces bg-white">
							<BangDsDichVuCDHA
								dataDanhSachDVCdha={dsDichVu}
								infoBnSelectedCdha={infoBnSelectedCdha}
								setDsDichVu={setDsDichVu}
								handleDoubleClick={handleDoubleClick}
								isViewModal={isViewModal}
								handleGetInfoBn={handleGetInfoBn}
								setStatusChangedEvent={setStatusChangedEvent}
							/>
						</div>
						<div className="d-flex flex-start spaces px-12 py-16 gap-10 bg-white">
							{(infoBnSelectedCdha?.id && !infoBnSelectedCdha?.isCanceled) && (
								<WfButton
									tracker={WfConstTracker.RISPACS}
									objectId={infoBnSelectedCdha?.id || ''}
									additionalFunc={handleThucHienCDHA}
									forceRender={forceRender}
								></WfButton>
							)}
							{infoBnSelectedCdha?.visit?.id &&
								infoBnSelectedCdha?.patient?.id &&
								workFlowStatus === WORK_FLOW_STATUS_CDHA.DA_TRA_KQ.code && (
									<ButtonInPhieu<IInPhieuParams>
										className="spaces mx-3"
										label="In kết quả (F5)"
										fetchExport={inPhieuKetQuaCLS}
										params={PRINT_PARAMS}
										keyOpenPhieuIn={keyOpenPhieuIn}
										keyBtn={KEY.F5}
										setKeyOpenPhieuIn={setKeyOpenPhieuIn}
									/>
								)}
						</div>
					</div>
				</Row>
			</div>

			{dichVuSelected.open && (
				<ModalThucHienCDHA
					handleClose={() => {
						setDichVuSelected({
							open: false, 
							data: dichVuSelected.data
						});
					}}
					handleTraKetQua={() => {}}
					infoBenhNhan={infoBnSelectedCdha}
					isViewModal={isViewModal}
					handleSubmitForm={handleSubmitForm}
					selectedRow={dichVuSelected.data}
				/>
			)}
			{typeSave && (
				<FormInPhieuDialog<IInPhieuParams>
					show={typeSave}
					onHide={() => {
						setTypeSave(null);
					}}
					fetchExport={inPhieuKetQuaCLS}
					params={PRINT_PARAMS}
				/>
			)}
		</>
	);
};

export { PhanHeCDHAVaTDCN };
