import clsx from 'clsx';
import { ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { KEY } from '../../utils/Constant';

type Cell = {
	type?: 'string' | 'number' | 'all';
	name: string;
	length: number;
	required?: boolean;
};

type Props = {
	cells: Cell[];
	onChange: (stringValue: string, rawValue: any) => void;
	value: string;
	className?: string;
	inputClassName?: string;
	labelClassName?: string;
	label?: string;
	required?: boolean;
};

const InputCellGroup = ({
	cells,
	value,
	onChange,
	className,
	inputClassName,
	labelClassName,
	label,
	required,
}: Props) => {
	const [fieldValue, setFieldValue] = useState<any>({});
	const inputsRef = useRef<HTMLInputElement[]>([]);

	useEffect(() => {
		if (value) {
			const result: any = {};
			let cellIndex = cells[0].length;
			cells.forEach(cell => {
				result[cell.name] = value.slice(0, cellIndex);
				cellIndex = cell.length;
			});
			setFieldValue(result);
		}
	}, [value]);

	const onCellChange = (e: ChangeEvent<HTMLInputElement>, cell: Cell, index: number) => {
		const target = e.target as HTMLInputElement;
		const value = target.value;
		const isValidNextCell =
			target.nextSibling && index < cells.length - 1 && value.length === cell.length;
		const isValidBackCell = target.previousSibling && index > 0 && !value;
		const newValue = {
			...fieldValue,
			[target.name]: value,
		};

		setFieldValue(newValue);

		if (isValidNextCell) {
			nextCell(index);
		}

		if (isValidBackCell) {
			backCell(index);
		}
	};

	const nextCell = (index: number) => {
		inputsRef.current[index + 1].focus();
	};

	const backCell = (index: number) => {
		inputsRef.current[index - 1].focus();
	};

	const onCellKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
		const target = e.target as HTMLInputElement;

		switch (e.key) {
			case KEY.DELETE:
				setFieldValue({
					...fieldValue,
					[target.name]: '',
				});
				break;

			case KEY.ARROW_LEFT:
				if (index >= 0) {
					backCell(index);
				}
				break;

			case KEY.ARROW_RIGHT:
				if (index < cells.length - 1) {
					nextCell(index);
				}
				break;

			case KEY.BACKSPACE:
				if (!target.value && index > 0) {
					backCell(index);
				}
				break;

			default:
				break;
		}
	};

	return (
		<Form.Group className={clsx('d-flex oct-field-wrapper oct-otp-input', className)}>
			{label && (
				<Form.Label
					className={clsx(
						'spaces text-lable-input max-content-width mb-0 me-2 min-w-fit-content',
						labelClassName
					)}
					dangerouslySetInnerHTML={{
						__html: required
							? `${label}<strong class='text-danger ps-1'>(*)</strong>`
							: `${label}`,
					}}
				></Form.Label>
			)}
			<div className="d-flex align-items-end">
				{cells.map((cell: Cell, index: number) => (
					<Form.Control
						ref={(element: HTMLInputElement) => (inputsRef.current[index] = element)}
						key={cell.name}
						value={fieldValue[cell.name]}
						type={cell.type}
						maxLength={cell.length}
						style={{
							flex: cell.length,
						}}
						className={clsx(
							'spaces mr-4 p-6 h-28 text-center radius-4',
							inputClassName
						)}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							onCellChange(e, cell, index)
						}
						onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => onCellKeyDown(e, index)}
						onFocus={(e: FocusEvent<HTMLInputElement>) => {
							e.target.select();
						}}
					/>
				))}
			</div>
		</Form.Group>
	);
};

export default InputCellGroup;
