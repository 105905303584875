import React from 'react'
import { Col, Row } from 'react-bootstrap';
import LabelRequired from '../../../../component/LabelRequired';
import DanhSachPhieu from '../../../../component/DanhSachPhieu';
import { TableCollapseCustom } from '../../../../component/table/table-collapse/TableCollapseCustom';

type Props = {}

const ChuyenKhoa = (props: Props) => {

  const columnsDSChuyenKhoa = [
    {
      title: "Tên dịch vụ",
      field: "name",
      className: "spaces width-40",
      render: (rowData: any) => {
        return rowData?.isCancel ? (
          <div className="d-flex">
            <i
              className={`bi bi-ban icon-ban spaces min-w-15 mr-10`}
            ></i>
            <div className={`${rowData?.isCancel ? "text-danger" : ""}`}>{rowData?.name}</div>
          </div>
        ) : (
          <div className={`${rowData?.isCancel ? "text-danger" : ""}`}>{rowData?.name}</div>
        );
      }
    },
    {
      title: "SL",
      field: "quantity",
      className: "spaces width-10 text-center",
      render: (rowData: any) => {
        const DEFAULT_QUANTITY = rowData?.isCancel ? 0 : 1;
        return <div className={`${rowData?.isCancel ? "text-danger" : ""}`}>{DEFAULT_QUANTITY}</div>
      }
    },
    {
      title: "Kết quả",
      field: "ketQua",
      className: "spaces width-20",
    },
    { title: "Loại bệnh phẩm", field: "type", className: "spaces width-15" },
    {
      title: "Đối tượng",
      field: "doiTuong",
      className: "spaces width-15",
      render: (rowData: any) => {
        return <div className={`${rowData?.isCancel ? "text-danger" : ""}`}>{rowData?.objectTypeName || ""}</div>
      }
    }
  ];

  return (
    <Row className="h-100 spaces px-16 py-5">
      <div>
        <div className="pt-4 bg-white">
          <Row className="mx-0">
            <Col xs={4} className="d-flex align-items-center mb-3">
              <LabelRequired label="Mã phiếu" className="min-w-100px" />
              <span className="fw-light text-truncate">...</span>{" "}
            </Col>
            <Col
              xs={4}
              className="d-flex align-items-center text-lable-input mb-3"
            >
              <LabelRequired label="Ngày y lệnh" className="min-w-100px" />
              <span className="fw-light text-truncate">...</span>
            </Col>
            <Col
              xs={4}
              className="d-flex align-items-center text-lable-input mb-3"
            >
              <LabelRequired label="Người chỉ định" className="min-w-100px" />
              <span className="fw-light">...</span>
            </Col>
            <Col
              xs={4}
              className="d-flex align-items-center text-lable-input mb-3"
            >
              <LabelRequired label="Nơi chỉ định" className="min-w-100px" />
              <span className="fw-light text-truncate">...</span>
            </Col>
            <Col
              xs={8}
              className="d-flex align-items-center text-lable-input mb-3"
            >
              <LabelRequired label="Chẩn đoán" className="min-w-100px" />
              <span className="fw-light text-truncate">...</span>
            </Col>
          </Row>
        </div>
        <div className="bg-white spaces pt-2 mb-6">
          <DanhSachPhieu
            className="mb-2"
            dsPhieu={[]}
            isGetFirstData
          />
          <TableCollapseCustom
            columns={columnsDSChuyenKhoa}
            data={[]}
            childrenField="subs"
            className={`overflow-auto spaces h-calc-vh-465`}
          />
        </div>
      </div>
    </Row>
  );
}

export default ChuyenKhoa