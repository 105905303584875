import clsx from 'clsx';
import { ChangeEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { IBaseComponentProps } from '../types';

type Props = IBaseComponentProps & {
	inline?: boolean;
	mode?: 'single' | 'multiple';
	data?: any;
	checkboxLayout?: 'horizontal' | 'vertical';
	value?: any;
	onChange: (value: any) => void;
};

const CheckboxGroup = ({
	label,
	data,
	name,
	inline,
	labelClassName,
	containerClassName,
	mode = 'multiple',
	error,
	className,
	value,
	onChange,
	checkboxLayout = 'horizontal',
	...props
}: Props) => {
	const [fieldValue, setFieldValue] = useState<any[]>([]);

	useEffect(() => {
		setFieldValue(value || []);
	}, [value]);

	const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = e.target;

		if (mode === 'single') {
			setFieldValue(checked ? [value] : []);
			onChange(checked ? value : null);
			return;
		}

		const newValue = checked
			? [...fieldValue, value]
			: fieldValue.filter(item => item !== value);

		setFieldValue(newValue);
		onChange(newValue.length ? newValue : null);
	};

	return (
		<Form.Group
			className={clsx(containerClassName, {
				'd-flex align-items-center': inline,
			})}
		>
			{label && (
				<Form.Label
					className={clsx(
						'text-lable-input min-w-fit-content spaces me-2',
						labelClassName
					)}
				>
					{label}
				</Form.Label>
			)}
			<div
				className={clsx('position-relative w-100', {
					'd-flex align-items-center flex-wrap': checkboxLayout === 'horizontal',
					'flex-column': checkboxLayout === 'vertical',
				})}
				style={{
					rowGap: '1rem',
				}}
			>
				{Boolean(data?.length) &&
					data?.map((checkbox: any, index: number) => (
						<Form.Check
							key={checkbox.value}
							id={`${name}-checkbox-${index}`}
							inline={inline}
							label={checkbox.label}
							value={checkbox.value}
							checked={fieldValue.includes(checkbox.value)}
							onChange={onCheckboxChange}
							type="checkbox"
							{...props}
						/>
					))}
				<Form.Control.Feedback type="invalid" tooltip className="field-tooltip-error">
					{error}
				</Form.Control.Feedback>
			</div>
		</Form.Group>
	);
};

export default CheckboxGroup;
