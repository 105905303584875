import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import "./Buong.scss"

type Props = {
    name: string,
    used: number,
    total: number,
    onClick?: () => void
}

const Buong = ({
    name,
    used, total, onClick
}: Props) => {
    return (
        <div className='d-flex align-items-center spaces mb-10 py-5 px-10 card-buong'
            onClick={() => onClick?.()} 
        >
            <h6 className='spaces mb-0 min-w-100'>{name || ""}</h6>
            <ProgressBar variant='primary' now={(used / total) * 100} className='custom-progress'/>
            <h6 className='spaces mb-0 min-w-40 ml-20'>{`${used}/${total}` || ""}</h6>
        </div>
    )
}

export default Buong