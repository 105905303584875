import { Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import TextField from '../../component/TextField';
import { MESSAGE } from '../../utils/Constant';

type FormValues = {
	note: string;
};

type Props = {
	open: boolean;
	onClose: () => void;
	onSave: (value: FormValues) => void;
	itemEdit?: any;
};

const ModalGhiChu = ({ open, onClose, onSave, itemEdit }: Props) => {
	const validationSchema = Yup.object().shape({
		note: Yup.string().required(MESSAGE.REQUIRED).trim(),
	});

	const onSubmit = (values: FormValues) => {
		onSave(values);
	};

	return (
		<Modal show={open} centered className="dialog-background" onHide={onClose}>
			<Modal.Header closeButton className="modal-header">
				<Modal.Title className="text-transform-none">Ghi chú</Modal.Title>
			</Modal.Header>

			<Formik<FormValues>
				initialValues={{
					note: '',
					...itemEdit,
				}}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
			>
				{({ submitForm }) => (
					<Form>
						<Modal.Body className="spaces min-h-150">
							<TextField
								as="textarea"
								name="note"
								inputClassName="spaces min-h-100"
							/>
						</Modal.Body>

						<Modal.Footer className="d-flex justify-content-end">
							<Button className="btn-secondary" onClick={onClose}>
								Đóng
							</Button>
							<Button className="btn-fill" onClick={submitForm}>
								Lưu
							</Button>
						</Modal.Footer>
					</Form>
				)}
			</Formik>
		</Modal>
	);
};

export default ModalGhiChu;
