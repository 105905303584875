import React from 'react'
import { Button } from 'react-bootstrap'
import InfoPatientRight from '../../../phan-he-kham-benh/components/InfoPatientRight'
import { OctKTSVG } from '@oceantech/oceantech-ui'

type Props = {}

const ThaoTacNoiTru = (props: Props) => {
    return (
        <div className="d-flex justify-content-between border border-bottom-2">
            <div className="d-flex align-items-center spaces h-40">
                <Button
                    className="btn-fill btn-kham-benh min-w-100 spaces h-29 mx-3"
                    onClick={() => { }}
                >
                    <i className="bi bi-printer-fill"></i>
                    In ấn
                </Button>
                <Button
                    className="btn-fill btn-kham-benh min-w-100 spaces h-29 mx-3"
                    onClick={() => { }}
                >
                    <i className="bi bi-person-plus-fill"></i>
                    Nhập BN
                </Button>
                <Button
                    className="btn-fill btn-kham-benh min-w-100 spaces h-29 mx-3"
                    onClick={() => { }}
                >
                    <i className="bi bi-capsule"></i>
                    QL thuốc
                </Button>
                <Button
                    className="btn-fill btn-kham-benh min-w-100 spaces h-29 mx-3"
                    onClick={() => { }}
                >
                    <OctKTSVG path='/media/svg/cis/vat-tu.svg' />
                    QL vật tư
                </Button>
                <Button
                    className="btn-fill btn-kham-benh min-w-100 spaces h-29 mx-3"
                    onClick={() => { }}
                >
                    <OctKTSVG path='/media/svg/cis/ql-mau.svg' />
                    QL máu
                </Button>
                <Button
                    className="btn-fill btn-kham-benh min-w-100 spaces h-29 mx-3 ml-10"
                    onClick={() => { }}
                >
                    <i className="bi bi-arrow-right-square-fill"></i>
                    Chuyển phòng
                </Button>
                <Button
                    className="btn-fill btn-kham-benh min-w-100 spaces h-29 mx-3"
                    onClick={() => { }}
                >
                    <OctKTSVG path='/media/svg/cis/benh-an.svg' />
                    Bệnh án
                </Button>
                <Button
                    className="btn-fill btn-kham-benh min-w-100 spaces h-29 mx-3"
                    onClick={() => { }}
                >
                    <i className="bi bi-cash-coin"></i>
                    Thánh toán
                </Button>
            </div>
            <InfoPatientRight benhNhanInfo={{
                thongTinKhamBenh: {
                    patient: {
                        personName: 'Nguyễn Văn A',
                        code: '0000001',
                        gender: 'Nam',
                        dobDay: '01',
                        dobMonth: '01',
                        dobYear: '2000',
                        communeName: "Phường Ô Chợ Dừa",
                        districtName: "Quan 1",
                        provinceName: "TP HCM",
                    },
                    visit: {
                        visitObjectTypeName: 'BHYT',
                    }
                }
            }} />
        </div>
    )
}

export default ThaoTacNoiTru