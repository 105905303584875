import { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import CustomTabMenu from '../../../../component/CustomTabMenu'
import { DS_NOI_TRU_TIEP_DON } from '../../../../utils/Constant'
import { dsTabThongTinNoiTru } from '../../../const/PhanHeNoitruConst'

type Props = {}

const BenhNhan = (props: Props) => {
  const [activeTabNoiTru, setActiveTabNoiTru] = useState<string>("0");
  const [isExpand, setIsExpand] = useState(false);

  return (
    <>
      <div className='spaces px-16 py-5'>
        <Row>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Mã BN
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Mã HSBA
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="6">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Bệnh nhân
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>

          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Ngày sinh
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Thời điểm tới
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Loại đón tiếp
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Đối tượng
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Số vào viện:
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Số vào khoa:
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Mã điều trị:
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Nhóm máu:
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Lý do khám:
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Bệnh án:
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="6">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                TTPK:
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Ngày vào:
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Ngày ra:
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="3">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Số ngày ĐT:
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="6">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Chẩn đoán:
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
          <Col xs="6">
            <div className="spaces fw-5 mb-8 d-flex">
              <span className="d-inline-block spaces min-w-100 fw-bold">
                Xử trí:
              </span>
              <span className="spaces fw-4 text-truncate">...</span>
            </div>
          </Col>
        </Row>
        <Row>
          {isExpand && <>

            <Col xs="3">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold">
                  CMND/CCCD
                </span>
                <span className="spaces fw-4 text-truncate">...</span>
              </div>
            </Col>
            <Col xs="3">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold">
                  Dân tộc-QT
                </span>
                <span className="spaces fw-4 text-truncate">...</span>
              </div>
            </Col>
            <Col xs="3">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold">
                  Nghề nghiệp
                </span>
                <span className="spaces fw-4 text-truncate">...</span>
              </div>
            </Col>
            <Col xs="3">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold text-pri">
                  Người nhà
                </span>
                <span className="spaces fw-4 text-truncate">...</span>
              </div>
            </Col>
            <Col xs="6">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold">
                  TT liên lạc
                </span>
                <span className="spaces fw-4 text-truncate">...</span>
              </div>
            </Col>
            <Col xs="3">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold text-danger">
                  Số BHYT
                </span>
                <span className="spaces fw-4 text-truncate">...</span>
              </div>
            </Col>
            <Col xs="3">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold text-danger">
                  Hạn thẻ
                </span>
                <span className="spaces fw-4 text-truncate">...</span>
              </div>
            </Col>
            <Col xs="3">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100 fw-bold text-danger">
                  Tuyến BHYT
                </span>
                <span className="spaces fw-4 text-truncate">...</span>
              </div>
            </Col>
          </>}
          <Col xs="12" className="d-flex flex-center">
            <Button className="btn-outline" onClick={() => setIsExpand(!isExpand)}>
              <i className={`bi bi-arrow-${isExpand ? "up": "down"}-short`}></i>
              {isExpand ? "Thu gọn" : "Mở rộng"}
            </Button>
          </Col>
        </Row>
      </div>
      <div className="p-0">
        <CustomTabMenu
          danhsachTabs={dsTabThongTinNoiTru}
          keyDanhSachTabs={DS_NOI_TRU_TIEP_DON}
          activeTab={activeTabNoiTru}
          onTabChange={(activeTab: any) => setActiveTabNoiTru(activeTab)}
        />
      </div>
    </>
  )
}

export default BenhNhan