import React from 'react'

type Props = {}

const TTXacThuc = (props: Props) => {
  return (
    <div>TTXacThuc</div>
  )
}

export default TTXacThuc