import { FormikErrors, FormikTouched, useFormikContext } from 'formik';
import { FC, useContext, useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import TextField from '../../../component/TextField';
import ButtonInPhieu from '../../../component/button-in-phieu';
import { IDanhSachDanhMuc } from '../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel';
import {
	KEY_DS_DANH_MUC_TIEP_DON,
	ORDER_TYPE_ID,
} from '../../../utils/Constant';
import { getIndexedDBItem } from '../../../utils/IndexedDB';
import { trangThaiBenhNhan } from '../../constants/BenhNhanConst';
import { ColunmDanhSachMauKhamBenh } from '../../constants/Columns';
import { constTypeBenhKemTheo, initialValuesObs } from '../../constants/KhamBenh';
import { PhanHeTiepDonContext } from '../../contexts/PhanHeTiepDonContext';
import MauKhamBenhButton from '../../modals/modal-ke-thuoc/components/DonThuocMauButton';
import { KetQuaDichVu, MauKhamBenh } from '../../models/ThongTinKhamBenhModel';
import { IDonThuocMauRequest } from '../../models/ThuocModels';
import { inPhieuKhamBenh, inToDieuTri } from '../../services/PhanHeTiepDonServer';
import ICD10 from '../ICD10';
import ModalBenhKemTheo from '../ModalBenhKemTheo';
import ModalDanhSachLanKham from '../modal-thong-tin-kham-benh/ModalDanhSachLanKham';
import ModalTrieuChung from '../modal-thong-tin-kham-benh/ModalTrieuChung';
import ModalTuyChonBaoCao from '../modal-thong-tin-kham-benh/ModalTuyChonBaoCao';
import SinhHieu from './SinhHieu';
import ModalChonKetQuaDichVu from './modal-chon-ket-qua-dich-vu/ModalChonKetQuaDichVu';
import ProvisonalDiagnosis from '../ProvisonalDiagnosis';

interface Props {
	hideFooter?: true;
}

export const PhieuKhamBenh: FC<Props> = ({ hideFooter }) => {
	const [openModalChonKetQuaDichVu, setOpenModalChonKetQuaDichVu] = useState<boolean>(false);
	const [ketQuaCLS, setKetQuaCLS] = useState<KetQuaDichVu[]>([]);
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const [openModalTuyChonBaoCao, setOpenModalTuyChonBaoCao] = useState<boolean>(false);
	const [mauSelected, setMauSelected] = useState<MauKhamBenh | null>(null);
	useState<boolean>(false);
	const [openModalDanhSachLanKham, setOpenModalDanhSachLanKham] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<any>({});
	const [shouldOpenModalICD10, setShouldOpenModalICD10] = useState<boolean>(false);
	const [shouldOpenModalSelectICD10, setShouldOpenModalSelectICD10] = useState<boolean>(false);
	const [selectionModeICD10, setSelectionModeICD10] = useState<'checkbox' | 'radio'>('radio');
	const [listICD10, setListICD10] = useState<any>([]);
	const [listComorbidity, setListComorbidity] = useState<any[]>([]);

	let { values, setFieldValue, resetForm, setValues, errors, touched } = useFormikContext<any>();
	let encounterDiagnosisValues = values?.encounterDiagnosis;
	let encounterDiagnosisErrors = errors?.encounterDiagnosis as FormikErrors<any>;
	let encounterDiagnosisTouched = touched?.encounterDiagnosis as FormikTouched<any>;
	let obsValues = values?.obs;
	let obsErrors = errors?.obs as FormikErrors<any>;
	let obsTouched = touched?.obs as FormikTouched<any>;

	const isDisableField: boolean = !benhNhanInfo?.thongTinKhamBenh?.isExamined
		|| benhNhanInfo?.thongTinKhamBenh?.isConclusion;

	const handleChangeEncounterDiagnosis = (name: string, value: any) => {
		setFieldValue('encounterDiagnosis', {
			...encounterDiagnosisValues,
			[name]: value,
		});
	};

	const handleChangeObs = (name: string, value: any) => {
		setFieldValue('obs', {
			...obsValues,
			[name]: value,
		});
	};

	useEffect(() => {
		mauSelected?.id && setValues({ ...mauSelected });
	}, [mauSelected]);

	useEffect(() => {
		setFieldValue('encounterDiagnosis', {
			...encounterDiagnosisValues,
			paraclinicalSummary: ketQuaCLS
				.map(
					item =>
						`${item.tenDichVu} ${item.ketQua !== null ? '(' + item.ketQua + ')' : ''}`
				)
				.join(', '),
		});
	}, [ketQuaCLS]);

	useEffect(() => {
		let encounterDiagnosis = benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis || {};
		let obs = benhNhanInfo?.thongTinKhamBenh?.obs || initialValuesObs;
		let visit = benhNhanInfo?.thongTinKhamBenh?.visit;

		if (benhNhanInfo?.thongTinKhamBenh?.orderId) {
			resetForm();
			setValues({
				encounterDiagnosis: {
					...encounterDiagnosis,
					reasonForEncounter:
						encounterDiagnosis?.reasonForEncounter || visit?.visitReasonName || '',
				},
				...(obs && { obs }),
			});
		} else {
			resetForm();
		}
	}, [benhNhanInfo?.thongTinKhamBenh]);

	const handleGetListICD10 = async () => {
		try {
			let res: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
			setListICD10(res?.listICD10 || []);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		handleGetListICD10();
	}, []);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
		const value = (event.target as HTMLInputElement).value.toUpperCase();
		setFieldValue('encounterDiagnosis', {
			...encounterDiagnosisValues,
			provisionalDiagnosis: value,
		});
	};

	const handleOpenSelectICD10 = () => {
		setShouldOpenModalSelectICD10(true);
	};

	const getMauKhamParams = () => {
		const {
			reasonForEncounter,
			pathologicalProcess,
			medicalHistoryPast,
			medicalHistoryFamily,
			physicalExamGeneral,
			physicalExamOfBody,
			provisionalDiagnosis,
			provisionalDiagnosisName,
			comorbidity,
			currentMedications,
		} = values.encounterDiagnosis ?? {};

		const templateParams = {
			encounterDiagnosis: {
				reasonForEncounter,
				pathologicalProcess,
				medicalHistoryPast,
				medicalHistoryFamily,
				physicalExamGeneral,
				physicalExamOfBody,
				provisionalDiagnosis,
				provisionalDiagnosisName,
				comorbidity,
				currentMedications,
			},
			obs: values.obs,
		};

		return {
			orderTemplate: JSON.stringify(templateParams),
			lstOrderSetMemebers: [],
		};
	};

	const onSelectMauKham = async (data: IDonThuocMauRequest) => {
		const templateValue = JSON.parse(data.orderTemplate);
		setValues({
			...values,
			encounterDiagnosis: {
				...values.encounterDiagnosis,
				...templateValue.encounterDiagnosis,
			},
			obs: templateValue.obs,
		});
	};

	const handleChangeICD10 = (data: any) => {
		setFieldValue('encounterDiagnosis', {
			...encounterDiagnosisValues,
			...data,
		});
	};

	return (
		<div className="modelKhamBenh spaces h-calc-vh-297">
			<div className={`thongTinKhamBenh ${hideFooter ? 'ps-4' : 'ps-0'}`}>
				<Row>
					<Row className="spaces ml-8">
						<h4 className="text-title fw-bold fs-5 mb-0">
							I. Lý do vào viện
						</h4>
					</Row>
					<Row className="m-8 spaces pe-0 align-items-center">
						<TextField
							as="textarea"
							className="resize-none"
							rows={3}
							name="reasonForEncounter"
							value={encounterDiagnosisValues?.reasonForEncounter || ''}
							onChange={(e: any) =>
								handleChangeEncounterDiagnosis('reasonForEncounter', e.target.value)
							}
							readOnlyText={benhNhanInfo?.isView}
							disabled={isDisableField}
							touched={encounterDiagnosisTouched?.reasonForEncounter}
							errors={encounterDiagnosisErrors?.reasonForEncounter}
						/>
					</Row>
					<Row className="spaces ml-8">
						<h4 className="text-title fw-bold fs-5 mb-0">II. Hỏi bệnh</h4>
					</Row>
					<Row className="spaces ml-10 mt-5">
						<h4 className="fw-bold fs-6 mb-0">1. Quá trình bệnh lý</h4>
					</Row>
					<Row className="m-8 spaces pe-0 align-items-center">
						<TextField
							name="pathologicalProcess"
							as="textarea"
							className="resize-none"
							rows={2}
							value={encounterDiagnosisValues?.pathologicalProcess || ''}
							onChange={(e: any) =>
								handleChangeEncounterDiagnosis(e.target.name, e.target.value)
							}
							readOnlyText={benhNhanInfo?.isView}
							disabled={isDisableField}
						/>
					</Row>
					<Row className="spaces ml-10 mt-5">
						<h4 className="fw-bold fs-6 mb-0">2. Tiền sử bệnh</h4>
					</Row>
					<Row className="spaces ml-10 mt-10">
						<p className="fs-6 mb-0">
							&#8226; Bản thân (Phát triển thể lực từ nhỏ đến lớn, những bệnh đã mắc,
							phương pháp ĐT, tiêm phòng, ăn uống, sinh hóa, vv...)
						</p>
					</Row>
					<Row className="m-8 spaces pe-0 align-items-center">
						<TextField
							name="medicalHistoryPast"
							as="textarea"
							className="resize-none"
							rows={2}
							value={encounterDiagnosisValues?.medicalHistoryPast || ''}
							onChange={(e: any) =>
								handleChangeEncounterDiagnosis(e.target.name, e.target.value)
							}
							readOnlyText={benhNhanInfo?.isView}
							disabled={isDisableField}
							touched={encounterDiagnosisTouched?.medicalHistoryPast}
							errors={encounterDiagnosisErrors?.medicalHistoryPast}
						/>
					</Row>
					<Row className="spaces ml-10 mt-10">
						<p className="fs-6 mb-0">
							&#8226; Gia đình (Những người trong gia đình: Bệnh đã mắc, đời sống,
							tinh thần, vật chất, vv...)
						</p>
					</Row>
					<Row className="m-8 spaces pe-0 align-items-center">
						<TextField
							name="medicalHistoryFamily"
							as="textarea"
							className="resize-none"
							rows={2}
							value={encounterDiagnosisValues?.medicalHistoryFamily || ''}
							onChange={(e: any) =>
								handleChangeEncounterDiagnosis(e.target.name, e.target.value)
							}
							readOnlyText={benhNhanInfo?.isView}
							disabled={isDisableField}
						/>
					</Row>

					<Row className="spaces ml-8">
						<h4 className="text-title fw-bold fs-5 mb-0">III. Khám bệnh</h4>
					</Row>
					<Row className="spaces ml-10 mt-5">
						<h4 className="fw-bold fs-6 mb-0">
							1. Toàn thân (Ý thức, da niêm mạc, hệ thống hạch, tuyến giáp, vị trí,
							kích thước, số lượng, di động, vv...)
						</h4>
					</Row>
					<Row className="m-8 spaces pe-0 align-items-center">
						<TextField
							as="textarea"
							className="resize-none"
							rows={2}
							name="physicalExamGeneral"
							value={encounterDiagnosisValues?.physicalExamGeneral || ''}
							onChange={(e: any) =>
								handleChangeEncounterDiagnosis(e.target.name, e.target.value)
							}
							readOnlyText={benhNhanInfo?.isView}
							disabled={isDisableField}
						/>
					</Row>
					<Row className="mt-2 m-8 spaces pe-0 align-items-center">
						<SinhHieu
							sinhHieu={obsValues}
							setSinhHieu={handleChangeObs}
							error={obsErrors}
							touched={obsTouched}
							disabled={isDisableField}
						/>
					</Row>
					<Row className="spaces ml-10 mt-5">
						<h4 className="fw-bold fs-6 mb-0">2. Các bộ phận</h4>
					</Row>
					<Row className="m-8 spaces pe-0 align-items-center">
						<TextField
							name="physicalExamOfBody"
							as="textarea"
							className="resize-none"
							rows={2}
							value={encounterDiagnosisValues?.physicalExamOfBody || ''}
							onChange={(e: any) =>
								handleChangeEncounterDiagnosis(e.target.name, e.target.value)
							}
							readOnlyText={benhNhanInfo?.isView}
							disabled={isDisableField}
						/>
					</Row>

					<div className="spaces ml-10 mt-5 mb-n3 d-flex flex-row justify-content-between ps-6">
						<h4 className="fw-bold fs-6">3. Tóm tắt kết quả cận lâm sàng</h4>

						<Button
							className="bg-white"
							onClick={() => {
								benhNhanInfo?.thongTinKhamBenh?.isExamined &&
									setOpenModalChonKetQuaDichVu(true);
							}}
							disabled={isDisableField}
						>
							<u className="fw-bold text-pri fs-13px">Chọn dịch vụ</u>
						</Button>
					</div>
					<Row className="m-8 spaces pe-0 align-items-center position-relative">
						<TextField
							className="flex-auto h-50 resize-none"
							name="paraclinicalSummary"
							as="textarea"
							rows={2}
							value={encounterDiagnosisValues?.paraclinicalSummary || ''}
							onChange={(e: any) =>
								handleChangeEncounterDiagnosis(e.target.name, e.target.value)
							}
							readOnlyText={benhNhanInfo?.isView}
							disabled={isDisableField}
						/>
					</Row>
					<div className="spaces ml-10 d-flex flex-row justify-content-between ps-6">
						<h4 className="fw-bold fs-6 mb-0">4. Chẩn đoán ban đầu
							<span className="text-danger"> (*)</span>
						</h4>
						<Button
							className="bg-white"
							onClick={handleOpenSelectICD10}
							disabled={isDisableField}
						>
							<u className="fw-bold text-pri fs-13px">Chọn bệnh</u>
						</Button>
					</div>
					<Row className="m-8 spaces pe-0 align-items-center position-relative">
						<ProvisonalDiagnosis 
							encounterDiagnosisValues={encounterDiagnosisValues} 
							encounterDiagnosisErrors={encounterDiagnosisErrors} 
							encounterDiagnosisTouched={encounterDiagnosisTouched}
							benhNhanInfo={benhNhanInfo}
							listComorbidity={listComorbidity}
							setListComorbidity={setListComorbidity}
							onChange={handleChangeICD10}
							handleChangeEncounterDiagnosis={handleChangeEncounterDiagnosis}
							disabled={isDisableField}
						/>
					</Row>
					<div className="spaces ml-10 mt-5 d-flex flex-row justify-content-between ps-6">
						<h4 className="fw-bold fs-6 mb-0">5. Bệnh kèm theo (nếu có)</h4>
					</div>
					<Row className="m-8 spaces pe-0 align-items-center">
						<TextField
							name="comorbidity"
							as="textarea"
							className="resize-none"
							rows={2}
							value={encounterDiagnosisValues?.comorbidity || ''}
							onChange={(e: any) =>
								handleChangeEncounterDiagnosis(e.target.name, e.target.value)
							}
							disabled={isDisableField}
						/>
					</Row>
					<Row className="spaces ml-10 mt-5">
						<h4 className="fw-bold fs-6 mb-0">6. Thuốc đang dùng</h4>
					</Row>
					<Row className="m-8 spaces pe-0 align-items-center">
						<TextField
							name="currentMedications"
							as="textarea"
							className="resize-none"
							rows={2}
							value={encounterDiagnosisValues?.currentMedications || ''}
							onChange={(e: any) =>
								handleChangeEncounterDiagnosis(e.target.name, e.target.value)
							}
							readOnlyText={benhNhanInfo?.isView}
							disabled={isDisableField}
						/>
					</Row>
					<Row className="spaces ml-10 mt-5">
						<h4 className="fw-bold fs-6 mb-0">7. Quá trình khám bệnh</h4>
					</Row>
					<Row className="m-8 spaces pe-0 align-items-center">
						<TextField
							name="medicalExamination"
							as="textarea"
							className="resize-none"
							rows={2}
							value={encounterDiagnosisValues?.medicalExamination || ''}
							onChange={(e: any) =>
								handleChangeEncounterDiagnosis(e.target.name, e.target.value)
							}
							readOnlyText={benhNhanInfo?.isView}
							disabled={isDisableField}
						/>
					</Row>
					<Row className="spaces ml-10 mt-5">
						<h4 className="fw-bold fs-6 mb-0">8. Kết luận</h4>
					</Row>
					<Row className="m-8 spaces pe-0 align-items-center">
						<TextField
							name="diagnosisConclusion"
							as="textarea"
							className="resize-none"
							rows={2}
							value={encounterDiagnosisValues?.diagnosisConclusion || ''}
							onChange={(e: any) =>
								handleChangeEncounterDiagnosis(e.target.name, e.target.value)
							}
							readOnlyText={benhNhanInfo?.isView}
							disabled={isDisableField}
						/>
					</Row>
				</Row>
			</div>
			{!hideFooter && (
				<div className="flex justify-content-between spaces ml-7">
					<div className="flex justify-content-between gap-4 pt-3 pb-2 btn-luu">
						{!benhNhanInfo?.thongTinKhamBenh?.isConclusion && (
							<Button
								className="btn-fill spaces min-w-80px h-29"
								type="submit"
								disabled={
									benhNhanInfo?.trangThai ===
										trangThaiBenhNhan.ketThucKham.code ||
									isDisableField
								}
								// onClick={() => handleSubmit()}
							>
								<span>Lưu</span>
							</Button>
						)}
						{benhNhanInfo?.thongTinKhamBenh?.orderId && <>
							<ButtonInPhieu
								label="Tờ điều trị"
								className="min-w-100px"
								fetchExport={inToDieuTri}
								params={{
									patientId: benhNhanInfo?.thongTinKhamBenh?.patient?.id,
									visitId: benhNhanInfo?.thongTinKhamBenh?.visit?.id,
									encounterId: benhNhanInfo?.thongTinKhamBenh?.encounter?.id,
									orderId: benhNhanInfo?.thongTinKhamBenh?.orderId,
								}}
							/>
							<ButtonInPhieu
								label="In phiếu khám bệnh"
								className="min-w-100px"
								fetchExport={inPhieuKhamBenh}
								params={{
									orderId: benhNhanInfo?.thongTinKhamBenh?.orderId,
								}}
							/>
						</>}
						
						{!isDisableField && 
							<MauKhamBenhButton
								label="Mẫu khám bệnh"
								getSaveParams={getMauKhamParams}
								onSelectMau={onSelectMauKham}
								orderType={parseInt(ORDER_TYPE_ID.CONG_KHAM)}
								drop="up"
								tableTitle="Danh sách mẫu khám bệnh hỏi bệnh"
								columns={ColunmDanhSachMauKhamBenh}
								getRowData={(row: any) => {
									const formValue = JSON.parse(row.orderTemplate);
									return {
										...row,
										...formValue?.encounterDiagnosis,
									};
								}}
							/>
						}
						{/* <CustomDropdownButton
							drop="up"
							listMenuItems={Array.from({ length: 3 }, (_, i) => ({
								title: '05/07/2024',
								desc: 'Q3 - Khám tai-mũi-họng định kì',
							}))}
							label="Đợt khám gần nhất"
							primaryItemLabel="Danh sách lần khám cũ"
							className="spaces W-200"
							menuItemsClassName="spaces W-200"
							onClick={() => setOpenModalDanhSachLanKham(true)}
						/> */}
					</div>
					{/* <FormCheck label="Bệnh nhân cấp sổ khám mới" className="ms-5" /> */}
				</div>
			)}

			{openModalTuyChonBaoCao && (
				<ModalTuyChonBaoCao handleClose={() => setOpenModalTuyChonBaoCao(false)} />
			)}

			{openModalDanhSachLanKham && (
				<ModalDanhSachLanKham
					setMauSelected={setMauSelected}
					handleClose={() => setOpenModalDanhSachLanKham(false)}
				/>
			)}

			{openModalChonKetQuaDichVu && (
				<ModalChonKetQuaDichVu
					open={openModalChonKetQuaDichVu}
					handleClose={() => setOpenModalChonKetQuaDichVu(false)}
					onSave={(selectedData: KetQuaDichVu[]) => setKetQuaCLS(selectedData)}
					visitId={benhNhanInfo.thongTinKhamBenh.visit.id}
				/>
			)}

			{openModal.benhKemTheo && (
				<ModalBenhKemTheo
					type={constTypeBenhKemTheo.ICD10.code}
					setListBenhKemTheo={(item: string) =>
						setFieldValue(constTypeBenhKemTheo.ICD10.code, item)
					}
					handleClose={() => setOpenModal({})}
				/>
			)}

			{openModal.trieuChung && (
				<ModalTrieuChung
					setListTrieuChung={(item: string) => setFieldValue('trieuChung', item)}
					handleClose={() => setOpenModal({})}
				/>
			)}

			<ICD10
				shouldOpenModalICD10={shouldOpenModalICD10}
				setShouldOpenModalICD10={setShouldOpenModalICD10}
				shouldOpenModalSelectICD10={shouldOpenModalSelectICD10}
				setShouldOpenModalSelectICD10={setShouldOpenModalSelectICD10}
				listICD10={listICD10}
				listComorbidity={listComorbidity}
				setListComorbidity={setListComorbidity}
				selectionModeICD10={selectionModeICD10}
				data={encounterDiagnosisValues}
				onChange={handleChangeICD10}
			/>
		</div>
	);
};

export default PhieuKhamBenh;
