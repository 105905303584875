import Fuse from 'fuse.js';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { RowMouseEventHandlerParams } from 'react-virtualized';
import { useDebounce } from '../../../../../../../_metronic/helpers';
import InputSearch from '../../../../../component/InputSearch';
import SimpleTable from '../../../../../component/table/simple-table/SimpleTable';
import { CODE_DOI_TUONG } from '../../../../../phan-he-tiep-nhan-thanh-toan/constants/PhanHeTiepNhan';
import { formatMoney } from '../../../../../utils/FormatUtils';
import { columnsKeVatTu } from '../../../../columns/modal-ke-vat-tu/ColumnModalKeVatTu';
import { LOAI_VAT_TU } from '../../../../constants/VatTu';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import { getListVatTu } from '../../common';
import { useKeVatTuContext } from '../../context';
import { useTableSelectVatTuContext } from '../../context/TableSelectVatTuContext';
import TableSelectVatTuRowEdit from './TableSelectVatTuRowEdit';
import { KHO_NHA_THUOC_ID } from '../../../../constants/Thuoc';

const TableSelectVatTu = () => {
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const { treeNodeValue, setVatTuChecked, vatTuChecked, treeValue } = useKeVatTuContext();
	const { selectedRow, setSelectedRow, editRowIndex, setEditRowIndex } =
		useTableSelectVatTuContext();

	const tableDataRef = useRef<any[]>([]);
	const [tableData, setTableData] = useState<object[]>([]);
	const [query, setQuery] = useState<string>('');

	const { visit } = benhNhanInfo.thongTinKhamBenh;
	const { BAO_HIEM, VIEN_PHI, YEU_CAU } = CODE_DOI_TUONG;
	const queryDebounce = useDebounce(query.trim(), 700);

	useEffect(() => {
		setTableData(treeNodeValue);
		tableDataRef.current = treeNodeValue;
		setSelectedRow(null);
	}, [treeNodeValue]);

	useEffect(() => {
		if (queryDebounce) {
			onSearchVatTu(queryDebounce);
		}
	}, [queryDebounce]);

	const onSearchVatTu = async (query: string) => {
		const result = await getListVatTu({
			materialType: tableDataRef.current?.[0]?.materialType,
			warehouseId: treeValue.id,
			materialGroup: LOAI_VAT_TU.VAT_TU,
			keyword: query,
		});
		setTableData(result ?? []);
	};

	const onSearch = async (e: ChangeEvent<HTMLInputElement>) => {
		const keySearch = e.target.value;
		setQuery(keySearch);

		if (!keySearch) {
			setTableData(tableDataRef.current);
			return;
		}
	};

	const renderCell = (columnData: any, dataKey: string, rowData: any) => {
		rowData = {
			...rowData,
			tonKho: formatMoney(rowData?.tonKho) || 0,
			giaBhyt: formatMoney(rowData?.giaBhyt) || 0,
			giaVienPhi: formatMoney(rowData?.giaVienPhi) || 0,
			giaDichVu: formatMoney(rowData?.giaDichVu) || 0,
		};
		if (typeof rowData.get === 'function') {
			return rowData.get(dataKey);
		} else {
			return rowData[dataKey];
		}
	};

	const getDonGiaTheoLoaiDoiTuong = (rowData: any) => {
		const MAP_DON_GIA_LOAI_DOI_TUONG = {
			[BAO_HIEM]: 'giaBhyt',
			[VIEN_PHI]: 'giaVienPhi',
			[YEU_CAU]: 'giaDichVu',
		};
		return rowData[MAP_DON_GIA_LOAI_DOI_TUONG[visit.visitObjectTypeCode]];
	};

	const onRowSelect = (rows: any[]) => {
		const rowData = rows[0];
		const isExistVatTu = vatTuChecked.some(
			row => row.idVatTu?.toString() === rowData.id?.toString()
		);
		const isCurrentVatTu =
			vatTuChecked[editRowIndex || 0]?.idVatTu?.toString() === rowData?.id?.toString();
		if (editRowIndex !== null && isCurrentVatTu) {
			setSelectedRow(rowData);
		} else if (isExistVatTu) {
			toast.warn('Vật tư đã được kê trong phiếu chỉ định');
			return;
		} else {
			setSelectedRow(rowData);
		}
	};

	const onSaveSelectedRow = (values: any) => {
		const isKhoNhaThuoc = KHO_NHA_THUOC_ID === String(treeValue.id);
		const donGiaVatTu =
			(isKhoNhaThuoc ? values.giaBan : getDonGiaTheoLoaiDoiTuong(values)) || values.donGia;
		let listTemp = [...vatTuChecked];

		const additonalRowData = {
			...values,
			donGia: donGiaVatTu,
			thanhTien: donGiaVatTu * values.soLuong,
			doiTuong: values.doiTuong || visit?.visitObjectTypeName,
		};

		if (editRowIndex !== null) {
			listTemp = vatTuChecked.map((row: any) => {
				if (row.index === editRowIndex) {
					return {
						idVatTu: values.id,
						...additonalRowData,
					};
				}
				return row;
			});
			setEditRowIndex(null);
		} else {
			listTemp = [
				...listTemp,
				{
					idVatTu: values.id,
					...additonalRowData,
				},
			];
		}

		setVatTuChecked(listTemp.map((row, index) => ({ ...row, index })));
		setSelectedRow(null);
	};

	return (
		<>
			<div className="bg-white">
				<div className="px-3 py-1">
					<TableSelectVatTuRowEdit data={selectedRow} onSave={onSaveSelectedRow} />
				</div>
				<div className="px-10 py-5 h-35 spaces">
					<InputSearch
						placeholder="Tìm kiếm"
						handleChange={onSearch}
						className="spaces h-25 pr-4 radius-3"
					/>
				</div>
				<div className="min-h-180 spaces bg-white mb-6">
					<SimpleTable
						data={tableData}
						columns={columnsKeVatTu}
						height={200}
						width={1000}
						scrollable={true}
						setDataChecked={onRowSelect}
						handleRenderCell={renderCell}
						dataChecked={selectedRow ? [selectedRow] : []}
						onRowClick={({ rowData }: RowMouseEventHandlerParams) => {
							onRowSelect([rowData]);
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default TableSelectVatTu;
