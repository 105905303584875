import React from 'react'

type Props = {}

const TaiLieu = (props: Props) => {
  return (
      <div className='spaces px-16 py-5'>Tài liệu</div>
  )
}

export default TaiLieu