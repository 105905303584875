import { FunctionComponent, memo, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { getWfWorkflows, updateWfWorkflows } from "../services/WfWorkflowsService";
import { CODE, RESPONSE_MESSAGE } from "../../utils/Constant";
import { toast } from "react-toastify";
import { WfTrackerField, WfWorkflowsReq, WfWorkflowsRes } from "../models/WfWorkflows";
import wfAxiosGenerator from "../utils/WfAxiosGenerator";
import { localStorageItem } from '../../utils/LocalStorage'
import { KEY_LOCALSTORAGE } from '../../auth/core/_consts'
import { convertTrackerFieldResponseToObject } from '../utils';
import { ConfirmDialog } from "../../component/ConfirmDialog";
import SpinnerOverlay from "../../component/loading/SpinnerOverlay";
interface WfAction {
  componentName: string,
  nameReq: string,
  cancel?: () => void
  submit: () => void,
}
interface WfProps {
  tracker: string;
  objectId: string;
  additionalFunc?: (currStatusId?: string | undefined, codeButton?: string | undefined) => void;
  handleClick?: (props: WfAction) => void;
  additionalProps?: any;
  forceRender?: boolean;
  prefixFunction?: (
    currentStatusCode: string,
    wfCallbackFn: () => void | Promise<any>
  ) => void | Promise<any>;
  getTrackerField?: (fields: any) => void;
  additonalButtonParams?: any;
}

const BASE_ENDPOINT = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL || "";

const WfButton: FunctionComponent<WfProps> = memo((wfProps: WfProps) => {
  const [statusCode, setStatusCode] = useState<any>();
  const [config, setConfig] = useState<any>();
  const [newStatusName, setNewStateName] = useState<string | undefined>();
  const [oldStatusName, setOldStatusName] = useState<string | undefined>();
  const [wfWorkflowsReqNew, setWfWorkflowsReqNew] = useState<WfWorkflowsReq>();
  const [wfWorkflowsReqOld, setWfWorkflowsReqOld] = useState<WfWorkflowsReq>();
  const [wfAxiosRequests, setWfAxiosRequests] = useState<any>();
  const [notification, setNotification] = useState<string>("")
  const [loadingButton, setLoadingButton] = useState<{ new: boolean, old: boolean }>({ new: false, old: false });
  const [loadingAdditionalButton, setLoadingAdditionalButton] = useState<{ [key: string]: boolean }>({});

  const showLoadingButton = (type: "new" | "old") => setLoadingButton(current => ({ ...current, [type]: true }));
  const hideLoadingButton = (type: "new" | "old") => setLoadingButton(current => ({ ...current, [type]: false }));
  const showLoadingAdditionalButton = (code: string) => setLoadingAdditionalButton(current => ({ ...current, [code]: true }));
  const hideLoadingAdditionalButton = (code: string) => setLoadingAdditionalButton(current => ({ ...current, [code]: false }));

  const updatePageData = useMemo(() => async () => {
    try {
      let { data } = await getWfWorkflows(wfProps.tracker, wfProps.objectId);
      if (data?.code === CODE.SUCCESS) {
        updateStates(data.data);
        wfProps.getTrackerField && wfProps.getTrackerField(
          convertTrackerFieldResponseToObject(data.data.wfTrackerFieldRestDtoList)
        );
      }
    } catch (e) {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    }
  }, [wfProps.tracker, wfProps.objectId]);

  useEffect(() => {
    updatePageData();
  }, [wfProps.tracker, wfProps.objectId, wfProps?.forceRender, updatePageData]);

  const changeStatus = async (wfWorkflowsReq: WfWorkflowsReq | undefined, codeRequest?: string) => {
    if (!wfWorkflowsReq) return;
    try {
      let { data } = await updateWfWorkflows(wfProps.tracker, wfWorkflowsReq);
      data?.data && updateStates(data?.data);
      if (wfProps.additionalFunc) wfProps.additionalFunc(codeRequest);
    } catch (error) {
      console.error(error);
      toast.error(RESPONSE_MESSAGE.ERROR);
    }
  };

  const updateStates = (wfWorkflowsRes: WfWorkflowsRes) => {
    wfWorkflowsRes.additionalConfiguration && setConfig(JSON.parse(wfWorkflowsRes.additionalConfiguration));
    setStatusCode({ old: wfWorkflowsRes.oldStatusCode, curr: wfWorkflowsRes.currStatusCode, new: wfWorkflowsRes.newStatusCode });
    setNewStateName(wfWorkflowsRes.newStatusName);
    setOldStatusName(wfWorkflowsRes.oldStatusName);
    setWfWorkflowsReqNew({ objectId: wfProps.objectId, status: wfWorkflowsRes.newStatusId })
    setWfWorkflowsReqOld({ objectId: wfProps.objectId, status: wfWorkflowsRes.oldStatusId })
    setWfAxiosRequests(wfAxiosGenerator(BASE_ENDPOINT, wfWorkflowsRes.additionalConfiguration));
  }

  const handleButtonClick = async (additionalButton: any) => {
    if (loadingAdditionalButton?.[additionalButton.code]) return;
    showLoadingAdditionalButton(additionalButton.code);

    try {
      const submit = () => {
        additionalButton.handleClick(wfProps);
        wfProps.additionalFunc?.(statusCode.curr, additionalButton.code);
        hideLoadingAdditionalButton(additionalButton.code);
      };

      if (additionalButton?.componentName && wfProps.handleClick) {
        wfProps.handleClick({
          componentName: additionalButton.componentName,
          nameReq: additionalButton.name,
          submit: submit,
          cancel: () => hideLoadingAdditionalButton(additionalButton.code),
        });
      } else {
        submit();
      }
    } catch (error) {
      console.error(error);
      toast.error("Đã có lỗi xảy ra!");
    }
  };

  const handleReq = async (statusReq: WfWorkflowsReq | undefined, codeReq: string, nameReq: string, componentName: string, type: "new" | "old") => {
    if (loadingButton[type]) return;
    showLoadingButton(type);
    try {
      const actions = wfAxiosRequests?.actions?.[codeReq];
      const submit = async () => {
        if (actions) {
          for (const func of actions) {
            let data = await func(wfProps);
            if (data?.data?.data?.[0]?.errorMessage) {
              setNotification(data?.data?.data[0]?.errorMessage || "");
              return data;
            }
          }
        }

        if (wfProps.prefixFunction) {
          wfProps.prefixFunction(statusCode.curr, async () => {
            await changeStatus(statusReq, codeReq);
            hideLoadingButton(type);
          });
          return;
        }

        await changeStatus(statusReq, codeReq);
        hideLoadingButton(type);
      };

      if (componentName && wfProps.handleClick) {
        wfProps.handleClick({
          componentName: componentName,
          nameReq: nameReq,
          submit: submit,
          cancel: () => hideLoadingButton(type)
        });
      } else {
        await submit();
      }
    } catch (error) {
      console.error(error);
      toast.error("Đã có lỗi xảy ra!");
      hideLoadingButton(type);
    }
  };

  return (
    <>
      {wfAxiosRequests && wfAxiosRequests.axiosRequests && wfProps.objectId && wfAxiosRequests.axiosRequests.map((additionalButton: any) => (
        <Button className={`position-relative spaces mx-3 btn btn-fill h-29 ${loadingAdditionalButton?.[additionalButton.code] ? "cursor-wait" : ""}`} key={additionalButton.code} onClick={() => handleButtonClick(additionalButton)}>
          {loadingAdditionalButton?.[additionalButton.code] && <SpinnerOverlay />}
          {additionalButton.name}
        </Button>
      ))}
      {oldStatusName && wfProps.objectId && <Button
        className={`position-relative min-w-120 spaces mx-3 btn btn-fill h-29 ${loadingButton.old ? "cursor-wait" : ""}`}
        id="wf-old-status-button"
        onClick={() => handleReq(wfWorkflowsReqOld, statusCode.old, oldStatusName, config?.componentNameOldReq, "old")}
      >
        {loadingButton.old && <SpinnerOverlay />}
        <span>{config?.oldStatusName ? config?.oldStatusName : oldStatusName}</span>
      </Button>}
      {newStatusName && wfProps.objectId && <Button
        className={`position-relative min-w-120 spaces mx-3 btn btn-fill h-29 ${loadingButton.new ? "cursor-wait" : ""}`}
        id="wf-new-status-button"
        onClick={() => handleReq(wfWorkflowsReqNew, statusCode.new, newStatusName, config?.componentNameNewReq, "new")}
      >
        {loadingButton.new && <SpinnerOverlay />}
        <span>{config?.newStatusName ? config?.newStatusName : newStatusName}</span>
      </Button>}
      {
        <ConfirmDialog
          title="Thông báo"
          yes="OK"
          onYesClick={() => setNotification("")}
          message={notification}
          show={Boolean(notification)}
          onCloseClick={() => setNotification("")}
        />
      }
    </>
  );
});

export default WfButton;
