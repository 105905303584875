import axios from 'axios'
import { SearchObjectDanhSachPhieu } from '../models/VienPhiInfoModels';
import { urlParamsConfig } from '../../utils/ParamsUtils';
import { IInPhieuParams } from '../../models/params';
import { SEARCH_OBJECT_MAX_SIZE } from '../../utils/Constant';
import { localStorageItem } from '../../utils/LocalStorage';
import { KEY_LOCALSTORAGE } from '../../auth/core/_consts';
const BASE_URL = localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["apiUrl"] || process.env.REACT_APP_API_URL;

const API_PATH_CATEGORY = (localStorageItem.get(KEY_LOCALSTORAGE.CONFIGURATION)?.["adminUrl"] || process.env.REACT_APP_API_URL) + "/simple-categories";

export const getListPatient = (searchObject: any) => {
  let url = BASE_URL + `/payment/search`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getStatusVienPhi = (searchObject: any) => {
  let url = BASE_URL + `/payment/status-summary`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getThongTinChiPhi= (searchObject: any) => {
  let url = BASE_URL + `/patients/ongoing-visit`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getDanhSachLoaiPhieuService = (searchObject: any) => {
  let url = API_PATH_CATEGORY + `/search`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getDanhSachHinhThucService = (searchObject: any) => {
  let url = API_PATH_CATEGORY + `/search`
  return axios.get(urlParamsConfig(url, searchObject));
}
export const getDanhSachPhieuService = (searchObject: SearchObjectDanhSachPhieu) => {
  let url = BASE_URL + `/invoices/search`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const createInvoices = (data: any) => {
  var url = BASE_URL + '/invoices'
  return axios.post(url, data)
}

export const updateDiscount = (data: any) => {
  var url = BASE_URL + '/order-data/update-discount'
  return axios.put(url, data)
}

export const getInvoiceByIdService = (id: string) => {
  let url = BASE_URL + `/invoices/${id}`
  return axios.get(url);
}

export const getDanhSachDichVu = (searchObject: any) => {
  let url = BASE_URL + `/order-data/by-visit`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const cancelInvoices = (data: any) => {
  var url = BASE_URL + `/invoices/${data?.id}/cancel`
  return axios.post(url, data?.payload)
}

export const inPhieuVienPhi = async(params: IInPhieuParams) => {
  return await axios.post<Blob>(
    `${BASE_URL}/export/invoices`,
    params, 
    {
      responseType: 'blob',
    }
  );
};

export const getStatusPatient = (visitId: string) => {
  let url = BASE_URL + `/payment/visit-status?visitId=${visitId}`;
  return axios.get(url);
}

export const generateCodePayment = (code: string) => {
  let url = BASE_URL + `/invoices/next-code/${code}`;
  return axios.get(url);
};

export const getDsPhieuThu = (params: any) => {
  let url = BASE_URL + `/invoices/tree-list`;
  return axios.get(url, { params });
};

// chốt số liệu phiếu thu
export const getDsSoLieuPhieuThu = (params: any) => {
  let url = BASE_URL + `/invoices/finalized`;
  return axios.get(url, { params });
};

export const saveDsSoLieuPhieuThu = (data: any) => {
  let url = BASE_URL + `/invoices/finalized`;
  return axios.post(url, data);
};

export const cancelChotSoLieuPhieuThu = (id: string) => {
  let url = BASE_URL + `/invoices/${id}/revoke-finalized`;
  return axios.post(url);
};

// used VP + KB
export const getPaymentDetail = (visitId: string) => {
  let url = BASE_URL + `/payment/detail?visitId=${visitId}`;
  return axios.get(url);
}

export const paymentApproval = (data: any) => {
  var url = BASE_URL + `/payment/approve`;
  return axios.post(url, data)
}

export const paymentCancelApproval = (data: any) => {
  var url = BASE_URL + `/payment/cancel`;
  return axios.post(url, data)
}

// sổ thu
export const getDsSoThuTien = (searchObject: any) => {
  let url = BASE_URL + `/ledgers/search`;
  return axios.get(url, { params: searchObject });
}

export const createSoThuTien = (data: any) => {
  let url = BASE_URL + `/ledgers`;
  return axios.post(url, data);
}

export const updateSoThuTien = (data: any, id: string) => {
  let url = BASE_URL + `/ledgers/${id}`;
  return axios.put(url, data);
}

export const getDsChonSoThuTien = () => {
  let url = BASE_URL + `/ledgers/selection`;
  return axios.get(url, { params: SEARCH_OBJECT_MAX_SIZE });
}

export const saveChonSoThuTien = (data: any) => {
  let url = BASE_URL + `/ledgers/selection`;
  return axios.post(url, data);
}

export const getThongTinSoThuTien = (id: string) => {
  let url = BASE_URL + `/ledgers/${id}`;
  return axios.get(url);
}

export const getFormFieldConfig = (codes: string) => {
  let url = BASE_URL + `/wf-org-conf/get-by-list-code?codes=${codes}`;
  return axios.get(url);
}
