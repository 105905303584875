import React from 'react'
import { OctKTSVG } from '@oceantech/oceantech-ui'
import './Giuong.scss'

type Props = {
    name: string
    used: number
    total: number
    isBaoTri: boolean
}

const Giuong = ({
    name,
    used, total, isBaoTri
}: Props) => {
    return (
        <div className='card-giuong'>
            <div className='d-flex align-items-center justify-content-between spaces px-10'>
                <div>
                    <OctKTSVG path='/media/svg/cis/giuong.svg' className='icon-giuong' />
                    <span className='fw-bold opacity-75'>{`(${used}/${total})`}</span>
                </div>
                <h6 className='spaces mb-0'>{name || ""}</h6>
            </div>
            <div className='border-top border-top-2 spaces px-10 pt-10'>
                {
                    isBaoTri
                        ? <span className='text-danger fw-bold'>Đang bảo trì</span>
                        : Array.from({ length: total }).map((item, index) => (
                            index + 1 <= used
                            ? <div key={index} className='spaces fw-4 d-flex align-items-center'>
                                <i className='bi bi-dot fs-2 text-dark me-1'></i>
                                {"Giàng Thiên Lý"}
                            </div>
                            : <div key={index} className='spaces fw-4 d-flex align-items-center'>
                                <i className='bi bi-dot fs-2 text-dark me-1'></i>
                                {"(Trống)"}
                            </div>
                        ))
                }
            </div>
        </div>
    )
}

export default Giuong